<template>
    <default-container>
        <nav class="page-breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="#">Payment</a></li>
                <li class="breadcrumb-item active" aria-current="page">Payment History</li>
            </ol>
        </nav>   
        <h4 class="page-title">Payment History</h4>
            <div class="row">
                <div class="col-lg-12">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-lg-12">
                                    <v-client-table :columns="columns" :data="payment_list" :options="options" :theme="theme" id="dataTable" class="main-data-table booking-table">      
                                       <div slot-scope="props" slot="stripe_subcription_id">
                                            <button @click="gotostripe(props.row.stripe_subcription_id)" class="btn btn-primary stripe-btn icon-with-text-btn" type="button">
                                                 Go to Stripe
                                            </button>                                         
                                        </div>
                                       <div slot-scope="props" slot="currency">
                                          <span class="badge bg-warning currency-bg"> {{ props.row.currency }}</span>                                       
                                        </div>                                         
                                        <div slot-scope="props" slot="date">
                                            {{ props.row.date.split('T')[0] }}
                                        </div>
                                        <div slot-scope="props" slot="date">
                                            {{ props.row.date.split('T')[0] }}
                                        </div>                                         
                                        <div slot-scope="props" slot="id">
                                            <button @click="viewPayment(props.row.stripe_url, props.row.date.split('T')[0])" class="btn btn-primary icon-with-text-btn" type="button">
                                                <i class="ri-eye-fill"></i> View
                                            </button>
                                        </div>                                                                                                                
                                    </v-client-table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Payment View -->
                <div class="modal fade" id="PaymentViewModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-lg payment-view">
                        <div class="modal-content">
                            <form @submit.prevent="taxsubmit" ref="taxform">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="exampleModalLabel">Payment Details</h5>
                                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div class="modal-body">
                                  <div class="hotel-header">
                                    <div class="row">
                                      <div class="col-md-2">
                                        <div class="hotel-logo">
                                          <img src="img/stripe.svg" />
                                        </div>
                                      </div>
                                      <div class="col-md-7">
                                        <div class="hotel-desc">
                                          <h4>
                                            Stripe Payment</h4>
                                          <span><i class="ri-calendar-2-fill"></i> {{ view_created }}</span>
                                        </div>
                                      </div>
                                      <div class="col-md-3 text-right">
                                            <button class="btn btn-primary icon-with-text-btn stripe-btn" @click="gotostripe(this.view_subscription)" type="button">
                                                <i class="ri-eye-fill"></i> Go to Stripe
                                            </button>
                                      </div>                                      
                                    </div>
                                  </div>
                                  <div class="book-info">
                                    <div class="row">
                                      <div class="col-md-12">
                                        <h4 class="fl">Subscription Details</h4>
                                        <span v-if="this.view_active == true" class="badge bg-success book-badge">Active</span>
                                        <span v-if="this.view_active != true" class="badge bg-danger book-badge">Inactive</span>
                                        <ul class="item-b">
                                          <li class="full">
                                            <b>Customer:</b> <span>Kasun Test</span>
                                          </li>
                                          <li>
                                            <b>Created Date:</b> <span>{{ view_created }}</span>
                                          </li>
                                          <li>
                                            <b>Stripe Product ID:</b> <span>{{ view_stripe_product_id }}</span>
                                          </li>
                                          <li>
                                            <b>Subscription ID:</b> <span>{{ view_subscription }}</span>
                                          </li>
                                          <li>
                                            <b>Tax Behavior:</b> <span>{{ view_tax_behavior }}</span>
                                          </li>
                                          <li>
                                            <b>Usage_Type:</b> <span>{{ view_usage_type }}</span>
                                          </li>
                                          <li>
                                            <b>Amount:</b> <span>{{ view_amount }}</span>
                                          </li>                                          
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="book-info last">
                                    <div class="row">
                                      <div class="col-md-12">
                                        <h4>Pricing Details</h4>
                                          <div class="tab-content room-tabs-content" id="pills-tabContent">
                                              <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                                                <ul class="item-b">
                                                  <li class="full">
                                                    <span class="light-plan">{{ view_stripe_product_id }}<br>
                                                        <p>{{ view_amount }} {{ view_currency }} /{{ view_interval }}</p>
                                                    </span>
                                                  </li>
                                                  <li>
                                                    <b>billing_scheme:</b> <span>{{ view_billing_scheme }}</span>
                                                  </li>
                                                  <li>
                                                    <b>currency:</b> <span style="text-transform: uppercase;">{{ view_currency }}</span>
                                                  </li>
                                                  <li>
                                                    <b>Quantity:</b> <span>{{ view_quantity }}</span>
                                                  </li>                                                   
                                                </ul>
                                              </div>         
                                          </div> 
                                      </div>
                                    </div>
                                  </div>                                  
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>                 
            </div>
    </default-container>
</template>
<script>
import Vue from 'vue'
import axios from 'axios';
import Header from "../../mixins/Header";
import {ClientTable, Event} from 'vue-tables-2'
import DefaultContainer from '../../containers/DefaultContainer.vue'
import mixinHotel from "@/mixins/Hotel/hotel";

Vue.use(ClientTable)
export default {
    mixins: [
        mixinHotel
    ],    
  components: { 
      DefaultContainer,
      ClientTable,
      Event,      
      },
  name: "hotel_list",
  data(){
      return{
        payment_data: [],  
        data: [],
        payment_list:[],
        theme: 'bootstrap5',
        hotelsubmitted: false,
        submitted: false,
        columns: ['date', 'amount','currency','stripe_product_id','stripe_subcription_id','id'],
        options: {
            customFilters: [{
                name: 'mysearch',
                callback: function(row, query) {
                    //this should be updated to match your data objects
                    return row.name[0] == query; 
                }
            }],     
            perPage: 8,
            columnsDisplay: {
                amount: 'desktopL',
                currency: 'not_mobile',   
                date: 'desktopL',                                
                stripe_product_id: 'not_mobile',
                stripe_subcription_id: 'not_mobile',
                id: 'desktopL',
            },  
            columnsClasses:{
                date: 'dateclass'
            },                              
            headings: {
                date: 'Date',
                amount: 'Amount',
                currency: 'Currency',
                stripe_product_id: 'Product ID',
                stripe_subcription_id: 'Stripe access Url',
                id: 'Action'
            },
            sortable: ['hotel_name', 'email','country','total_rooms',],
            filterable: ['checkIn', 'checkOut','createdAt','roomId','userId'],
            sortIcon: { base:'fa', up:'fa-sort-asc', down:'fa-sort-desc', is:'fa-sort' },
            pagination: {
                chunk: 5,
                edge: false,
                nav: 'scroll'
            }
        },  
        searchTerm: "",
        view_created: "",
        view_stripe_product_id: "",
        view_subscription: "",
        view_tax_behavior: "",
        view_currency: "",
        view_usage_type: "",
        view_active: "false",      
        view_amount: "",
        view_billing_scheme: "",
        view_quantity: null
      }
  },
  methods:{
  async gotostripe(id) {
    window.open(`${process.env.VUE_APP_STRIPE_URL}${id}`,'_blank');
  },
   async getpaymentlist  () {

       this.payment_list = []
        try {
          let response = await this.axios         
            .get(`${process.env.VUE_APP_PAYMENT_URL}admin/payment/list`, Header.APP_ADMIN_HEADER)
            .then((response) => {
                console.log(response)
                this.payment_list = response.data.reverse();
                console.log(this.payment_list)

            //   for (let a = 0; a < this.payment_list.length; a++){
            //     this.payment_list.push({
            //       date :this.payment_list[a].date,
            //       amount:this.payment_list[a].amount,
            //       currency:this.payment_list[a].currency,
            //       card_user_id:this.payment_list[a].card_user_id,
            //       stripe_product_id:this.payment_list[a].stripe_product_id,
            //       stripe_url:this.payment_list[a].stripe_url,
            //     })               
            //   }

              console.log(this.data)

            })
        } catch (error) {
          console.log(error)          
        }       
   },
   async goedithotel(id) {
        this.$router.push("/edit_hotel?hotel_id="+id)
   },
   async deleteHotel(id) { 
    
    this.$swal({
        title: 'Are you sure?',
        text: "Do you want to delete this Hotel?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#0d6efd',
        cancelButtonColor: '#d33',
        confirmButtonText: 'delete it!'
    }).then((result) => {
        if (result.isConfirmed) {
            axios.delete(`${process.env.VUE_APP_API_URL}hotel/delete?hotel_id=${id}`, Header.APP_ADMIN_HEADER)
            .then(response => {
                console.log(response);
                this.$toast.success(response.data.message, {hideProgressBar: true, timeout: 3000,});    
                this.getHotelList(); 
            })
            .catch(function (error) {
                console.log(error)
                this.$toast.error(error.message, {hideProgressBar: true, timeout: 3000,});  
            })  
        }
    })    
   },
   async viewPayment(url, date) {

      this.payment_data = []

      this.view_stripe_product_id = ""
      this.view_subscription = ""
      this.view_tax_behavior = ""
      this.view_currency = ""
      this.view_usage_type = ""
      this.view_active = "false"  
      this.view_amount = ""
      this.view_interval = ""
      this.view_billing_scheme = ""
      this.view_quantity = null

      this.view_created = date

      try {
        let response = await this.axios
          .get(`${process.env.VUE_APP_STRIPE_API_URL}${url}`, Header.BOOKING_TOKEN)
          .then((response) => {
              this.payment_data = response.data.data[0]
              console.log(this.payment_data)

              this.view_stripe_product_id = this.payment_data.plan.product
              this.view_subscription = this.payment_data.subscription
              this.view_tax_behavior = this.payment_data.price.tax_behavior
              this.view_currency = this.payment_data.plan.currency
              this.view_usage_type = this.payment_data.plan.usage_type
              this.view_active = this.payment_data.plan.active
              this.view_amount = this.payment_data.plan.amount
              this.view_interval = this.payment_data.plan.interval
              this.view_billing_scheme = this.payment_data.plan.billing_scheme
              this.view_quantity = this.payment_data.quantity

          //   for (let a = 0; a < response.data.length; a++){
          //     this.payment_data.push({
          //       id :response.data[a].id,
          //       name:response.data[a].name,
          //       image_url: response.data[a].image_url,
          //       public_id: response.data[a].public_id,
          //     })               
          //   }
          })
      } catch (error) {
        console.log(error)          
      }        
    bootstrap.Modal.getOrCreateInstance(document.getElementById('PaymentViewModal')).show(); 
   },
  },
  created(){
      this.getpaymentlist();
  },
  mounted(){     
  }
}
</script>
<style>
</style>