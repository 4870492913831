<template>
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-lg-12">
                            <button type="button" class="btn btn-primary pull-right btn-lg" data-bs-toggle="modal" data-bs-target="#AddNewTaxModal">Add New Tax</button>
                            <v-client-table ref="infoPagesTable" :columns="columns" :data="data" :options="options" id="dataTable" class="main-data-table">
                                <div slot-scope="props" slot="id">
                                    <button @click="editTax(props.row.id, props.row.name, props.row.description, props.row.is_precentage, props.row.status, props.row.value)" class="btn btn-primary" type="button"><i class="fa-solid fa-pencil"></i></button>
                                    <button @click="deleteTax(props.row.id)" class="btn btn-danger" type="button"><i class="fa-solid fa-trash-can"></i></button>
                                    <button @click="editInfoPage(props.row.id)" class="btn btn-info" type="button"><i class="ri-eye-fill"></i></button>
                                </div>   
                                <template v-slot:child_row="props">
                                    <div class="list-br"><b>Description:</b> {{props.row.description}}</div>
                                    <div class="list-br"><b>Precentage:</b> {{props.row.is_precentage}}</div>
                                    <div class="list-br"><b>Status:</b> {{props.row.status}}</div>
                                    <div class="list-br"><b>Value:</b> {{props.row.value}}</div>
                                </template>                                 
                            </v-client-table>                                             
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Add New Tax modal -->
        <div class="modal fade" id="AddNewTaxModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <form @submit.prevent="taxsubmit" ref="taxform">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Add New Tax</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group mb-3">
                                        <label for="tax_name" class="form-label">Tax Name</label>
                                        <input type="text" v-model="tax.name" id="tax_name" name="tax_name" class="form-control" placeholder="Tax Name" 
                                        :class="{ 'is-invalid': taxsubmitted && $v.tax.name.$error }">
                                        <div v-if="taxsubmitted && !$v.tax.name.required" class="invalid-feedback">Tax Name required</div>
                                    </div>
                                    <div class="form-group mb-3">
                                        <label for="tax_value" class="form-label">Value</label>
                                        <input type="number" min="o" v-model="tax.value" id="value" name="tax_value" class="form-control" placeholder="Tax value" 
                                        :class="{ 'is-invalid': taxsubmitted && $v.tax.value.$error }">
                                        <div v-if="taxsubmitted && !$v.tax.value.required" class="invalid-feedback">Tax value required</div>
                                    </div>                                    
                                    <div class="form-group mb-3">
                                        <label for="description" class="form-label">Description</label>
                                        <input type="text" v-model="tax.description" id="description" name="description" class="form-control" placeholder="description" 
                                        :class="{ 'is-invalid': taxsubmitted && $v.tax.description.$error }">
                                        <div v-if="taxsubmitted && !$v.tax.description.required" class="invalid-feedback">description required</div>
                                    </div>                                    
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-md-6">
                                    <div class="form-check form-switch form-group mb-3">
                                        <input class="form-check-input" v-model="tax.is_precentage" type="checkbox" role="switch" id="is_precentage">
                                        <label class="form-check-label" for="is_precentage">Is precentage</label>
                                    </div>                                     
                                </div>
                                <div class="col-md-6">
                                    <div class="form-check form-switch form-group mb-3">
                                        <input class="form-check-input" v-model="tax.status" type="checkbox" role="switch" id="status">
                                        <label class="form-check-label" for="status">Status</label>
                                    </div>                                     
                                </div>                                
                            </div>                                    
                           
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button class="btn btn-primary">Save changes</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <!-- Update Tax modal -->
        <div class="modal fade" id="updateTaxModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <form @submit.prevent="updatetaxsubmit" ref="updatetaxform">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Update Tax</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group mb-3">
                                        <label for="tax_name" class="form-label">Tax Name</label>
                                        <input type="text" v-model="tax.update_name" id="tax_name" name="tax_name" class="form-control" placeholder="Tax Name" 
                                        :class="{ 'is-invalid': updatetaxsubmitted && $v.tax.update_name.$error }">
                                        <div v-if="updatetaxsubmitted && !$v.tax.update_name.required" class="invalid-feedback">Tax Name required</div>
                                    </div>
                                    <div class="form-group mb-3">
                                        <label for="tax_value" class="form-label">Value</label>
                                        <input type="number" min="o" v-model="tax.update_value" id="value" name="tax_value" class="form-control" placeholder="Tax value" 
                                        :class="{ 'is-invalid': updatetaxsubmitted && $v.tax.update_value.$error }">
                                        <div v-if="updatetaxsubmitted && !$v.tax.update_value.required" class="invalid-feedback">Tax value required</div>
                                    </div>                                    
                                    <div class="form-group mb-3">
                                        <label for="description" class="form-label">Description</label>
                                        <input type="text" v-model="tax.update_description" id="description" name="description" class="form-control" placeholder="description" 
                                        :class="{ 'is-invalid': updatetaxsubmitted && $v.tax.update_description.$error }">
                                        <div v-if="updatetaxsubmitted && !$v.tax.update_description.required" class="invalid-feedback">description required</div>
                                    </div>                                    
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-md-6">
                                    <div class="form-check form-switch form-group mb-3">
                                        <input class="form-check-input" v-model="tax.update_is_precentage" type="checkbox" role="switch" id="is_precentage">
                                        <label class="form-check-label" for="is_precentage">Is precentage</label>
                                    </div>                                     
                                </div>
                                <div class="col-md-6">
                                    <div class="form-check form-switch form-group mb-3">
                                        <input class="form-check-input" v-model="tax.update_status" type="checkbox" role="switch" id="status">
                                        <label class="form-check-label" for="status">Status</label>
                                    </div>                                     
                                </div>                                
                            </div>                                    
                           
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button class="btn btn-primary">Save changes</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>        
    </div>
</template>
<script>
import axios from 'axios';
import Header from "../../../mixins/Header";
import { required } from "vuelidate/lib/validators";
import {ClientTable, Event} from 'vue-tables-2'
export default {  
  components: { ClientTable, Event  },
  name: "room_info",
  data(){
      return{
        data: [],
        hotel_id: this.$route.query.hotel_id,
        columns: ['name', 'description', 'is_precentage', 'status','value','id'],
        options: {
            perPage: 8,
            columnsDisplay: {
                name: 'desktopL',
                description: 'not_mobile',
                is_precentage: 'not_mobile',
                status: 'not_mobile',
                value: 'not_mobile',
                id: 'desktopL',
            },            
            headings: {
                name: 'Tax Name',
                description: 'Description',
                is_precentage: 'is_precentage',
                status: 'status',
                value: 'value',
                id: 'Action'
            },
            sortable: [],
            filterable: [],
            sortIcon: { base:'fa', up:'fa-sort-asc', down:'fa-sort-desc', is:'fa-sort' },
            pagination: {
                chunk: 5,
                edge: false,
                nav: 'scroll'
            }
        },         
        tax:{
            name: "",
            description: "",
            value: null,
            is_precentage: false,
            status: false,
            update_name: "",
            update_description: "",
            update_value: null,
            update_is_precentage: false,
            update_status: false,            
        },      
        taxsubmitted: false,
        updatetaxsubmitted: false,
        submitted: false,
        updateTaxdataid: ""
      }
  },
  validations: {
      tax: {
        name: { required },
        description: { required },   
        value: { required },    
        update_name: { required },
        update_description: { required },
        update_value: { required },
      },      
  }, 
  methods:{    

    async editInfoPage(id) {
        this.$refs.infoPagesTable.toggleChildRow(id);
    },      
    async taxsubmit(e) { 

      this.taxsubmitted = true;

        this.$v.tax.name.$touch();
        this.$v.tax.description.$touch();
        this.$v.tax.value.$touch();

         if (
            this.$v.tax.name.$error || 
            this.$v.tax.description.$error || 
            this.$v.tax.value.$error
        ) {  
        } 
        else{
            var reqBody = {
                name: this.tax.name,
                description: this.tax.description,
                is_precentage: this.tax.is_precentage,
                status: Boolean(this.tax.status),
                value: parseInt(this.tax.value),

            }
            console.log(reqBody)

          try{
              let response = await this.axios
              .post(`${process.env.VUE_APP_API_URL}hotel/taxation/create?hotel_id=${this.hotel_id}`, reqBody, Header.APP_ADMIN_HEADER)
              .then((res) => {
                console.log(res);   
                this.tax.name = '';
                this.tax.description = '';  
                this.tax.value = '';
                this.tax.is_precentage = false
                this.tax.status = false
                this.$v.tax.name.$reset();
                this.$v.tax.description.$reset();
                this.$v.tax.value.$reset();  
                this.getTaxList();              
                this.$toast.success(res.data.message, {hideProgressBar: true, timeout: 3000,});      
                bootstrap.Modal.getOrCreateInstance(document.getElementById('AddNewTaxModal')).hide(); 
              })                    
          }catch(error){    
              console.log(error)   
              this.$toast.error(error.message, {hideProgressBar: true, timeout: 3000,});                                   
          }            
        }
    },            
    async getTaxList() {

        this.data = []

        try {
          let response = await this.axios
            .get(`${process.env.VUE_APP_API_URL}hotel/taxation/get/list?hotel_id=${this.hotel_id}`, Header.APP_ADMIN_HEADER)
            .then((response) => {
                console.log(response.data)
                for (let a = 0; a < response.data.length; a++){
                    this.data.push({
                        description :response.data[a].description,
                        hotel_id:response.data[a].hotel_id,
                        id :response.data[a].id,
                        is_precentage:String(response.data[a].is_precentage),
                        name :response.data[a].name,
                        status:String(response.data[a].status),   
                        value :response.data[a].value                                                       
                    })               
                }
            })
        } catch (error) {
          console.log(error)          
        }
    },
    async editTax(id, name, description, is_precentage, status, value) {

        this.tax.update_name = name
        this.tax.update_description = description
        this.tax.update_is_precentage = is_precentage
        this.tax.update_status = status
        this.tax.update_value = value

        this.updateTaxdata = []
        this.updateTaxdataid = id

        bootstrap.Modal.getOrCreateInstance(document.getElementById('updateTaxModal')).show();          
    },
    async updatetaxsubmit(e) { 

      this.updatetaxsubmitted = true;

        this.$v.tax.update_name.$touch();
        this.$v.tax.update_description.$touch();
        this.$v.tax.update_value.$touch();        

        if (
            this.$v.tax.update_name.$error || 
            this.$v.tax.update_description.$error || 
            this.$v.tax.update_value.$error
        ) {            
        }
        else{
            var reqBody = {
                name: this.tax.update_name,
                description: this.tax.update_description,
                is_precentage: Boolean(this.tax.update_description),
                value: parseInt(this.tax.update_value),
                status: Boolean(this.tax.update_status)
            }
            console.log(reqBody)            
            try{
                let response = await this.axios
                .put(`${process.env.VUE_APP_API_URL}hotel/taxation/update?taxation_id=${this.updateTaxdataid}&hotel_id=${this.hotel_id}`, reqBody, Header.APP_ADMIN_HEADER)
                .then((res) => {
                    let data = res.data;
                    console.log(data)
                    this.tax.update_name = '';
                    this.tax.update_description = '';  
                    this.tax.update_value = '';
                    this.tax.update_is_precentage = false
                    this.tax.update_status = false
                    this.$v.tax.update_name.$reset();
                    this.$v.tax.update_description.$reset();
                    this.$v.tax.update_value.$reset();    
                    this.getTaxList();            
                    this.$toast.success(res.data.message, {hideProgressBar: true, timeout: 3000,});      
                    bootstrap.Modal.getOrCreateInstance(document.getElementById('updateTaxModal')).hide();    
                })                    
            }catch(error){    
                console.log(error)   
                this.$toast.error(error.message, {hideProgressBar: true, timeout: 3000,});
                    this.tax.update_name = '';
                    this.tax.update_description = '';  
                    this.tax.update_value = '';
                    this.tax.update_is_precentage = false
                    this.tax.update_status = false
                    this.$v.tax.update_name.$reset();
                    this.$v.tax.update_description.$reset();
                    this.$v.tax.update_value.$reset();                                                 
            }             
        }
    },
    async deleteTax(id) { 
        
        this.$swal({
            title: 'Are you sure?',
            text: "Do you want to delete this Tax?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#0d6efd',
            cancelButtonColor: '#d33',
            confirmButtonText: 'delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                axios.delete(`${process.env.VUE_APP_API_URL}hotel/taxation/delete?taxation_id=${id}`, Header.APP_ADMIN_HEADER)
                .then(response => {
                    console.log(response);
                    this.getTaxList(); 
                    this.$toast.success(response.data.message, {hideProgressBar: true, timeout: 3000,});    
                })
                .catch(function (error) {
                    console.log(error)
                    this.$toast.error(error.message, {hideProgressBar: true, timeout: 3000,});  
                })  
            }
        })   
    },     
  },
  created(){
      this.getTaxList();
  }
}
</script>

<style>

</style>