<template>
    <default-container>
        <nav class="page-breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="#">User</a></li>
                <li class="breadcrumb-item active" aria-current="page">Add New User</li>
            </ol>
        </nav>   
        <h4 class="page-title">Add New User</h4>
        <add-user />
    </default-container>
</template>

<script>
import Vue from 'vue'
import DefaultContainer from '../../containers/DefaultContainer.vue'
import AddUser from '../../components/Crover/AddUser.vue'

export default {   
  components: { DefaultContainer, AddUser },
    name: "add_user",
  data(){
      return{
           data: [],
          coindata:[],
          time2: null,
          amenityList: [],
          theme: 'bootstrap5',
          columns: ['date', 'email','amount','hash','maturity_date','id'],
            options: {
            headings: {
                date: 'Action',
                email: 'Room Type',
                amount: 'Room Name',
                hash: 'lorem',
                maturity_date: 'People',
                id: 'Attachments'
            },
            sortable: ['date', 'email','amount', 'hash', 'maturity_date'],
            filterable: ['date', 'email','amount', 'hash', 'maturity_date'],
            sortIcon: { base:'fa', up:'fa-sort-asc', down:'fa-sort-desc', is:'fa-sort' },
            pagination: {
                chunk: 5,
                edge: false,
                nav: 'scroll'
            }
            },           
          hotel:{
            hotel_name: "",
            exclusive_deal: "",
            checkin_hour: "",
            checkout_hour: "",
            total_rooms: "",
            thumbnail_path: "",
            short_description: "",
            long_description: "",
            hotel_amenities: "",
            id: "",
            hotel_currency: "",
            stars: "",
            address: "",
            city_id: "",
            city_name: "",
            country_id: "",
            country_name: "",
            state: "",
            zip_code: "",
            longitude: "",
            latitude: "",
            destination_id: "",
            phone: "",
            country_code: "",
            email: "",
            fax: "",
            nearest_airport_code: "",
            provider: "",
            distance_unit: "",
            product_status: "",
            user_id: ""
          },
        amenity:{
            amenity_name: "",
            amenity_image_url: ""
        },
          hotelsubmitted: false,
          amenitysubmitted: false,
          submitted: false
      }
  }, 
  methods:{   
  },
  mounted(){
  }
}
</script>

<style>

</style>