<template>
    <div class="card">
        <div class="card-header">
        <button type="button" class="btn btn-primary btn-sm float-end" data-bs-toggle="modal" data-bs-target="#CategoryModal">Add New Category</button>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-lg-12">  
                    <v-client-table :columns="columns" :data="category_data" :options="options" id="dataTable" class="main-data-table">
                        <div slot-scope="props" slot="id">
                            <button @click="editCategory(props.row.id, props.row.name, props.row.image_url, props.row.public_id)"   class="btn btn-primary" type="button"><i class="fa-solid fa-pencil"></i></button>
                            <button @click="deleteCategory(props.row.id, props.row.public_id)" class="btn btn-danger" type="button"><i class="fa-solid fa-trash-can"></i></button>                                  
                        </div>   
                        <div slot-scope="props" slot="public_id">                                         
                            <span class="badge border-warning text-dark label-border">{{ props.row.public_id }}</span>
                        </div>                        
                        <div slot-scope="props" slot="image_url">                                         
                            <img class="table-img" :src="props.row.image_url" />
                        </div>
                        <div slot-scope="props" slot="status">                                           
                            <span v-if="props.row.status == 1" class="badge bg-success rounded-pill">Active</span>
                            <span v-else class="badge bg-danger rounded-pill">Disabled</span>
                        </div>                                 
                    </v-client-table>
                </div>
            </div>                                        
        </div>  
    <!-- category add modal -->
    <div class="modal fade" id="CategoryModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <form @submit.prevent="categorysubmit" ref="amenityform">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Add New Category</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="form-group mb-3">
                            <label for="category_name" class="form-label">Category Name</label>
                            <input type="text" v-model="category.category_name" id="category_name" name="category_name" class="form-control" placeholder="Amenity Name" 
                            :class="{ 'is-invalid': amenitysubmitted && $v.category.category_name.$error }">
                            <div v-if="amenitysubmitted && !$v.category.category_name.required" class="invalid-feedback">category Name required</div>
                        </div>
                        <div class="form-group mb-3">
                            <label for="amenity_image_url" class="form-label">Category Image Url</label>
                            <div class="card auto-height" 
                            :class="{ 'is-invalid': amenitysubmitted && $v.category.category_image_url.$error }">
                                <div class="card-body">
                                    <vue-dropzone 
                                        ref="myVueDropzone"
                                        :options="dropzoneOptions"
                                        @vdropzone-error="category_errorUploading"
                                        @vdropzone-success="category_fileUploaded"
                                        @vdropzone-removed-file="category_removefile"
                                        >
                                    </vue-dropzone>                                 
                                </div>
                                <div v-if="amenitysubmitted && !$v.category.category_image_url.required" class="invalid-feedback">Category Image required</div>                                  
                            </div>                            
                        </div>               
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        <button class="btn btn-primary">Save changes</button>
                    </div>
                </form>
            </div>
        </div>
    </div>  
    <!-- Update Amenity modal -->
    <div class="modal fade" id="updatecategoryModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <form @submit.prevent="updatecategorysubmit" ref="updateamenityform">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Update Category</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="form-group mb-3">
                            <label for="amenity_name" class="form-label">Category Name</label>
                            <input type="text" v-model="updatecategory.category_name" id="category_name" name="category_name" class="form-control" placeholder="Amenity Name" 
                            :class="{ 'is-invalid': updateamenitysubmitted && $v.updatecategory.category_name.$error }">
                            <div v-if="updateamenitysubmitted && !$v.updatecategory.category_name.required" class="invalid-feedback">category Name required</div>
                        </div>
                        <div class="form-group mb-3">
                            <label for="amenity_image_url" class="form-label">category Image Url</label>
                            <div class="card auto-height" 
                            :class="{ 'is-invalid': updateamenitysubmitted && $v.updatecategory.category_image_url.$error }">
                                <div class="card-body">
                                    <vue-dropzone 
                                        ref="myVueDropzone2"
                                        :options="dropzoneOptions"
                                        @vdropzone-error="updatecategory_errorUploading"
                                        @vdropzone-success="updatecategory_fileUploaded"
                                        @vdropzone-removed-file="updatecategory_removefile"
                                        :useCustomSlot=true
                                        >
                                        <div class="dropzone-custom-content">
                                            <h3 class="dropzone-custom-title">Drag and drop to upload content!</h3>
                                            <img :src="updatecategory.category_image_url" />
                                        </div>                                          
                                    </vue-dropzone>                                 
                                </div>
                                <div v-if="updateamenitysubmitted && !$v.updatecategory.category_image_url.required" class="invalid-feedback">category Image required</div>                                  
                            </div>                            
                        </div>               
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        <button class="btn btn-primary">Save changes</button>
                    </div>
                </form>
            </div>
        </div>
    </div>                            
    </div>
</template>
<script>
import axios from 'axios';
import {ClientTable, Event} from 'vue-tables-2'
import Header from "../../mixins/Header";
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import { required } from "vuelidate/lib/validators";
export default {  
  components: {  ClientTable, Event, vueDropzone: vue2Dropzone },
  data(){
      return{
        dropzoneOptions: {
            id: "facility_image",
            url: 'https://httpbin.org/post',
            thumbnailWidth: 150,
            maxFilesize: 0.5,
            addRemoveLinks: true,
            uploadMultiple: false,
            maxFilesize: 20,
            autoProcessQueue: true,
            acceptedFiles: "image/*",
            maxFiles: 1,
            headers: { "My-Awesome-Header": "header value" },
        },          
           data: [],
          category_data: [],
          coindata:[],
          time2: null,
          amenityList: [],
          theme: 'bootstrap5',
        columns: ['name', 'image_url', 'public_id', 'id'],
        options: {
            perPage: 8,
            columnsDisplay: {
                name: 'desktopL',
                image_url: 'desktopL',
                public_id: 'not_mobile',
                id: 'desktopL',
            },             
            headings: {
                id: 'Action',
                name: 'Name',
                image_url: 'Image',
                public_id: 'Public ID',
            },
            sortable: [],
            filterable: [],
            sortIcon: { base:'fa', up:'fa-sort-asc', down:'fa-sort-desc', is:'fa-sort' },
            pagination: {
                chunk: 5,
                edge: false,
                nav: 'scroll'
            }
        },           
        category:{
            category_name: "",
            category_image_url: ""
        },
        updatecategory:{
            category_name: "",
            category_image_url: "",
            category_id: null,
            updatepublic_id: ""
        },          
          hotelsubmitted: false,
          amenitysubmitted: false,
          updateamenitysubmitted: false,
          submitted: false
      }
  },
  validations: {

    category: {
        category_name: { required },
        category_image_url: { required },   
    },
      updatecategory: {
        category_name: { required },
        category_image_url: { required },   
      }, 
  },   
  methods:{  
    async categorysubmit(e) { 

      this.amenitysubmitted = true;

        this.$v.category.category_name.$touch();
        this.$v.category.category_image_url.$touch();

        if (
            this.$v.category.category_name.$error || 
            this.$v.category.category_image_url.$error
        ) {} 
        else{
            var reqBody = {
                name: this.category.category_name,
                image_url: this.category.category_image_url
            }
            console.log(reqBody)
            try{
                let response = await this.axios
                .post(`${process.env.VUE_APP_API_URL}common/category/create`, reqBody, Header.APP_ADMIN_HEADER)
                .then((res) => {
                    let data = res.data;
                    console.log(data)
                    this.category.category_name = '';
                    this.category.category_image_url = '';  
                    this.$v.category.category_name.$reset();   
                    this.$v.category.category_image_url.$reset();  
                    this.$refs.myVueDropzone.removeAllFiles();
                    this.$toast.success(data.message, {hideProgressBar: true, timeout: 3000,});   
                    this.getCategory();
                    bootstrap.Modal.getOrCreateInstance(document.getElementById('CategoryModal')).hide();     
                })                    
            }catch(error){    
                console.log(error)   
                this.$toast.error(error.message, {hideProgressBar: true, timeout: 3000,});                                   
            }             
        }
    },       
    async getCategory() {
        this.category_data = []
        try {
          let response = await this.axios
            .get(`${process.env.VUE_APP_API_URL}common/category/get/list`, Header.APP_ADMIN_HEADER)
            .then((response) => {
                console.log(response.data)
              for (let a = 0; a < response.data.length; a++){
                this.category_data.push({
                  id :response.data[a].id,
                  name:response.data[a].name,
                  image_url: response.data[a].image_url,
                  public_id: response.data[a].public_id,
                })               
              }
            })
        } catch (error) {
          console.log(error)          
        }

    }, 
    async updatecategorysubmit(e) { 

      this.updateamenitysubmitted = true;

        this.$v.updatecategory.category_name.$touch();
        this.$v.updatecategory.category_image_url.$touch();

        if (
            this.$v.updatecategory.category_name.$error || 
            this.$v.updatecategory.category_image_url.$error
            ) 
        {   
        }
        else{
            var reqBody = {
                name: this.updatecategory.category_name,
                image_url: this.updatecategory.category_image_url
            }
            console.log(reqBody)
            try{
                let response = await this.axios
                .put(`${process.env.VUE_APP_API_URL}common/category/update/?category_id=${this.category_id}&public_id=${this.updatepublic_id}`, reqBody, Header.APP_ADMIN_HEADER)
                .then((res) => {
                    let data = res.data;
                    console.log(data)
                    this.updatecategory.category_name = '';
                    this.updatecategory.category_image_url = '';  
                    this.$v.updatecategory.category_name.$reset();   
                    this.$v.updatecategory.category_image_url.$reset();  
                    this.$toast.success(data.message, {hideProgressBar: true, timeout: 3000,});   
                    this.getCategory();
                    bootstrap.Modal.getOrCreateInstance(document.getElementById('updatecategoryModal')).hide();     
                })                    
            }catch(error){    
                console.log(error)   
                this.$toast.error(error.message, {hideProgressBar: true, timeout: 3000,});                                   
            }             
        }
    },   
    async editCategory(id, name, url, public_id) {

        this.updatepublic_id = public_id
        this.category_id = id
        this.updatecategory.category_name = name
        this.updatecategory.category_image_url = url
      

        bootstrap.Modal.getOrCreateInstance(document.getElementById('updatecategoryModal')).show();

    }, 
    async deleteCategory(id, public_id) { 
        
        this.$swal({
            title: 'Are you sure?',
            text: "Do you want to delete this Category?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#0d6efd',
            cancelButtonColor: '#d33',
            confirmButtonText: 'delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                axios.delete(`${process.env.VUE_APP_API_URL}common/category/delete/?category_id=${id}&public_id=${public_id}`, Header.APP_ADMIN_HEADER)
                .then(response => {
                    console.log(response);
                    this.getCategory(); 
                    this.$toast.success(response.data.message, {hideProgressBar: true, timeout: 3000,});    
                })
                .catch(function (error) {
                    console.log(error)
                    this.$toast.error(error.message, {hideProgressBar: true, timeout: 3000,});  
                })  
            }
        })   
    },         
    category_errorUploading (file, message, xhr) {
        console.log(file)
        this.$toast.error(message, {hideProgressBar: true, timeout: 3000,});  
    },
    category_fileUploaded (file, response) {
        console.log(file.dataURL)
        this.category.category_image_url = file.dataURL
    },
    category_removefile (file, error, xhr){
        console.log(file)
        this.category.category_image_url = ""
    },
    updatecategory_errorUploading (file, message, xhr) {
        console.log(file)
        this.$toast.error(message, {hideProgressBar: true, timeout: 3000,});  
    },
    updatecategory_fileUploaded (file, response) {
        console.log(file.dataURL)
        this.updatecategory.category_image_url = file.dataURL
    },
    updatecategory_removefile (file, error, xhr){
        console.log(file)
        this.updatecategory.category_image_url = ""
    },    
  },
  mounted(){
    this.getCategory();
  }
}
</script>

<style>

</style>