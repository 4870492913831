<template>
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-lg-12">
                            <button type="button" class="btn btn-primary pull-right btn-lg" data-bs-toggle="modal" data-bs-target="#AddNewBedModal">Add New Bed</button>
                            <v-client-table ref="infoPagesTable" :columns="columns" :data="data" :options="options" id="dataTable" class="main-data-table">
                                <div slot-scope="props" slot="id">
                                    <button @click="editBed(props.row.id, props.row.name, props.row.description)" class="btn btn-primary" type="button"><i class="fa-solid fa-pencil"></i></button>
                                    <button @click="deleteBed(props.row.id)" class="btn btn-danger" type="button"><i class="fa-solid fa-trash-can"></i></button>
                                    <button @click="editInfoPage(props.row.id)" class="btn btn-info" type="button"><i class="ri-eye-fill"></i></button>

                                </div>   
                                <div slot-scope="props" slot="image_url">                                         
                                    <img class="table-img" :src="props.row.image_url" />
                                </div>
                                <template v-slot:child_row="props">
                                    <div class="list-br"><b>Description:</b> {{props.row.description}}</div>
                                    <div class="list-br"><b>Hotel ID:</b> {{props.row.hotel_id}}</div>
                                </template>                                 
                            </v-client-table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Add New Beds modal -->
        <div class="modal fade" id="AddNewBedModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <form @submit.prevent="bedsubmit" ref="bedform">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Add New Bed</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="form-group mb-3">
                                <label for="bed" class="form-label">Bed Name</label>
                                <input type="text" v-model="beds.name" id="bed" name="bed" class="form-control" placeholder="Bed Name" 
                                :class="{ 'is-invalid': bedsubmitted && $v.beds.name.$error }">
                                <div v-if="bedsubmitted && !$v.beds.name.required" class="invalid-feedback">Bed Name required</div>
                            </div>
                            <div class="form-group mb-3">
                                <label for="image_url" class="form-label">Description</label>
                                <div class="card auto-height mb-0">
                                    <textarea class="form-control" v-model="beds.description" id="description" rows="5" placeholder="Bed description"
                                    :class="{ 'is-invalid': bedsubmitted && $v.beds.description.$error }"
                                    ></textarea>     
                                    <div v-if="bedsubmitted && !$v.beds.description.required" class="invalid-feedback">Bed description required</div>                          
                                </div>
                                <!-- <input type="text" v-model="amenity.amenity_image_url" id="amenity_image_url" name="amenity_image_url" class="form-control" placeholder="Amenity Image Url" 
                                    :class="{ 'is-invalid': amenitysubmitted && $v.amenity.amenity_image_url.$error }"> -->
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="submit" class="btn btn-primary">Save changes</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>

        <!-- Update Beds modal -->
        <div class="modal fade" id="updateBedModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <form @submit.prevent="updatebedsubmit" ref="updatebedform">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Update Bed</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="form-group mb-3">
                                <label for="bed" class="form-label">Bed Name</label>
                                <input type="text" v-model="beds.update_name" id="bed" name="bed" class="form-control" placeholder="Bed Name" 
                                :class="{ 'is-invalid': updatebedsubmitted && $v.beds.update_name.$error }">
                                <div v-if="updatebedsubmitted && !$v.beds.update_name.required" class="invalid-feedback">Bed Name required</div>
                            </div>
                            <div class="form-group mb-3">
                                <label for="image_url" class="form-label">Bed Image Url</label>
                                <div class="card auto-height mb-0" 
                                :class="{ 'is-invalid': updatebedsubmitted && $v.beds.update_image_url.$error }">
                                    <vue-dropzone 
                                        ref="updatemyVueDropzone"
                                        :options="dropzoneOptions"
                                        @vdropzone-error="update_errorUploading"
                                        @vdropzone-success="update_fileUploaded"
                                        :useCustomSlot=true                                         
                                        >
                                        <div class="dropzone-custom-content">
                                            <h3 class="dropzone-custom-title">Drag and drop to upload Image</h3>
                                            <img :src="beds.update_image_url" />
                                        </div>                                         
                                    </vue-dropzone>                                
                                </div>
                                <!-- <input type="text" v-model="amenity.amenity_image_url" id="amenity_image_url" name="amenity_image_url" class="form-control" placeholder="Amenity Image Url" 
                                    :class="{ 'is-invalid': amenitysubmitted && $v.amenity.amenity_image_url.$error }"> -->
                                <div v-if="updatebedsubmitted && !$v.beds.update_image_url.required" class="invalid-feedback">Description required</div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button class="btn btn-primary">Save changes</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>        
    </div>
</template>
<script>
import axios from 'axios';
import Header from "../../../mixins/Header";
import { required } from "vuelidate/lib/validators";
import {ClientTable, Event} from 'vue-tables-2'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
export default {  
  components: { ClientTable, Event, vueDropzone: vue2Dropzone  },
  name: "room_info",
  data(){
      return{
        data: [],
        dropzoneOptions: {
            id: "facility_image",
            url: 'https://httpbin.org/post',
            thumbnailWidth: 150,
            maxFilesize: 0.5,
            addRemoveLinks: true,
            uploadMultiple: false,
            maxFilesize: 20,
            autoProcessQueue: true,
            acceptedFiles: "image/*",
            maxFiles: 1,
            headers: { "My-Awesome-Header": "header value" },
        },
        hotel_id: this.$route.query.hotel_id,
        room_id: this.$route.query.room_id,
        columns: ['name', 'description', 'hotel_id','id'],
        options: {
            headings: {
                name: 'Bed Name',
                image_url: 'Image',
                hotel_id: "Hotel ID",
                id: "Action"
            },
            perPage: 8,
            columnsDisplay: {
                name: 'desktopL',
                description: 'not_mobile',
                hotel_id: 'not_mobile',
                id: 'desktopL',
            },             
            sortable: ['name'],
            filterable: [],
            sortIcon: { base:'fa', up:'fa-sort-asc', down:'fa-sort-desc', is:'fa-sort' },
            pagination: {
                chunk: 5,
                edge: false,
                nav: 'scroll'
            }
        },         
        beds:{
            name: "",
            description: "",     
            update_name: "",
            update_description: ""   
        },      
        bedsubmitted: false,
        updatebedsubmitted: false,
        submitted: false,
        updateBeddata: []
      }
  },
  validations: {
      beds: {
        name: { required },
        description: { required },   
        update_name: { required },
        update_description: { required }
      },      
  }, 
  methods:{
    async editInfoPage(id) {
        this.$refs.infoPagesTable.toggleChildRow(id);
    },      
    async bedsubmit(e) { 

      this.bedsubmitted = true;

        this.$v.beds.name.$touch();
        this.$v.beds.description.$touch();

        if (!this.$v.beds.name.$invalid || !this.$v.beds.description.$invalid) {  

            var reqBody = {
                bed: this.beds.name,
                description: this.beds.description
            }

            console.log(reqBody)            
            
            try{
                let response = await this.axios
                .post(`${process.env.VUE_APP_API_URL}room/detail/create?hotel_id=${this.hotel_id}`, reqBody, Header.APP_ADMIN_HEADER)
                .then((res) => {
                    let data = res.data;
                    console.log(data)
                    this.beds.name = '';
                    this.beds.description = '';  
                    this.$v.beds.name.$reset();   
                    this.$v.beds.description.$reset();  
                    this.getBedList();
                    this.$toast.success(data.message, {hideProgressBar: true, timeout: 3000,});    
                    bootstrap.Modal.getOrCreateInstance(document.getElementById('AddNewBedModal')).hide();    
                })                    
            }catch(error){    
                console.log(error)   
                this.$toast.error(error.message, {hideProgressBar: true, timeout: 3000,});    
                    this.beds.name = '';
                    this.beds.description = '';  
                    this.$v.beds.name.$reset();   
                    this.$v.beds.description.$reset();                                                 
            }            
            
        } 
    },
    async deleteBed(id) { 
        
        this.$swal({
            title: 'Are you sure?',
            text: "Do you want to delete this Bed?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#0d6efd',
            cancelButtonColor: '#d33',
            confirmButtonText: 'delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                axios.delete(`${process.env.VUE_APP_API_URL}room/detail/delete?room_detail_id=${id}`, Header.APP_ADMIN_HEADER)
                .then(response => {
                    console.log(response);
                    this.getBedList(); 
                    this.$toast.success(response.data.message, {hideProgressBar: true, timeout: 3000,});    
                })
                .catch(function (error) {
                    console.log(error)
                    this.$toast.error(error.message, {hideProgressBar: true, timeout: 3000,});  
                })  
            }
        })   
    },    
    async updatebedsubmit(e) { 

      this.updatebedsubmitted = true;

        this.$v.beds.update_name.$touch();
        this.$v.beds.update_image_url.$touch();

        if (!this.$v.beds.update_name.$invalid || !this.$v.beds.update_image_url.$invalid) {  

            var reqBody = {
                bed: this.beds.update_name,
                image_url: this.beds.update_image_url
            }

            console.log(reqBody)            
            
            try{
                let response = await this.axios
                .put(`${process.env.VUE_APP_API_URL}room/detail/create?room/detail/update?room_detail_id=${this.room_id}&hotel_id=${this.hotel_id}`, reqBody, Header.APP_ADMIN_HEADER)
                .then((res) => {
                    let data = res.data;
                    console.log(data)
                    this.beds.update_name = '';
                    this.beds.update_image_url = '';
                    this.$v.beds.update_name.$reset();
                    this.$v.beds.update_image_url.$reset();
                    this.getBedList();
                    this.$toast.success(data.message, {hideProgressBar: true, timeout: 3000,});    
                    bootstrap.Modal.getOrCreateInstance(document.getElementById('updateBedModal')).hide();    
                })                    
            }catch(error){    
                console.log(error)   
                this.$toast.error(error.message, {hideProgressBar: true, timeout: 3000,});
                this.beds.update_name = '';
                this.beds.update_image_url = '';  
                this.$v.beds.update_name.$reset();
                this.$v.beds.update_image_url.$reset();                                                  
            }            
            
        } 
    },
    async getBedList() {

        this.data = []

        try {
          let response = await this.axios
            .get(`${process.env.VUE_APP_API_URL}room/detail/get/list/?hotel_id=${this.hotel_id}`, Header.APP_ADMIN_HEADER)
            .then((response) => {
                console.log(response.data)
              for (let a = 0; a < response.data.length; a++){
                this.data.push({
                  id :response.data[a].id,
                  hotel_id: response.data[a].hotel_id,
                  name :response.data[a].bed,
                  description:response.data[a].description,
                })               
              }
            })
        } catch (error) {
          console.log(error)          
        }
    },   
    errorUploading (file, message, xhr) {
    console.log(file)
    this.$toast.error(message, {hideProgressBar: true, timeout: 3000,});  
    },
    fileUploaded (file, response) {
        this.beds.image_url = file.dataURL
        console.log(response)
    },
    update_errorUploading (file, message, xhr) {
    console.log(file)
    this.$toast.error(message, {hideProgressBar: true, timeout: 3000,});  
    },
    update_fileUploaded (file, response) {
        this.beds.update_image_url = file.dataURL
        console.log(response)
    },    
    async editBed(id, name, url) {

        this.updateBeddata = []
        this.updateBeddata.push({
            id: id
        });         
        this.beds.update_name = name
        this.beds.update_image_url = url

        bootstrap.Modal.getOrCreateInstance(document.getElementById('updateBedModal')).show();          
    }     
  },
  created(){
      this.getBedList();
  }
}
</script>

<style>

</style>