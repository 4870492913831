<template>
<div>
    <div class="card">
        <div class="card-body">
            <vue-dropzone 
                ref="myVueDropzone"
                :options="dropzoneOptions"
                @vdropzone-error="errorUploading"
                @vdropzone-success="fileUploaded"
                >
            </vue-dropzone>

            <div class="row mt-4">
                <div class="col-md-4 col-6 col-sm-6 col-lg-2" v-for="images in data" :key="images.id">
                    <div class="img-slot">
                        <div class="delete-btn" @click="deleteimage(images.id)">
                            <i class="ri-delete-bin-6-line"></i>
                        </div>
                        <img :src="images.image_url" />
                    </div>
                </div>
            </div>            
        </div>
    </div>
        <div class="row mt-4">
            <div class="col-lg-12 text-right mb-4 footer-btn-action-area">
                <button @click="imageubmit" class="btn btn-primary float-end footer-btn-action no-margin">Save</button>
                <button type="button" class="btn btn-secondary float-end footer-btn-action">Cancel</button>
            </div>    
        </div>    
</div>
</template>
<script>
import axios from 'axios';
import Header from "../../../mixins/Header";
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
export default {
    data() {
        return{
            ImageArray:[],
            data: [],
            gallery: [],
            room_id: this.$route.query.room_id,
            dropzoneOptions: {
                id: "",
                url: 'https://httpbin.org/post',
                thumbnailWidth: 150,
                maxFilesize: 0.5,
                addRemoveLinks: true,
                uploadMultiple: false,
                maxFilesize: 20,
                autoProcessQueue: true,
                acceptedFiles: "image/*",
                maxFiles: 20,
                headers: { "My-Awesome-Header": "header value" },
            },
        }
    },
    components:{
        vueDropzone: vue2Dropzone
    },
    props: {
        id: {
            type: String,
            required: true
        },
        url: {
            type: String,
            required: true
        }
    },    
    methods: {
        async imageubmit () {

            this.imagesubmitted = true;

            if(this.gallery.length  > 0){

                var reqBody = {
                    images: this.ImageArray
                }
                console.log(reqBody)
            
                try{
                    let response = await this.axios
                    .post(`${process.env.VUE_APP_API_URL}room/images/upload/?room_id=${this.room_id}`, reqBody, Header.APP_ADMIN_HEADER)
                    .then((res) => {
                        let data = res.data;
                        console.log(data)
                        this.$refs.myVueDropzone.removeAllFiles();
                        this.getimages();
                        this.$toast.success(res.data.message, {hideProgressBar: true, timeout: 3000,});       
                    })                    
                }catch(error){    
                    console.log(error)   
                    this.$toast.error(error.message, {hideProgressBar: true, timeout: 3000,});                                   
                }
            }  
            else{
                this.$toast.error("Please upload the images.", {hideProgressBar: true, timeout: 3000,});                
            }     
        },        
      errorUploading (file, message, xhr) {
        console.log(file)
        this.$toast.error(message, {hideProgressBar: true, timeout: 3000,});  
      },
      fileUploaded (file, response) {
          
        this.gallery.push({
            imageurl: file.dataURL,
        })
        console.log(response)
        console.log(this.gallery)

        for(let i=0;i < this.gallery.length ;i++){
            // this.ImageArray.push({
            //     images:this.gallery[i].imageurl
            // })
            this.ImageArray[i]=this.gallery[i].imageurl
        }

        console.log("###########")
        console.log(this.ImageArray)


        
      },
    async getimages() {

        this.data = []

        try {
          let response = await this.axios
            .get(`${process.env.VUE_APP_API_URL}room/images/get/?room_id=${this.room_id}`, Header.APP_ADMIN_HEADER)
            .then((response) => {
                console.log(response)
                for (let a = 0; a < response.data.length; a++){
                        this.data.push({
                        image_url :response.data[a].image_urls,
                        id :response.data[a].id,   
                    })               
                }
            })
        } catch (error) {
          console.log(error)          
        }
    },   
    async deleteimage(id) { 
        
        this.$swal({
            title: 'Are you sure?',
            text: "Do you want to delete this Image?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#0d6efd',
            cancelButtonColor: '#d33',
            confirmButtonText: 'delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                axios.delete(`${process.env.VUE_APP_API_URL}room/images/delete?image_id=${id}`, Header.APP_ADMIN_HEADER)
                .then(response => {
                    console.log(response);
                    this.getimages(); 
                    this.$toast.success(response.data.message, {hideProgressBar: true, timeout: 3000,});    
                })
                .catch(function (error) {
                    console.log(error)
                    this.$toast.error(error.message, {hideProgressBar: true, timeout: 3000,});  
                })  
            }
        })   
    },        
    },
    mounted(){
        this.getimages();
    }
}
</script>

<style>

</style>