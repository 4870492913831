<template>
  <div class="app-area">
    <header2/>
    <sidebar/>
    <page-content>
      <slot />
    </page-content>
     <footer/>
  </div>
</template>
<script>
import Footer from '../components/Footer/Footer.vue'
import Header2 from '../components/Header/TopHeader.vue'
import PageContent from '../components/PageContent/PageContent.vue'
import Sidebar from '../components/Sidebar/Sidebar.vue'
export default {
  name: 'DefaultContainer',
  components: {
    Header2,
    Sidebar,
    Footer,
    PageContent
  },
  data () {
    return {
    }
  },
  methods: {
  },
  computed: {
  }
}
</script>