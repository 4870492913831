<template>
  <div class="user-profile">
    <div class="row">
        <div class="col-md-4">
            <div class="user-card">
                <div class="user-img">
                    <i class="ri-shield-user-fill"></i>
                    <h4>{{ this.Fname }} {{ this.Lname }}</h4>
                <h5>Super Admin</h5>                    
                </div>
                <div class="user-info">
                    <ul>
                        <li>
                            <div class="top">
                                <i class="ri-fingerprint-line"></i>                              
                            </div>
                            <div class="next-top">
                                <b>Sub ID:</b>
                                <span class="subid">{{ this.subId }}</span>
                            </div>
                        </li>                        
                        <li>
                            <div class="top">
                                <i class="ri-calendar-todo-line"></i>                              
                            </div>
                            <div class="next-top">
                                <b>Created At:</b>
                                <span>{{ this.createdAt.split('T')[0] }}</span>
                            </div>
                        </li>
                        <li>
                            <div class="top">
                                <i class="ri-calendar-event-line"></i>                         
                            </div>
                            <div class="next-top">
                                <b>Updated At:</b>
                                <span>{{ this.updatedAt.split('T')[0] }}</span>
                            </div>
                        </li>                        
                    </ul>
                </div>
            </div>
        </div>
        <div class="col-md-8">
            <button type="button" class="btn btn-primary pull-right btn-lg edit-btn" v-if="!edittprofile" @click="editprofile"><i class="ri-pencil-line"></i> Edit Profiile</button>  
            <button v-else type="button" class="btn btn-primary pull-right btn-lg edit-btn" @click="viewprofile"><i class="ri-eye-line"></i> View Profiile</button>             
            <div class="user-form tab-profile">                
                <ul class="nav nav-pills mb-3 cus-top" id="pills-tab" role="tablist">
                    <li class="nav-item" role="presentation">
                        <button class="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">General</button>
                    </li>
                </ul>
                <div class="tab-content form-tab" id="pills-tabContent">
                    <div class="tab-pane fade right-setting show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                        <h3>Personal</h3>
                        <div class="row">                           
                            <div class="col-md-4">
                                <div class="form-group mb-3">
                                    <label for="first_name" class="form-label">First Name</label>
                                    <p v-if="!edittprofile">{{ this.Fname }}</p>
                                    <input v-show="edittprofile" type="text" v-model="Fname" class="form-control" id="first_name" name="first_name" placeholder="First Name"
                                    :class="{ 'is-invalid': $v.Fname.$error }">
                                    <div v-if="!$v.Fname.required" class="invalid-feedback">First Name required</div>                                    
                                 </div>                                 
                            </div>
                            <div class="col-md-4">
                                <div class="form-group mb-3">
                                    <label for="first_name" class="form-label">Last Name</label>
                                    <p v-if="!edittprofile">{{ this.Lname }}</p>
                                    <input v-show="edittprofile" type="text" v-model="Lname" class="form-control" id="first_name" name="first_name" placeholder="Last Name" 
                                    :class="{ 'is-invalid': $v.Lname.$error }">
                                    <div v-if="!$v.Lname.required" class="invalid-feedback">Last Name required</div>
                                 </div>                                 
                            </div>
                            <div class="col-md-4">
                                <div class="form-group mb-3">
                                    <label for="first_name" class="form-label">Birthday</label>
                                    <date-picker v-if="edittprofile" v-model="birthday" class="width-100" format="YYYY-MM-DD" placeholder="Select Birthday">                                           
                                        </date-picker>
                                  <p v-if="!edittprofile">No added</p>    
                                </div>                                 
                            </div>                            
                        </div>
                        <div class="row mb-4">

                        </div>  
                        <h3>Contact</h3>    
                        <div class="row mb-3">
                            <div class="col-md-4">
                                <div class="form-group mb-3">
                                    <label for="first_name" class="form-label">Mobile Number</label>
                                    <p v-if="!edittprofile">({{ this.countryCode }}) {{ this.mobile }}</p>
                                    <input v-if="edittprofile && !mobileconverter" @input="checkmobilenumber(mobile)" type="number" v-model="mobile" v-bind="mobile" class="form-control" id="mobile" name="mobile" placeholder="Mobile NUmber">
                                    <vue-phone-number-input v-show="mobileconverter" 
                                    :class="{ 'is-invalid': $v.mobile.$error || showmobileerror}"
                                      @update="onUpdate" v-model="mobile" v-bind="mobile"></vue-phone-number-input> 
                                      <div v-if="!$v.mobile.required" class="invalid-feedback">Mobile Number required</div>
                                        <div v-if="mobileconverter && showmobileerror" class="invalid-feedback extra-error">Mobile Number is not valid with Country</div>                                                                     
                                 </div>                                 
                            </div>                            
                        </div>
                        <div class="row" v-if="edittprofile">
                            <div class="col-md-12">
                                <div class="bottom-save">
                                    <button type="button" class="btn btn-secondary btn-lg edit-btn" @click="cancelsave">Cancel</button>  
                                    <button type="button" class="btn btn-primary btn-lg edit-btn" @click="saveprofile">Save Profile</button>  
                                </div>
                            </div>
                        </div>
                    </div>                        
                </div>                
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import Header from "../../mixins/Header";
import DatePicker from 'vue2-datepicker';
import { required, email } from "vuelidate/lib/validators";
export default {
    data(){
        return{
            edittprofile: false,
            Fname: "",
            Lname: "",
            countryCode: "",
            createdAt: "",
            dateOfBirth: "", 
            mobile: "",
            subId: "",
            newbirthday: "",
            birthday:"",
            results: [],
            mobileconverter: false,
            showmobileerror: false
        }
    },
    validations: {
        Fname: { required },
        Lname: { required },  
        mobile: { required }
  },     
    components:{
        DatePicker
    },
    methods:{
        async saveprofile() {

            this.$v.Fname.$touch();
            this.$v.Lname.$touch();
            this.$v.mobile.$touch();

            if(this.$v.Fname.$error || this.$v.Lname.$error || this.$v.mobile.$error) {
            }
            else{
                if(this.showmobileerror == false) {

                    var reqBody = {
                        Fname: this.Fname,
                        Lname: this.Lname,
                        dateOfBirth: this.birthday,
                        mobile: this.mobile,
                        countryCode: this.countryCode
                    }

                    console.log(reqBody)

                    try{
                        let response = await this.axios
                        .post(`${process.env.VUE_APP_AUTH_SERVICE_API}users/update`, reqBody, Header.APP_ADMIN_HEADER)
                        .then((res) => {
                            let data = res.data;
                            console.log(data)
                            this.$toast.success(data.message, {hideProgressBar: true, timeout: 3000,});   
                            this.getAdminData();   
                        })                    
                    }catch(error){    
                        console.log(error)   
                        this.$toast.error(error.message, {hideProgressBar: true, timeout: 3000,});                                   
                    } 

                }
            }
        },
        async cancelsave() {
            this.edittprofile = false
        },
        async checkmobilenumber(number) {
            if(number !=mobile) {
                this.mobileconverter = true
            }
            else{
                this.mobileconverter = false
            }
            console.log(number)
        },
        async getAdminData() {
            this.data = []
            try {
                let response = await this.axios
                .get(`https://apidev-dev-auth.stagingsite.click/auth/authenticate/admin_admin`, Header.APP_ADMIN_HEADER)
                .then((response) => {
                    console.log(response)
                    this.Fname = response.data.Fname
                    this.Lname = response.data.Lname
                    this.countryCode = response.data.countryCode
                    this.dateOfBirth = response.data.dateOfBirth
                    this.mobile = response.data.mobile
                    this.subId = response.data.subId
                    this.createdAt = response.data.createdAt
                    this.updatedAt = response.data.updatedAt

                })

            } catch (error) {
                console.log(error)          
            }
        },  
        async editprofile() {
            this.edittprofile = true
        },
        async viewprofile() {
            this.edittprofile = false
        },
        async onUpdate(payload) {
        
        this.results = payload;
        this.countryCode = "+"+this.results.countryCallingCode

        console.log(this.results)

        if(!this.$v.mobile.$invalid) {

          if(this.results.isValid == true){
            this.showmobileerror = false
          }    
          
          if(this.results.isValid == false){
            this.showmobileerror = true
          }          

        }

        else {
          this.showmobileerror = false
        }

      },        
    },
    mounted(){
        this.getAdminData();
    }

}
</script>
<style lang="scss">
    @import "../../scss/Setting/Setting";
</style>