<template>
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-lg-12">
                            <button type="button" class="btn btn-primary pull-right btn-lg" data-bs-toggle="modal" data-bs-target="#AddNewRateModal">Add New Rate</button>
                            <v-client-table :columns="columns" :data="data" :options="options" id="dataTable" class="main-data-table"></v-client-table>
                            <div slot="Action" slot-scope="props" @click="showmore(props.row.id)" class="icon-eye"></div>                                                 
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Add New Facility modal -->
        <div class="modal fade" id="AddNewRateModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <form @submit.prevent="ratesubmit" ref="rateform">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Add New Rate</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="form-group mb-3">
                                        <label for="extra" class="form-label">Rate Plan</label>
                                        <input type="text" v-model="rate.plan" id="extra" name="plan" class="form-control" placeholder="Rate Plan" 
                                        :class="{ 'is-invalid': ratesubmitted && $v.rate.plan.$error }">
                                        <div v-if="ratesubmitted && !$v.rate.plan.required" class="invalid-feedback">Rate Plan required</div>
                                    </div>                                    
                                </div>
                                <div class="col-lg-12">
                                    <div class="form-group mb-3">
                                        <label for="description" class="form-label">Description</label>
                                        <input type="text" v-model="rate.description" id="description" name="description" class="form-control" placeholder="description" 
                                        :class="{ 'is-invalid': ratesubmitted && $v.rate.description.$error }">
                                        <div v-if="ratesubmitted && !$v.rate.description.required" class="invalid-feedback">Description required</div>
                                    </div>                                      
                                </div>
                            </div>                                                    
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="submit" class="btn btn-primary">Save changes</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
import Header from "../../mixins/Header";
import { required } from "vuelidate/lib/validators";
import {ClientTable, Event} from 'vue-tables-2'
export default {  
  components: { ClientTable, Event  },
  name: "room_info",
  data(){
      return{
        data: [],
        hotel_id: this.$route.query.hotel_id,
        room_id: this.$route.query.room_id,
        columns: ['date', 'email','amount','hash','maturity_date','id'],
        options: {
            headings: {
                date: 'Hotel Name',
                email: 'phone',
                amount: 'Country',
                hash: 'Provider',
                maturity_date: 'Status',
                id: 'Action'
            },
            sortable: ['date', 'email','amount', 'hash'],
            filterable: ['date', 'email','amount', 'hash', 'maturity_date'],
            sortIcon: { base:'fa', up:'fa-sort-asc', down:'fa-sort-desc', is:'fa-sort' },
            pagination: {
                chunk: 5,
                edge: false,
                nav: 'scroll'
            }
        },         
        rate:{
            plan: "",
            description: "",      
        },      
        ratesubmitted: false,
        submitted: false
      }
  },
  validations: {
      rate: {
        plan: { required },
        description: { required },   
      },      
  }, 
  methods:{    
    async ratesubmit(e) { 

      this.ratesubmitted = true;

        this.$v.rate.plan.$touch();
        this.$v.rate.description.$touch();

        if (!this.$v.rate.plan.$invalid || !this.$v.rate.description.$invalid) {  

            var reqBody = {
                meal_plan: this.meal.meal_plan,
                description: this.meal.description,
            }

            console.log(reqBody)    
            
          try{
              let response = await this.axios
              .post(`${process.env.VUE_APP_API_URL}mealplan/create?hotel_id=${this.hotel_id}`, reqBody, Header.APP_ADMIN_HEADER)
              .then((res) => {
                  console.log(res);   
                  this.$toast.success(res.data.message, {hideProgressBar: true, timeout: 3000,});      
              })                    
          }catch(error){    
              console.log(error)   
              this.$toast.error(error.message, {hideProgressBar: true, timeout: 3000,});                                   
          }             
            
        } 
    },            
    async roomsubmit(e) {
      this.roomsubmitted = true;
      this.$v.room.room_type_id.$touch();
      this.$v.room.room_name.$touch();
      this.$v.room.room_allotments.$touch();
      this.$v.room.short_des.$touch();
      this.$v.room.long_des.$touch();
      this.$v.room.max_adults.$touch();
      this.$v.room.max_child.$touch();
      this.$v.room.child_age.$touch();      

      if(
        !this.$v.room.room_type_id.$invalid || 
        !this.$v.room.room_name.$invalid ||
        !this.$v.room.room_allotments.$invalid ||
        !this.$v.room.short_des.$invalid ||
        !this.$v.room.long_des.$invalid ||
        !this.$v.room.max_adults.$invalid ||
        !this.$v.room.max_child.$invalid ||
        !this.$v.room.child_age.$invalid    
      ){
          alert("success")
      }
 
        var reqBody = {
          room_type_id: this.room.room_type_id,
          room_name: this.room.room_name,
          room_allotments: this.room.room_allotments,
          short_des: this.room.short_des,
          long_des: this.room.long_des,
          max_adults: this.room.max_adults,
          max_child: this.room.max_child,
          child_age: this.room.child_age,
        }   
        
        console.log(reqBody)
    }, 
    async getFacilityList() {
        try {
          let response = await this.axios
            .get(`${process.env.VUE_APP_API_URL}room/facility/get/list?room_id=${this.hotel_id}`, Header.APP_ADMIN_HEADER)
            .then((response) => {
                console.log(response.data)
              for (let a = 0; a < response.data.length; a++){
                this.data.push({
                  room_type_id :response.data[a].room_type_id,
                  room_name:response.data[a].room_name,
                  room_allotments:response.data[a].room_allotments,
                  max_adults:response.data[a].max_adults,
                  max_child:response.data[a].max_child,
                  child_age:response.data[a].child_age,
                  id:response.data[a].id,
                })               
              }
            })
        } catch (error) {
          console.log(error)          
        }
    },   
    submitForm () {
    }       
  },
  created(){
  }
}
</script>

<style>

</style>