<template>
    <default-container>
        <nav class="page-breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <router-link :to="`edit_hotel?hotel_id=${this.$route.query.hotel_id}`">
                    <h4 class="page-title"><i class="fa-regular fa-circle-left"></i> Back to Hotel</h4>
                    </router-link>
                </li>
                    
                <li class="breadcrumb-item no-arrow"><router-link :to="`/hotel_list`">Manage Hotel</router-link></li>
                <li class="breadcrumb-item"><router-link :to="`edit_hotel?hotel_id=${this.$route.query.hotel_id}`">Hotel Details</router-link></li>
                <li class="breadcrumb-item active" aria-current="page">Add New Room</li>
            </ol>
        </nav>   
        <ul class="nav nav-pills add-new-hotel mb-3" id="pills-tab" role="tablist">
            <li class="nav-item" role="presentation">
                <button class="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Room General</button>
            </li>
            <li class="nav-item" role="presentation">
                <button class="nav-link disabled" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Facilities</button>
            </li>
            <li class="nav-item" role="presentation">
                <button class="nav-link disabled" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact"  aria-selected="false">Pictures</button>
            </li>
            <li class="nav-item" role="presentation">
                <button class="nav-link disabled" id="beds-tab" data-bs-toggle="pill" data-bs-target="#pills-beds" type="button" role="tab" aria-controls="pills-beds"  aria-selected="false">Beds</button>
            </li>
            <li class="nav-item" role="presentation">
                <button class="nav-link disabled" id="meal-tab" data-bs-toggle="pill" data-bs-target="#pills-meal" type="button" role="tab" aria-controls="pills-meal"  aria-selected="false">Meal Plans</button>
            </li>         
            <li class="nav-item" role="presentation">
                <button class="nav-link disabled" id="extra-tab" data-bs-toggle="pill" data-bs-target="#pills-extra" type="button" role="tab" aria-controls="pills-extra"  aria-selected="false">Room Extra</button>
            </li>             
        </ul>
        <div class="tab-content" id="pills-tabContent">
            <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                <general-room />
            </div>
            <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
            </div>
            <div class="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
            </div>
        </div>           
    </default-container>
</template>
<script>
import DefaultContainer from '../../containers/DefaultContainer.vue'
import GeneralRoom from '../../components/EditHotel/GeneralRoom.vue';

export default {    
  components: { DefaultContainer, GeneralRoom },
  name: "add_room_info",
  data(){
      return{
        data: [],
        roomsubmitted: false,
        submitted: false
      }
  }, 
  methods:{       
  },
  created(){
  }
}
</script>

<style>

</style>