<template>
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="row">
                                <div class="col-lg-4">
                                    <div class="form-group mb-3">
                                        <label for="first_name" class="form-label">First Name</label>
                                        <input type="text" class="form-control" id="first_name" name="first_name" placeholder="First Name">
                                        <!-- <div v-if="hotelsubmitted && !$v.hotel.hotel_name.required" class="invalid-feedback">Hotel Name required</div> -->
                                    </div>                                                  
                                </div>
                                <div class="col-lg-4">
                                    <div class="form-group mb-3">
                                        <label for="first_name" class="form-label">Last Name</label>
                                        <input type="text" class="form-control" id="first_name" name="first_name" placeholder="First Name">
                                        <!-- <div v-if="hotelsubmitted && !$v.hotel.hotel_name.required" class="invalid-feedback">Hotel Name required</div> -->
                                    </div>                                                  
                                </div>
                                <div class="col-lg-4">
                                    <div class="form-group mb-3">
                                        <label for="first_name" class="form-label">Email</label>
                                        <input type="text" class="form-control" id="first_name" name="first_name" placeholder="First Name">
                                        <!-- <div v-if="hotelsubmitted && !$v.hotel.hotel_name.required" class="invalid-feedback">Hotel Name required</div> -->
                                    </div>                                                  
                                </div>                                 
                            </div>
                            <div class="row mb-4">
                                <div class="col-lg-4">
                                    <div class="form-group mb-3">
                                        <label for="first_name" class="form-label">Gender</label>
                                        <input type="text" class="form-control" id="first_name" name="first_name" placeholder="First Name">
                                        <!-- <div v-if="hotelsubmitted && !$v.hotel.hotel_name.required" class="invalid-feedback">Hotel Name required</div> -->
                                    </div>                                                  
                                </div>
                                <div class="col-lg-4">
                                    <div class="form-group mb-3">
                                        <label for="first_name" class="form-label">Birthdate</label>
                                        <input type="text" class="form-control" id="first_name" name="first_name" placeholder="First Name">
                                        <!-- <div v-if="hotelsubmitted && !$v.hotel.hotel_name.required" class="invalid-feedback">Hotel Name required</div> -->
                                    </div>                                                  
                                </div>                                  
                            </div>
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="form-group mb-3">
                                        <button class="btn btn-primary pull-left btn-lg" @click="usersignUp">Add New User</button> 

<br/><br/>
                                        <input v-model="uemail" type="text" class="form-control" id="uemail" name="uemail" placeholder="email"><br/>
                                        <input v-if="showpassword" v-model="utop" type="number" class="form-control" id="otp" name="otp" placeholder="OTP"><br/>
                                        <button v-if="!showpassword" class="btn btn-danger pull-left btn-lg" @click="userSignin(uemail)">Sign in</button>
                                        <button class="btn btn-danger pull-left btn-lg" @click="userSigninbycode(utop)">OTP Check</button>

                                        <br/>
                                        <br/>
                                        <div style="cursor: pointer;" @click="logout">logout</div>

                                    </div>                                                  
                                </div>                                 
                            </div>                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { Auth } from 'aws-amplify';
export default {  
  components: {  },
  name: "add_new_user",
  data(){
      return{
        data: [],     
        uemail: "", 
        utop: "",
        bedsubmitted: false,
        submitted: false,
        showpassword: false,
        state: {
            cognitoUser: []
        }
      }
  },
  methods:{  
    async usersignUp() {

        const params = {
            password: "Praveen@123",
            username: "praveen@persystance.com",
            attributes: {
                email: "praveen@persystance.com",
                given_name: "praveen",
                family_name: "user1",
            }             
        };
        try {
            await Auth.signUp(params)
            .then(data=>{
                console.log(data)
            })
        } catch (error) {
            console.log(error);
        } 
    },
    async userSignin(email) {

        try {
            await Auth.signIn(email)
            .then(data=>{
                console.log(data)
                this.state.cognitoUser = data
                this.showpassword = true
            })
        } catch (error) {
            console.log(error);
            this.$toast.error(error, {hideProgressBar: true, timeout: 3000,});  
        }   

    },
    async userSigninbycode(code) {

        let otp = code
        let user = this.state.cognitoUser;
        
        console.log(otp)
        console.log(user)

        try {
            await Auth.sendCustomChallengeAnswer(user, otp)
            .then(data=>{
                console.log(data)
                if(data.storage.length == 0){
                    this.$toast.error("Incorrect OTP code", {hideProgressBar: true, timeout: 3000,});  
                }
                else{
                    this.$toast.success("successfully login", {hideProgressBar: true, timeout: 3000,});  
                } 
            })
        } catch (error) {
            console.log(error);
            this.$toast.error(error, {hideProgressBar: true, timeout: 3000,});
        }

    },
    async logout() {
        try {
            await Auth.signOut()
            .then(data=>{
                this.$toast.success("successfully logout", {hideProgressBar: true, timeout: 3000,});   
            })
        } catch (error) {
            console.log(error);
            this.$toast.error(error, {hideProgressBar: true, timeout: 3000,});    
        } 
    }
    // getRandomString(bytes: number) {
    //     const randomValues = new Uint8Array(bytes);
    //     window.crypto.getRandomValues(randomValues);
    //     return Array.from(randomValues).map(intToHex).join('');
    // },

    // intToHex(nr: number) {
    //     return nr.toString(16).padStart(2, '0');
    // },    
  },
  created(){
    
  }
}
</script>

<style>

</style>