<template>
    <default-container>
        <nav class="page-breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="#">Hotel</a></li>
                <li class="breadcrumb-item active" aria-current="page">Edit Hotel</li>
            </ol>
        </nav>   
        <h4 class="page-title">Edit Hotel</h4>
        
        <ul class="nav nav-pills add-new-hotel mb-3" id="pills-tab" role="tablist">
            <li class="nav-item" role="presentation">
                <button class="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">General Info</button>
            </li>
            <li class="nav-item" role="presentation">
                <button class="nav-link" id="phone-tab" data-bs-toggle="pill" data-bs-target="#phone-profile" type="button" role="tab" aria-controls="phone-profile" aria-selected="false">Phone Numbers</button>
            </li>             
            <li class="nav-item" role="presentation">
                <button class="nav-link" id="type-profile-tab" data-bs-toggle="pill" data-bs-target="#type-profile" type="button" role="tab" aria-controls="type-profile" aria-selected="false">Rooms Type</button>
            </li>
            <li class="nav-item" role="presentation">
                <button class="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Rooms info</button>
            </li>
            <li class="nav-item" role="presentation">
                <button class="nav-link" id="pic-contact-tab" data-bs-toggle="pill" data-bs-target="#pic-contact" type="button" role="tab" aria-controls="pic-contact"  aria-selected="false">Pictures</button>
            </li>
            <li class="nav-item" role="presentation">
                <button class="nav-link" id="taxation-tab" data-bs-toggle="pill" data-bs-target="#taxation" type="button" role="tab" aria-controls="taxation"  aria-selected="false">Taxation</button>
            </li>
            <li class="nav-item" role="presentation">
                <button class="nav-link" id="season-tab" data-bs-toggle="pill" data-bs-target="#season" type="button" role="tab" aria-controls="season"  aria-selected="false">Hotel Season</button>
            </li>            
        </ul>
        <div class="tab-content" id="pills-tabContent">
            <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                <generalinfo />
            </div>
            <div class="tab-pane fade" id="phone-profile" role="tabpanel" aria-labelledby="phone-tab">
               <phone-numbers />
            </div>              
            <div class="tab-pane fade" id="type-profile" role="tabpanel" aria-labelledby="type-home-tab">
                <room-type />
            </div>            
            <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                <roomsinfo />
            </div>
            <div class="tab-pane fade" id="pic-contact" role="tabpanel" aria-labelledby="pic-contact-tab">
                <pictures />
            </div>
            <div class="tab-pane fade" id="taxation" role="tabpanel" aria-labelledby="taxation-tab">
                <taxation />
            </div>
            <div class="tab-pane fade" id="season" role="tabpanel" aria-labelledby="season-tab">
               <hotel-season />
            </div>            
        </div>

        <!-- Amenity modal -->
        <div class="modal fade" id="addNewRoomModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <form @submit.prevent="amenitysubmit" ref="amenityform">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Add New Amenity</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="form-group mb-3">
                                <label for="amenity_name" class="form-label">Amenity Name</label>
                                <input type="text" v-model="amenity.amenity_name" id="amenity_name" name="amenity_name" class="form-control" placeholder="Amenity Name" 
                                :class="{ 'is-invalid': amenitysubmitted && $v.amenity.amenity_name.$error }">
                                <div v-if="amenitysubmitted && !$v.amenity.amenity_name.required" class="invalid-feedback">Amenity Name required</div>
                            </div>
                            <div class="form-group mb-3">
                                <label for="amenity_image_url" class="form-label">Amenity Image Url</label>
                                <input type="text" v-model="amenity.amenity_image_url" id="amenity_image_url" name="amenity_image_url" class="form-control" placeholder="Amenity Image Url" 
                                :class="{ 'is-invalid': amenitysubmitted && $v.amenity.amenity_image_url.$error }">
                                <div v-if="amenitysubmitted && !$v.amenity.amenity_image_url.required" class="invalid-feedback">Amenity Image Url required</div>
                            </div>               
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="submit" class="btn btn-primary">Save changes</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>            
    </default-container>
</template>

<script>
import Vue from 'vue'
import axios from 'axios';
import DefaultContainer from '../../containers/DefaultContainer.vue'
import { required } from "vuelidate/lib/validators";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import {ClientTable, Event} from 'vue-tables-2'
import mixinHotel from "@/mixins/Hotel/hotel";
import Generalinfo from '../../components/EditHotel/Generalinfo.vue';
import Roomsinfo from '../../components/EditHotel/EditRoom/Roomsinfo.vue';
import Rates from '../../components/EditHotel/Rates.vue';
import Promotion from '../../components/EditHotel/Promotion.vue';
import Pictures from '../../components/EditHotel/PIctures.vue';
import Taxation from '../../components/EditHotel/EditRoom/Taxation.vue';
import RoomType from '../../components/EditHotel/EditRoom/RoomType.vue';
import PhoneNumbers from '../../components/EditHotel/EditRoom/PhoneNumbers.vue';
import HotelSeason from '../../components/EditHotel/HotelSeason.vue';

Vue.use(ClientTable)

export default {
    mixins: [
        mixinHotel
    ],    
  components: { DefaultContainer, DatePicker,  ClientTable, Event, Generalinfo, Roomsinfo, Rates, Promotion, Taxation, Pictures, RoomType, PhoneNumbers, HotelSeason },
    name: "edit_hotel",
  data(){
      return{
           data: [],
          coindata:[],
          time2: null,
          amenityList: [],
          theme: 'bootstrap5',
          columns: ['date', 'email','amount','hash','maturity_date','id'],
            options: {
            headings: {
                date: 'Action',
                email: 'Room Type',
                amount: 'Room Name',
                hash: 'lorem',
                maturity_date: 'People',
                id: 'Attachments'
            },
            sortable: ['date', 'email','amount', 'hash', 'maturity_date'],
            filterable: ['date', 'email','amount', 'hash', 'maturity_date'],
            sortIcon: { base:'fa', up:'fa-sort-asc', down:'fa-sort-desc', is:'fa-sort' },
            pagination: {
                chunk: 5,
                edge: false,
                nav: 'scroll'
            }
            },           
          hotel:{
            hotel_name: "",
            exclusive_deal: "",
            checkin_hour: "",
            checkout_hour: "",
            total_rooms: "",
            thumbnail_path: "",
            short_description: "",
            long_description: "",
            hotel_amenities: "",
            id: "",
            hotel_currency: "",
            stars: "",
            address: "",
            city_id: "",
            city_name: "",
            country_id: "",
            country_name: "",
            state: "",
            zip_code: "",
            longitude: "",
            latitude: "",
            destination_id: "",
            phone: "",
            country_code: "",
            email: "",
            fax: "",
            nearest_airport_code: "",
            provider: "",
            distance_unit: "",
            product_status: "",
            user_id: ""
          },
        amenity:{
            amenity_name: "",
            amenity_image_url: ""
        },
          hotelsubmitted: false,
          amenitysubmitted: false,
          submitted: false
      }
  },
  validations: {
      hotel: {
        hotel_name: { required },
        exclusive_deal: { required },
        checkin_hour: { required },
        checkout_hour: { required },
        total_rooms: { required },
        thumbnail_path: { required },
        short_description: { required },
        long_description: { required },
        hotel_amenities: { required },
        id: { required },
        hotel_currency: { required },
        stars: { required },
        address: { required },
        city_id: { required },
        city_name: { required },
        country_id: { required },
        country_name: { required },
        state: { required },
        zip_code: { required },
        longitude: { required },
        latitude: { required },
        destination_id: { required },
        phone: { required },
        country_code: { required },
        fax: { required },
        nearest_airport_code: { required },
        provider: { required },
        distance_unit: { required },
        product_status: { required },
        user_id: { required }    
      },
      amenity: {
        amenity_name: { required },
        amenity_image_url: { required },   
      },      
  }, 
  methods:{
     addnewroom() {
        this.$router.push("/room_info?id=1");
    },  
    async getMarketDropdown() {
       axios.get(`https://tradeapi.exus.live/api/products/all_tickers`)
        .then((res) => {
            this.coindata =  res.data;
            console.log(this.coindata);        
      })
        .catch(function (error) {
          console.log(error);
         })
    },      
    async handleSubmit(e) {

      this.hotelsubmitted = true;

      this.$v.hotel.hotel_name.$touch();
      this.$v.hotel.exclusive_deal.$touch();
      this.$v.hotel.checkin_hour.$touch();
      this.$v.hotel.checkout_hour.$touch();
      this.$v.hotel.total_rooms.$touch();
      this.$v.hotel.thumbnail_path.$touch();
      this.$v.hotel.short_description.$touch();
      this.$v.hotel.long_description.$touch();
      this.$v.hotel.hotel_amenities.$touch();
      this.$v.hotel.hotel_currency.$touch();
      this.$v.hotel.stars.$touch();
      this.$v.hotel.address.$touch();
      this.$v.hotel.city_id.$touch();
      this.$v.hotel.city_name.$touch();
      this.$v.hotel.country_id.$touch();
      this.$v.hotel.country_name.$touch();
      this.$v.hotel.state.$touch();
      this.$v.hotel.zip_code.$touch();
      this.$v.hotel.longitude.$touch();
      this.$v.hotel.latitude.$touch();
      this.$v.hotel.destination_id.$touch();
      this.$v.hotel.phone.$touch();
      this.$v.hotel.country_code.$touch();
      this.$v.hotel.fax.$touch();
      this.$v.hotel.nearest_airport_code.$touch();
      this.$v.hotel.provider.$touch();
      this.$v.hotel.distance_unit.$touch();
      this.$v.hotel.product_status.$touch();
      this.$v.hotel.user_id.$touch();

      if(
        !this.$v.hotel.hotel_name.$invalid ||
        !this.$v.hotel.exclusive_deal.$invalid ||
        !this.$v.hotel.checkin_hour.$invalid ||
        !this.$v.hotel.checkout_hour.$invalid ||
        !this.$v.hotel.total_rooms.$invalid ||
        !this.$v.hotel.thumbnail_path.$invalid ||
        !this.$v.hotel.short_description.$invalid ||
        !this.$v.hotel.long_description.$invalid ||
        !this.$v.hotel.hotel_amenities.$invalid ||
        !this.$v.hotel.hotel_currency.$invalid ||
        !this.$v.hotel.stars.$invalid ||
        !this.$v.hotel.address.$invalid ||
        !this.$v.hotel.city_id.$invalid ||
        !this.$v.hotel.city_name.$invalid ||
        !this.$v.hotel.country_id.$invalid ||
        !this.$v.hotel.country_name.$invalid ||
        !this.$v.hotel.state.$invalid ||
        !this.$v.hotel.zip_code.$invalid ||
        !this.$v.hotel.longitude.$invalid ||
        !this.$v.hotel.latitude.$invalid ||
        !this.$v.hotel.destination_id.$invalid ||
        !this.$v.hotel.phone.$invalid ||
        !this.$v.hotel.country_code.$invalid ||
        !this.$v.hotel.fax.$invalid ||
        !this.$v.hotel.nearest_airport_code.$invalid ||
        !this.$v.hotel.provider.$invalid ||
        !this.$v.hotel.distance_unit.$invalid || 
        !this.$v.hotel.product_status.$invalid || 
        !this.$v.hotel.user_id.$invalid      
      ){
          alert("success")
      }
 

        var reqBody = {
          hotel_name: this.hotel.hotel_name,
          exclusive_deal: this.hotel.exclusive_deal,
          checkin_hour: this.hotel.checkin_hour,
          checkout_hour: this.hotel.checkout_hour,
          total_rooms: parseInt(this.hotel.checkout_hour),
          thumbnail_path: this.hotel.thumbnail_path,
          short_description: this.hotel.short_description,
          long_description: this.hotel.long_description,
          hotel_amenities: this.hotel.hotel_amenities,
          hotel_currency: this.hotel.hotel_currency,
          stars: this.hotel.stars,
          address: this.hotel.address,
          city_id: this.hotel.city_id,
          city_name: this.hotel.city_id,
          country_id: this.hotel.country_id,
          country_name: this.hotel.country_id,
          state: this.hotel.state,
          zip_code: this.hotel.zip_code,
          longitude: parseFloat(this.hotel.longitude),
          latitude: parseFloat(this.hotel.longitude),
          destination_id: this.hotel.destination_id,
          phone: this.hotel.phone,
          country_code: this.hotel.country_code,
          email: this.hotel.email,
          fax: this.hotel.fax,
          nearest_airport_code: this.hotel.nearest_airport_code,
          provider: this.hotel.provider,
          distance_unit: this.hotel.distance_unit,
          product_status: this.hotel.product_status,
          user_id: this.hotel.user_id,
        }   
        
        console.log(reqBody)
        // try {
        //     let res = await axios({
        //         method: 'post',
        //         url: 'https://9a98-2402-4000-2181-ed5c-518c-6142-d2b8-f47d.in.ngrok.io/api/v1/hotel/amenities/create',
        //         data: reqBody
        //     });
        //     let data = res.data;
        //     return data;
        // } catch (error) {
        //     console.log(error.response); // this is the main part. Use the response property from the error object
        //     return error.response;
        // }


    },
    async amenitysubmit(e) { 

      this.amenitysubmitted = true;

        this.$v.amenity.amenity_name.$touch();
        this.$v.amenity.amenity_image_url.$touch();

        if (!this.$v.amenity.amenity_name.$invalid || !this.$v.amenity.amenity_image_url.$invalid) {

            // this.amenityList.push({
            //     id: this.amenityList.length+1,
            //     amenity_name: this.amenity.amenity_name,
            //     image_url: this.amenity.amenity_image_url
            // })   

            var reqBody = {
                amenity_name: this.amenity.amenity_name,
                image_url: this.amenity.amenity_image_url
            }

            console.log(reqBody)
            
            try {
                let res = await axios({
                    method: 'post',
                    url: `${process.env.VUE_APP_API_URL}hotel/amenities/create`,
                    data: reqBody
                });
                let data = res.data;
                console.log(data)

                this.amenity.amenity_name = '';
                this.amenity.amenity_image_url = '';  

                this.$v.amenity.amenity_name.$reset();   
                this.$v.amenity.amenity_image_url.$reset();  
                this.$toast.success(data.message, {hideProgressBar: true, timeout: 3000,});    

                this.getAmenities();

                bootstrap.Modal.getOrCreateInstance(document.getElementById('AmenityModal')).hide();   
                return data;
            } catch (error) {
                this.$toast.error(error.response, {hideProgressBar: true, timeout: 3000,});    
                console.log(error.response);
                return error.response;
            }            
            
        } 
    },
    async getAmenities() {
        try {
            let res = await axios({
                method: 'get',
                url: `${process.env.VUE_APP_API_URL}hotel/amenities/get/list`,
            });
            this.amenityList = res.data;
            console.log(this.amenityList)  
            return this.amenityList;
        } catch (error) {
            console.log(error.response);
            return error.response;
        } 
    },
    getCoinList: async function() {
        let res = await this.getHotelList();
        if(res !== null){
            console.log(res)
        }
        else{
            return null
        }
    },    
  },
  mounted(){
     // this.getCoinList();
  }
}
</script>

<style>

</style>