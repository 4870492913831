<template>
    <default-container>
        <nav class="page-breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="#">Hotel</a></li>
                <li class="breadcrumb-item active" aria-current="page">View Hotel List</li>
            </ol>
        </nav>   
        <h4 class="page-title">Hotel List</h4>

            <div class="row">
                <div class="col-lg-12">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-lg-12">
                                    <!-- <i v-for="n in this.star" :key="n" class="ri-star-fill"></i> -->
                                    <v-client-table :columns="columns" :data="data" :options="options" ref="infoPagesTable" :theme="theme" id="dataTable" class="main-data-table collapse-table">
                                        <div slot-scope="props" slot="hotel_name">                                         
                                            <div class="logo-hotel"><i class="ri-hotel-line"></i></div>
                                            <span class="h-name">{{ props.row.hotel_name }}</span>
                                        </div>
                                        <div slot-scope="props" slot="star">

                                            <span class="star-mark" v-if="props.row.star == 1">
                                                <i class="ri-star-fill"></i>
                                            </span>
                                            <span  class="star-mark" v-if="props.row.star == 2">
                                                <i class="ri-star-fill"></i>
                                                <i class="ri-star-fill"></i>
                                            </span> 
                                            <span class="star-mark"  v-if="props.row.star == 3">
                                                <i class="ri-star-fill"></i>
                                                <i class="ri-star-fill"></i>
                                                <i class="ri-star-fill"></i>
                                            </span>
                                            <span class="star-mark" v-if="props.row.star == 4">
                                                <i class="ri-star-fill"></i>
                                                <i class="ri-star-fill"></i>
                                                <i class="ri-star-fill"></i>
                                                <i class="ri-star-fill"></i>
                                            </span>   
                                            <span class="star-mark" v-if="props.row.star == 5">
                                                <i class="ri-star-fill"></i>
                                                <i class="ri-star-fill"></i>
                                                <i class="ri-star-fill"></i>
                                                <i class="ri-star-fill"></i>
                                                <i class="ri-star-fill"></i>
                                            </span>                                                                                      
                                        </div>
                                        <div slot-scope="props" slot="id">                                         
                                            <button @click="goedithotel(props.row.id)"  class="btn btn-primary" type="button"><i class="fa-solid fa-pencil"></i></button>
                                            <button @click="deleteHotel(props.row.id)" class="btn btn-danger" type="button"><i class="fa-solid fa-trash-can"></i></button>
                                            <button @click="editInfoPage(props.row.id)" class="btn btn-info" type="button"><i class="ri-eye-fill"></i></button>
                                        </div>
                                        <template v-slot:child_row="props">
                                            <div class="list-br"><b>Number of Rooms:</b> {{props.row.total_rooms}}</div>
                                        </template>                                        
                                    </v-client-table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </default-container>
</template>

<script>
import Vue from 'vue'
import axios from 'axios';
import Header from "../../mixins/Header";
import {ClientTable, Event} from 'vue-tables-2'
import DefaultContainer from '../../containers/DefaultContainer.vue'
import mixinHotel from "@/mixins/Hotel/hotel";

Vue.use(ClientTable)
export default {
    mixins: [
        mixinHotel
    ],    
  components: { 
      DefaultContainer,
      ClientTable,
      Event,      
      },
  name: "hotel_list",
  data(){
      return{
        Expanded: "",
        classtd: [],
        ismobile: false,
        windowWidth: window.innerWidth,
        data: [],
        hotel_list:[],
        theme: 'bootstrap5',
        hotelsubmitted: false,
        submitted: false,
        columns: ['hotel_name', 'email','country','total_rooms','star','id'],
        options: {
            perPage: 8,
            columnsDisplay: {
                hotel_name: 'desktopL',
                email: 'not_mobile',
                country: 'not_mobile',
                total_rooms: 'not_mobile',
                star: 'not_mobile',
                id: 'desktopL',
            },
            columnsClasses:{
                hotel_name: 'test'
            },            
            headings: {
                hotel_name: 'Hotel Name',
                email: 'Email Address',
                country: 'Country',
                total_rooms: 'Number of Rooms',
                star: 'Stars',
                id: 'Action'
            },
            sortable: ['hotel_name', 'email','country','total_rooms',],
            filterable: ['hotel_name', 'email','country','total_rooms',],
            sortIcon: { base:'fa', up:'fa-sort-asc', down:'fa-sort-desc', is:'fa-sort' },
            pagination: {
                chunk: 5,
                edge: false,
                nav: 'scroll'
            }
        },            
      }
  },
  methods:{

    async editInfoPage(id) {
        this.$refs.infoPagesTable.toggleChildRow(id);
    },
   async getHotelList  () {
       this.data = []
        try {
          let response = await this.axios         
            .get(`${process.env.VUE_APP_API_URL}hotel/get/list`, Header.APP_ADMIN_HEADER)
            .then((response) => {
                this.hotel_list = response.data  
                console.log(this.hotel_list)

              for (let a = 0; a < this.hotel_list.length; a++){
                this.data.push({
                  hotel_name :this.hotel_list[a].hotel_name,
                  email:this.hotel_list[a].email,
                  country:this.hotel_list[a].country_name,
                  total_rooms:this.hotel_list[a].total_rooms,
                  star:this.hotel_list[a].stars,
                  id:this.hotel_list[a].id,
                })               
              }

              console.log(this.data)

            })
        } catch (error) {
          console.log(error)
        }       
   },
   async goedithotel(id) {
        this.$router.push("/edit_hotel?hotel_id="+id)
   },
   async deleteHotel(id) { 
    
    this.$swal({
        title: 'Are you sure?',
        text: "Do you want to delete this Hotel?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#0d6efd',
        cancelButtonColor: '#d33',
        confirmButtonText: 'delete it!'
    }).then((result) => {
        if (result.isConfirmed) {
            axios.delete(`${process.env.VUE_APP_API_URL}hotel/delete?hotel_id=${id}`, Header.APP_ADMIN_HEADER)
            .then(response => {
                console.log(response);
                this.$toast.success(response.data.message, {hideProgressBar: true, timeout: 3000,});    
                this.getHotelList(); 
            })
            .catch(function (error) {
                console.log(error)
                this.$toast.error(error.message, {hideProgressBar: true, timeout: 3000,});  
            })  
        }
    })    
    

   },
    async   test(props, table){
      props.expanded = (this.Expanded !== table) ? true : !props.expanded ;
      this.Expanded = table;
    },   
  },
  created(){
      this.getHotelList();
  }
}
</script>

<style>
.VueTables__child-row-toggler {
    width: 16px;
    height: 16px;
    line-height: 16px;
    display: block;
    margin: auto;
    text-align: center;
    background-color: #ddebff;
    font-size: 15px;
    color: #000;
    cursor: pointer;
}

.VueTables__child-row-toggler--closed::before {
    content: "+";
}

.VueTables__child-row-toggler--open::before {
    content: "-";
}
</style>