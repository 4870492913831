
export default {
    methods: {
      getHotelList: async function() {
        try {
          let response = await this.axios.get(`https://tradeapi.exus.live/api/products/all_tickers`);
          return response.data[0];
        } catch (error) {
          throw error;
        }
      },  
      createHotel: async function(obj) {
        try {
          let response = await this.axios.post(
            "http://35.158.50.253:3000/api/v1/hotel/create/general/info",
            obj
          );
          return response;
        } catch (error) {
          throw error;
        }
      },          
    }
}   