<template>
    <form @submit.prevent="roomsubmit" ref="roomform">
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-header">
                        Room General
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-lg-3">
                                <div class="form-group mb-3">
                                    <label for="room_type" class="form-label">Room Type</label>
                                    <select class="form-control form-select" v-model="room.room_type_id" id="exampleFormControlSelect1" placeholder="Select Room Type"
                                    :class="{ 'is-invalid': roomsubmitted && $v.room.room_type_id.$error }">
                                    <option selected value="">Select Room Type</option>
                                        <option v-for="list in hotel_room_type" :key="list.id">{{ list.name }}</option>
                                    </select>
                                    <div v-if="roomsubmitted && !$v.room.room_type_id.required" class="invalid-feedback">Room Type required</div>
                                </div>   
                            </div>
                            <div class="col-lg-3">
                                <div class="form-group mb-3">
                                    <label for="room_name" class="form-label">Room Name</label>
                                <input type="text" v-model="room.room_name" class="form-control" id="room_name" name="room_name" placeholder="Room  Name" 
                                    :class="{ 'is-invalid': roomsubmitted && $v.room.room_name.$error }">
                                    <div v-if="roomsubmitted && !$v.room.room_name.required" class="invalid-feedback">Room Name required</div>
                                </div>   
                            </div>
                            <div class="col-lg-3">
                                <div class="form-group mb-3">
                                    <label for="room_name" class="form-label">Allotments</label>
                                    <input type="number" v-model="room.room_allotments" class="form-control" id="room_name" name="room_name" placeholder="NUmber of room Allotments" 
                                    :class="{ 'is-invalid': roomsubmitted && $v.room.room_allotments.$error }">
                                    <div v-if="roomsubmitted && !$v.room.room_allotments.required" class="invalid-feedback">Allotments required</div>
                                </div>   
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-9">
                                <div class="form-group mb-3">
                                    <label for="short_des" class="form-label">Room Short Description</label>
                                <input type="text" v-model="room.short_des" class="form-control" id="total_rooms" name="short_des" placeholder="Room Short Description" 
                                    :class="{ 'is-invalid': roomsubmitted && $v.room.short_des.$error }">
                                    <div v-if="roomsubmitted && !$v.room.short_des.required" class="invalid-feedback">Room Name required</div>
                                </div>   
                            </div>                                                                        
                        </div>
                        <div class="row">
                            <div class="col-lg-9">
                                <div class="form-group mb-3">
                                    <label for="long_des" class="form-label">Room Long Description</label>
                                    <textarea rows="4" v-model="room.long_des" class="form-control" id="total_rooms" name="long_des" placeholder="Room Long Description"
                                    :class="{ 'is-invalid': roomsubmitted && $v.room.long_des.$error }"
                                    ></textarea>
                                    <div v-if="roomsubmitted && !$v.room.long_des.required" class="invalid-feedback">Room Name required</div>
                                </div>   
                            </div>                                      
                        </div>
                        <div class="row">
                            <div class="col-lg-3">
                                <div class="form-group mb-3">
                                    <label for="max_adults" class="form-label">Max Adults</label>
                                    <input type="number" min="1" v-model="room.max_adults" class="form-control" id="max_adults" name="max_adults" placeholder="Max Adults"
                                    :class="{ 'is-invalid': roomsubmitted && $v.room.max_adults.$error }"                                                 />
                                    <div v-if="roomsubmitted && !$v.room.max_adults.required" class="invalid-feedback">Max Adults required</div>
                                </div>   
                            </div>
                            <div class="col-lg-3">
                                <div class="form-group mb-3">
                                    <label for="max_child" class="form-label">Max Child</label>
                                    <input type="number" min="1" v-model="room.max_child" class="form-control" id="max_child" name="max_child" placeholder="Max Child" 
                                    :class="{ 'is-invalid': roomsubmitted && $v.room.max_child.$error }"                                                    />
                                    <div v-if="roomsubmitted && !$v.room.max_child.required" class="invalid-feedback">Max Child required</div>
                                </div>   
                            </div>
                            <div class="col-lg-3">
                                <div class="form-group mb-3">
                                    <label for="child_age" class="form-label">Child Age</label>
                                    <input type="number" min="1" v-model="room.child_age" class="form-control" id="child_age" name="max_chchild_ageild" placeholder="Child Age" 
                                    :class="{ 'is-invalid': roomsubmitted && $v.room.child_age.$error }"                                                   />
                                    <div v-if="roomsubmitted && !$v.room.child_age.required" class="invalid-feedback">Child Age</div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-9 mb-4">
                                 <label for="max_adults" class="form-label">Facility Type</label>
                                <Multiselect
                                    v-model="this.facility"
                                    mode="tags"
                                    placeholder="Select Facility"
                                    track-by="name"
                                    label="name"
                                    :close-on-select="false"
                                    :search="true"     
                                    :options="this.facility_data"
                                    @change="getdata_facility()"
                                    >
                                    <template v-slot:tag="{ option, handleTagRemove, disabled }">
                                        <div class="multiselect-tag is-user">
                                        <img :src="option.image">
                                        {{ option.name }}
                                        <span
                                            v-if="!disabled"
                                            class="multiselect-tag-remove"
                                            @mousedown.prevent="handleTagRemove(option, $event)"
                                        >
                                            <span class="multiselect-tag-remove-icon"></span>
                                        </span>
                                        </div>
                                    </template>
                                    <template v-slot:option="{ option }">
                                        <img class="user-image" :src="option.image"> {{ option.name }}
                                    </template>
                                    </Multiselect>                                
                            </div>
                        </div>                         
                    </div>
                </div>                                
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12 text-right mb-4 footer-btn-action-area">
                <button class="btn btn-primary float-end footer-btn-action no-margin">Save</button>
                <button type="button" class="btn btn-secondary float-end footer-btn-action">Cancel</button>
            </div>    
        </div>
    </form>  
</template>

<script>
import axios from 'axios';
import { required } from "vuelidate/lib/validators";
import Multiselect from '@vueform/multiselect/dist/multiselect.vue2.js'
import Header from "../../mixins/Header";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import mixinHotel from "@/mixins/Hotel/hotel";
export default {
    mixins: [
        mixinHotel
    ],    
  name: "room_info",
  components: {
      Multiselect
  },
  data(){
      return{
        data: [],
        facility: [],
        hotel_room_type: [],
        hotel_id: this.$route.query.hotel_id,
        room:{
            room_type_id: "",
            room_name: "",
            room_allotments: "",
            short_des: "",
            long_des: "",
            max_adults: null,
            max_child: null,
            child_age: null,
            columns: ['date', 'email','amount','hash','maturity_date','id'],
            options: {
                headings: {
                    date: 'Hotel Name',
                    email: 'phone',
                    amount: 'Country',
                    hash: 'Provider',
                    maturity_date: 'Status',
                    id: 'Action'
                },
                sortable: ['date', 'email','amount', 'hash'],
                filterable: ['date', 'email','amount', 'hash', 'maturity_date'],
                sortIcon: { base:'fa', up:'fa-sort-asc', down:'fa-sort-desc', is:'fa-sort' },
                pagination: {
                    chunk: 5,
                    edge: false,
                    nav: 'scroll'
                }
            },                        
        },
        roomsubmitted: false,
        submitted: false,
        facility_data: []
      }
  },
  validations: {
      room: { 
        room_type_id: { required },
        room_name: { required },  
        room_allotments: { required },  
        short_des: { required },
        long_des: { required },  
        max_adults: { required },  
        max_child: { required },  
        child_age: { required },  
      },     
  }, 
  methods:{
      
    async getFaciity() {

        this.facility_data = []

        try {
          let response = await this.axios
            .get(`${process.env.VUE_APP_API_URL}common/facility/get/list`, Header.APP_ADMIN_HEADER)
            .then((response) => {
                console.log(response.data)
              for (let a = 0; a < response.data.length; a++){
                this.facility_data.push({
                  value :response.data[a].id,
                  name:response.data[a].facility_name,
                  image: response.data[a].image_url,
                })               
              }
            })
        } catch (error) {
          console.log(error)          
        }

    },      

    async roomsubmit(e) {

      this.roomsubmitted = true;

      this.$v.room.room_type_id.$touch();
      this.$v.room.room_name.$touch();
      this.$v.room.room_allotments.$touch();
      this.$v.room.short_des.$touch();
      this.$v.room.long_des.$touch();
      this.$v.room.max_adults.$touch();
      this.$v.room.max_child.$touch();
      this.$v.room.child_age.$touch();   
         

      if(
        this.$v.room.room_type_id.$error || 
        this.$v.room.room_name.$error ||
        this.$v.room.room_allotments.$error ||
        this.$v.room.short_des.$error ||
        this.$v.room.long_des.$error ||
        this.$v.room.max_adults.$error ||
        this.$v.room.max_child.$error ||
        this.$v.room.child_age.$error    
      ){
      }
      else{
        var reqBody = {
          room_type_id: this.room.room_type_id,
          room_name: this.room.room_name,
          room_allotments: this.room.room_allotments,
          short_des: this.room.short_des,
          long_des: this.room.long_des,
          max_adults: parseInt(this.room.max_adults),
          max_child: parseInt(this.room.max_child),
          child_age: parseInt(this.room.child_age),
          tier_plans: ["id1","id2"],
          room_facilities: this.facility
        }  

        console.log(reqBody)

          try{
              let response = await this.axios
              .post(`${process.env.VUE_APP_API_URL}room/create?hotel_id=${this.hotel_id}`, reqBody, Header.APP_ADMIN_HEADER)
              .then((res) => {
                console.log(res);
                this.$toast.success(res.data.message, {hideProgressBar: true, timeout: 3000,});    
                this.room.room_type_id = '';
                this.room.room_name = '';  
                this.room.room_allotments = '';
                this.room.short_des = '';
                this.room.long_des = '';
                this.room.max_adults = '';
                this.room.max_child = '';
                this.room.child_age = '';

                this.$v.room.room_type_id.$reset();  
                this.$v.room.room_name.$reset();  
                this.$v.room.room_allotments.$reset();  
                this.$v.room.short_des.$reset();  
                this.$v.room.long_des.$reset();  
                this.$v.room.max_adults.$reset();  
                this.$v.room.max_child.$reset();  
                this.$v.room.child_age.$reset();   
                
                this.$router.push("/hotel_list");
                  
              })                    
          }catch(error) {
              console.log(error)
              this.$toast.error(error.message, {hideProgressBar: true, timeout: 3000,});                                   
          }          
      }
    }, 
    async getRoomType(){

        this.hotel_room_type = []

        try {
          let response = await this.axios
            .get(`${process.env.VUE_APP_API_URL}room/type/get/list?hotel_id=${this.hotel_id}`, Header.APP_ADMIN_HEADER)
            .then((response) => {
                console.log(response.data)
              for (let a = 0; a < response.data.length; a++){
                    this.hotel_room_type.push({
                        name :response.data[a].name,
                        code:response.data[a].code,
                        symbol: response.data[a].symbol
                    })               
              }
            })
        } catch (error) {
          console.log(error)          
        }
    }      
  },
  mounted(){
      this.getRoomType();
      this.getFaciity();
  }
}
</script>

<style>

</style>