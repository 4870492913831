<template>
    <default-container>
        <nav class="page-breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="#">Main </a></li>
                <li class="breadcrumb-item active" aria-current="page">Manage Amentices</li>
            </ol>
        </nav>   
        <h4 class="page-title">Manage Amentices</h4>
        <div class="row">
            <div class="col-md-12">
                <amentices />
            </div>
        </div>         
    </default-container>
</template>

<script>
import Amentices from '../../components/Amentices/Amentices.vue'
import DefaultContainer from '../../containers/DefaultContainer.vue'

export default {  
  components: { DefaultContainer, Amentices },
    name: "",
  data(){
      return{
      }
  },
  methods:{   
  },
  mounted(){

  }
}
</script>

<style>

</style>