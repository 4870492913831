<template>
  <router-view></router-view>
</template>

<script>
import { Auth } from "aws-amplify";

export default {
  name: 'App',
  components: {
  },
  data() {
    return{
      musername: "",
      mpassword: "",
      spinner: false,
      loginsuccess: false
    }
  },
  methods:{
    async login() {
      this.spinner = true
      try {
        await Auth.signIn(this.musername, this.mpassword)
        .then(
          (data) => {
            console.log(data);
            localStorage.setItem("access-token", data.signInUserSession.accessToken.jwtToken)
            this.spinner = false
            alert("Login Successfully")
            this.musername = ""
            this.mpassword = ""    
            this.loginsuccess = true        
          });
         
      } catch (error) { 
         console.log(error)
         alert(error)
         this.spinner = false
         this.musername = ""
         this.mpassword = ""
      }
    },
    async logout() {
        try {
            await Auth.signOut();
            this.loginsuccess = false
            localStorage.removeItem("access-token")
        } catch (error) {
            console.log('error signing out: ', error);
        }
    }    
  }
}
</script>

<style>
    @import "scss/_main.scss";
</style>
