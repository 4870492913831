<template>
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-lg-12">
                            <button type="button" class="btn btn-primary pull-right btn-lg" data-bs-toggle="modal" data-bs-target="#AddNewExtraModal">Add New extra</button>
                            <v-client-table ref="infoPagesTable" :columns="columns" :data="data" :options="options" id="dataTable" class="main-data-table">
                                <div slot-scope="props" slot="id">
                                    <button @click="editextra(props.row.id, props.row.extra, props.row.price, props.row.remarks, props.row.room_id)" class="btn btn-primary" type="button"><i class="fa-solid fa-pencil"></i></button>
                                    <button @click="deleteextra(props.row.id)" class="btn btn-danger" type="button"><i class="fa-solid fa-trash-can"></i></button>
                                    <button @click="editInfoPage(props.row.id)" class="btn btn-info" type="button"><i class="ri-eye-fill"></i></button>

                                </div>    
                                <template v-slot:child_row="props">
                                    <div class="list-br"><b>Price:</b> {{props.row.price}}</div>
                                    <div class="list-br"><b>Remarks:</b> {{props.row.remarks}}</div>
                                    <div class="list-br"><b>Room ID:</b> {{props.row.room_id}}</div>
                                </template>                                                             
                            </v-client-table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Add New Extra modal -->
        <div class="modal fade" id="AddNewExtraModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <form @submit.prevent="extrasubmit" ref="extraform">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Add New Room Extra</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="form-group mb-3">
                                        <label for="extra" class="form-label">Room Extra Name</label>
                                        <input type="text" v-model="extra.extra" id="extra" name="extra" class="form-control" placeholder="Room Extra Name" 
                                        :class="{ 'is-invalid': extrasubmitted && $v.extra.extra.$error }">
                                        <div v-if="extrasubmitted && !$v.extra.extra.required" class="invalid-feedback">Extra Name required</div>
                                    </div>                                    
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-group mb-3">
                                        <label for="price" class="form-label">Price</label>
                                        <input type="text" v-model="extra.price" id="price" name="price" class="form-control" placeholder="Price" 
                                        :class="{ 'is-invalid': extrasubmitted && $v.extra.price.$error }">
                                        <div v-if="extrasubmitted && !$v.extra.price.required" class="invalid-feedback">Price required</div>
                                    </div>                                      
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="form-group mb-3">
                                        <label for="remarks" class="form-label">Remarks</label>
                                        <input type="text" v-model="extra.remarks" id="remarks" name="remarks" class="form-control" placeholder="remarks" 
                                        :class="{ 'is-invalid': extrasubmitted && $v.extra.remarks.$error }">
                                        <div v-if="extrasubmitted && !$v.extra.remarks.required" class="invalid-feedback">Remarks required</div>
                                    </div>                                      
                                </div>
                            </div>                                                      
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="submit" class="btn btn-primary">Save changes</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>

        <!-- Update Extra modal -->
        <div class="modal fade" id="UpdateExtraModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <form @submit.prevent="updateextrasubmit" ref="updateextraform">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Update Room Extra</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="form-group mb-3">
                                        <label for="extra" class="form-label">Room Extra Name</label>
                                        <input type="text" v-model="extra.update_extra" id="extra" name="extra" class="form-control" placeholder="Room Extra Name" 
                                        :class="{ 'is-invalid': updateextrasubmitted && $v.extra.update_extra.$error }">
                                        <div v-if="updateextrasubmitted && !$v.extra.update_extra.required" class="invalid-feedback">Extra Name required</div>
                                    </div>                                    
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-group mb-3">
                                        <label for="price" class="form-label">Price</label>
                                        <input type="text" v-model="extra.update_price" id="price" name="price" class="form-control" placeholder="Price" 
                                        :class="{ 'is-invalid': updateextrasubmitted && $v.extra.update_price.$error }">
                                        <div v-if="updateextrasubmitted && !$v.extra.update_price.required" class="invalid-feedback">Price required</div>
                                    </div>                                      
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="form-group mb-3">
                                        <label for="remarks" class="form-label">Remarks</label>
                                        <input type="text" v-model="extra.update_remarks" id="remarks" name="remarks" class="form-control" placeholder="remarks" 
                                        :class="{ 'is-invalid': updateextrasubmitted && $v.extra.update_remarks.$error }">
                                        <div v-if="updateextrasubmitted && !$v.extra.update_remarks.required" class="invalid-feedback">Remarks required</div>
                                    </div>                                      
                                </div>
                            </div>                                                      
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="submit" class="btn btn-primary">Save changes</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>        
    </div>
</template>
<script>
import axios from 'axios';
import Header from "../../../mixins/Header";
import { required } from "vuelidate/lib/validators";
import {ClientTable, Event} from 'vue-tables-2'
export default {  
  components: { ClientTable, Event  },
  name: "room_info",
  data(){
      return{
        data: [],
        hotel_id: this.$route.query.hotel_id,
        room_id: this.$route.query.room_id,
        columns: ['extra', 'price','remarks','room_id','id'],
        options: {
            columnsDisplay: {
                extra: 'desktopL',
                price: 'not_mobile',
                remarks: 'not_mobile',
                room_id: 'not_mobile',
                id: 'desktopL',
            },             
            headings: {
                extra: 'Room Extra Name',
                price: 'Price',
                remarks: 'Remarks',
                room_id: 'Room ID',                
                id: 'Action'
            },
            sortable: [],
            filterable: [],
            sortIcon: { base:'fa', up:'fa-sort-asc', down:'fa-sort-desc', is:'fa-sort' },
            pagination: {
                chunk: 5,
                edge: false,
                nav: 'scroll'
            }
        },         
        extra:{
            extra: "",
            price: "",      
            remarks: "",
            update_extra: "",
            update_price: "",      
            update_remarks: ""             
        },      
        extrasubmitted: false,
        submitted: false,
        updateextrasubmitted: false
      }
  },
  validations: {
      extra: {
        extra: { required },
        price: { required },   
        remarks: { required },
        update_extra: { required },
        update_price: { required },   
        update_remarks: { required }        
      },      
  }, 
  methods:{    
    async editInfoPage(id) {
        this.$refs.infoPagesTable.toggleChildRow(id);
    },       
    async extrasubmit(e) { 

      this.extrasubmitted = true;

        this.$v.extra.extra.$touch();
        this.$v.extra.price.$touch();
        this.$v.extra.remarks.$touch();

        if (!this.$v.extra.extra.$invalid || !this.$v.extra.price.$invalid || !this.$v.extra.remarks.$invalid) {  

            var reqBody = {
                extra: this.extra.extra,
                price: this.extra.price,
                remarks: this.extra.remarks
            }

            console.log(reqBody)    
            
          try{
              let response = await this.axios
              .post(`${process.env.VUE_APP_API_URL}room/extra/create?room_id=${this.room_id}`, reqBody, Header.APP_ADMIN_HEADER)
              .then((res) => {
                console.log(res);   
                this.extra.extra = '';
                this.extra.price = '';
                this.extra.remarks = '';                  
                this.$v.extra.extra.$reset();   
                this.$v.extra.price.$reset();
                this.$v.extra.remarks.$reset();
                this.getextralist();
                this.$toast.success(res.data.message, {hideProgressBar: true, timeout: 3000,});      
                bootstrap.Modal.getOrCreateInstance(document.getElementById('AddNewExtraModal')).hide();  
              })                    
          }catch(error){    
              console.log(error)   
              this.$toast.error(error.message, {hideProgressBar: true, timeout: 3000,});                                   
          }             
            
        } 
    },            
    async getextralist() {

        this.data = []

        try {
          let response = await this.axios
            .get(`${process.env.VUE_APP_API_URL}room/extra/get/list?room_id=${this.room_id}`, Header.APP_ADMIN_HEADER)
            .then((response) => {
                console.log(response.data)
              for (let a = 0; a < response.data.length; a++){
                this.data.push({
                  extra :response.data[a].extra,
                  price:response.data[a].price,
                  remarks:response.data[a].remarks,
                  room_id:response.data[a].room_id,                  
                  id:response.data[a].id,
                })               
              }
            })
        } catch (error) {
          console.log(error)          
        }
    },   
    async editextra(id, extra, price, remarks, room_id) {

        this.editextraid = id
       
        this.extra.update_extra = extra
        this.extra.update_price = price
        this.extra.update_remarks = remarks
        this.extra.room_id = room_id          

        bootstrap.Modal.getOrCreateInstance(document.getElementById('UpdateExtraModal')).show();
    },
    async updateextrasubmit(e) { 

      this.updateextrasubmitted = true;

        this.$v.extra.update_extra.$touch();
        this.$v.extra.update_price.$touch();
        this.$v.extra.update_remarks.$touch();        

        if (!this.$v.extra.update_extra.$invalid || !this.$v.extra.update_price.$invalid || !this.$v.extra.update_remarks.$invalid) {  

            var reqBody = {
                extra: this.extra.update_extra,
                price: this.extra.update_price,
                remarks: this.extra.update_remarks,                
            }

            console.log(reqBody)            
            
            try{
                let response = await this.axios
                .put(`${process.env.VUE_APP_API_URL}room/extra/update?room_extra_id=${this.editextraid}&room_id=${this.extra.room_id}`, reqBody, Header.APP_ADMIN_HEADER)
                .then((res) => {
                    let data = res.data;
                    console.log(data)
                    this.extra.update_extra = '';
                    this.extra.update_price = '';
                    this.extra.update_remarks = '';                    
                    this.$v.extra.update_extra.$reset();
                    this.$v.extra.update_price.$reset();
                    this.$v.extra.update_remarks.$reset();                    
                    this.getextralist();
                    this.$toast.success(data.message, {hideProgressBar: true, timeout: 3000,});    
                    bootstrap.Modal.getOrCreateInstance(document.getElementById('UpdateExtraModal')).hide();    
                })                    
            }catch(error){    
                console.log(error)   
                this.$toast.error(error.message, {hideProgressBar: true, timeout: 3000,});
                this.extra.update_extra = '';
                this.extra.update_price = '';
                this.extra.update_remarks = '';                    
                this.$v.extra.update_extra.$reset();
                this.$v.extra.update_price.$reset();
                this.$v.extra.update_remarks.$reset();                                                 
            }            
            
        } 
    },
    async deleteextra(id) { 
        
        this.$swal({
            title: 'Are you sure?',
            text: "Do you want to delete this Room Extra?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#0d6efd',
            cancelButtonColor: '#d33',
            confirmButtonText: 'delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                axios.delete(`${process.env.VUE_APP_API_URL}room/extra/delete?room_extra_id=${id}`, Header.APP_ADMIN_HEADER)
                .then(response => {
                    console.log(response);
                    this.getextralist(); 
                    this.$toast.success(response.data.message, {hideProgressBar: true, timeout: 3000,});    
                })
                .catch(function (error) {
                    console.log(error)
                    this.$toast.error(error.message, {hideProgressBar: true, timeout: 3000,});  
                })  
            }
        })   
    },     
  },
  created(){
      this.getextralist();
  }
}
</script>

<style>

</style>