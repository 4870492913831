<template>
<div>
<div class="sidebar-bg" @click="sibebgclick"></div>
<nav class="sidebar">
  <div class="sidebar-header">
    <a href="#" class="sidebar-brand">
      <img src="img/logo.png" />
    </a>
    <div class="sidebar-toggler not-active" @click="toggleSidebar">
      <i v-bind:class="[showsidebar == true ? 'ri-menu-line' : 'ri-close-line']"></i>
    </div>
  </div>
  <div class="sidebar-body ps" @mouseover="showsidebarohover" @mouseleave="showsidebaroleave">
    <ul class="nav">
      <li class="nav-item">
       <a class="nav-link main-item" data-bs-toggle="collapse" href="#hotel" role="button" aria-expanded="false" aria-controls="hotel">
            <i class="ri-hotel-line"></i>
           <span class="link-title">Hotel</span>
        </a>
        <div class="collapse show" id="hotel">
          <ul class="nav sub-menu">
            <li class="nav-item">
              <router-link to="/add_new_hotel" class="nav-link">Add New Hotel</router-link>
            </li>
            <li class="nav-item">
              <router-link to="/hotel_list" class="nav-link" @click="rourerclick">Manage Hotels</router-link>
            </li>
          </ul>
        </div>        
      </li>
      <li class="nav-item">
       <a class="nav-link main-item" data-bs-toggle="collapse" href="#module" role="button" aria-expanded="false" aria-controls="module">
            <i class="ri-database-2-line"></i>
           <span class="link-title">Modules</span>
        </a>
        <div class="collapse show" id="module">
          <ul class="nav sub-menu">
            <li class="nav-item">
              <router-link to="/amenities" class="nav-link ">Hotel Amenities</router-link>
            </li>
            <li class="nav-item">
              <router-link to="/category" class="nav-link ">Hotel Categories</router-link>
            </li>
            <li class="nav-item">
              <router-link to="/facility" class="nav-link ">Hotel Facilities</router-link>
            </li>            
          </ul>
        </div>        
      </li>
      <li class="nav-item">
       <a class="nav-link main-item" data-bs-toggle="collapse" href="#booking" role="button" aria-expanded="false" aria-controls="booking">
            <i class="ri-book-mark-line"></i>
           <span class="link-title">Booking</span>
        </a>
        <div class="collapse show" id="booking">
          <ul class="nav sub-menu">
            <li class="nav-item">
              <a class="nav-link">Add New Booking</a>
            </li>              
            <li class="nav-item">
              <router-link to="/booking" class="nav-link ">Manage Booking</router-link>
            </li>           
          </ul>
        </div>        
      </li>
      <li class="nav-item">
       <a class="nav-link main-item" data-bs-toggle="collapse" href="#payment" role="button" aria-expanded="false" aria-controls="payment">
            <i class="ri-refund-2-fill"></i>
           <span class="link-title">Payment</span>
        </a>
        <div class="collapse show" id="payment">
          <ul class="nav sub-menu">            
            <li class="nav-item">
              <a class="nav-link">Payment History</a>
            </li>
            <li class="nav-item">
              <router-link to="/payment" class="nav-link ">Manage Payment</router-link>
            </li>             
          </ul>
        </div>        
      </li>
      <li class="nav-item">
       <a class="nav-link main-item" data-bs-toggle="collapse" href="#setting" role="button" aria-expanded="false" aria-controls="setting">
        <i class="ri-settings-2-line"></i>
           <span class="link-title">Setting</span>
        </a>
        <div class="collapse show" id="setting">
          <ul class="nav sub-menu">            
            <li class="nav-item">
              <a class="nav-link">Permission</a>
            </li>
            <li class="nav-item">
              <router-link to="/setting" class="nav-link ">Update Profile</router-link>
            </li>             
          </ul>
        </div>        
      </li> 
      <!-- <li class="nav-item">
       <a class="nav-link main-item collapsed" data-bs-toggle="collapse" href="#website" role="button" aria-expanded="false" aria-controls="website">
            <i class="ri-window-line"></i>
           <span class="link-title">Website</span>
        </a>
      </li>
      <li class="nav-item">
       <a class="nav-link main-item collapsed" data-bs-toggle="collapse" href="#crover" role="button" aria-expanded="false" aria-controls="crover">
            <i class="ri-map-pin-user-line"></i>
           <span class="link-title">Crover</span>
        </a>
        <div class="collapse" id="crover">
          <ul class="nav sub-menu">
          </ul>
        </div>        
      </li>
      <li class="nav-item">
       <a class="nav-link main-item collapsed" data-bs-toggle="collapse" href="#report" role="button" aria-expanded="false" aria-controls="report">
            <i class="ri-file-chart-line"></i>
           <span class="link-title">Report</span>
        </a>
        <div class="collapse" id="report">
        </div>        
      </li>        -->
    </ul>
  <div class="ps__rail-x" style="left: 0px; bottom: 0px;"><div class="ps__thumb-x" tabindex="0" style="left: 0px; width: 0px;"></div></div><div class="ps__rail-y" style="top: 0px; right: 0px;"><div class="ps__thumb-y" tabindex="0" style="top: 0px; height: 0px;"></div></div></div>
</nav>
</div>
</template>
<script>
export default {
  data(){
    return{
      showsidebar: true
    }
  },
  methods:{

    async sibebgclick() {
      document.querySelector("body").classList.remove("close-sidebar");
      document.querySelector("body").classList.remove("hovershowmenu");
      this.showsidebar = true

    },
    async toggleSidebar() {

      this.showsidebar = !this.showsidebar
      if(!this.showsidebar) {
        document.querySelector("body").classList.add("close-sidebar");
      }
      else{
        document.querySelector("body").classList.remove("close-sidebar");
      }

    },
    async showsidebarohover() {
      if(!this.showsidebar == true){
         document.querySelector("body").classList.add("hovershowmenu");
      }
    },
    async showsidebaroleave() {
      if(!this.showsidebar == true){
         document.querySelector("body").classList.remove("hovershowmenu");
      }
    }    
  }
}
</script>

<style lang="scss">
    @import "../../scss/Sidebar/Sidebar";
</style>