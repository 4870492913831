<template>
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-lg-12">
                            <button type="button" class="btn btn-primary pull-right btn-lg" data-bs-toggle="modal" data-bs-target="#AddNewRateModal">Add New Promotion</button>
                            <v-client-table :columns="columns" :data="data" :options="options" id="dataTable" class="main-data-table"></v-client-table>
                            <div slot="Action" slot-scope="props" @click="showmore(props.row.id)" class="icon-eye"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Add New Promotion modal -->
        <div class="modal fade" id="AddNewPormotionMOdal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <form @submit.prevent="ratesubmit" ref="rateform">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Add New Promotion</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="form-group mb-3">
                                        <label for="extra" class="form-label">Promotion Code</label>
                                        <input type="text" v-model="promotion.code" id="extra" name="plan" class="form-control" placeholder="Rate Plan" 
                                        :class="{ 'is-invalid': promotionsubmitted && $v.rate.plan.$error }">
                                        <div v-if="ratesubmitted && !$v.rate.plan.required" class="invalid-feedback">Rate Plan required</div>
                                    </div>                                    
                                </div>
                                <div class="col-lg-12">
                                    <div class="form-group mb-3">
                                        <label for="description" class="form-label">Description</label>
                                        <input type="text" v-model="rate.description" id="description" name="description" class="form-control" placeholder="description" 
                                        :class="{ 'is-invalid': ratesubmitted && $v.rate.description.$error }">
                                        <div v-if="ratesubmitted && !$v.rate.description.required" class="invalid-feedback">Description required</div>
                                    </div>                                      
                                </div>
                            </div>                                                    
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="submit" class="btn btn-primary">Save changes</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
import { required } from "vuelidate/lib/validators";
import {ClientTable, Event} from 'vue-tables-2'
export default {  
  components: { ClientTable, Event  },
  name: "room_info",
  data(){
      return{
        data: [],
        hotel_id: this.$route.query.hotel_id,
        room_id: this.$route.query.room_id,
        columns: ['promo_code', 'promo_name','promo_type','room_type','from_date','to_date','is_precentag','value','apply_for_extras'],
        options: {
            headings: {
                promo_code: 'code',
                promo_name: 'Name',
                promo_type: 'Type',
                room_type: 'Room typ',
                from_date: 'From date',
                to_date: 'To_date',
                is_precentag: 'is_precentag',
                value: 'value',
                apply_for_extras: 'apply_for_extras'
            },
            // sortable: ['date', 'email','amount', 'hash'],
            // filterable: ['date', 'email','amount', 'hash', 'maturity_date'],
            sortIcon: { base:'fa', up:'fa-sort-asc', down:'fa-sort-desc', is:'fa-sort' },
            pagination: {
                chunk: 5,
                edge: false,
                nav: 'scroll'
            }
        },         
        promotion:{
            promo_code: "",
            promo_name: "",
            promo_type: "",
            room_type: "",
            bed_type: "",
            meal_type: "",
        },      
        ratesubmitted: false,
        submitted: false
      }
  },
  validations: {
      rate: {
        plan: { required },
        description: { required },   
      },      
  }, 
  methods:{    
    async ratesubmit(e) { 

      this.ratesubmitted = true;

        this.$v.rate.plan.$touch();
        this.$v.rate.description.$touch();

        if (!this.$v.rate.plan.$invalid || !this.$v.rate.description.$invalid) {  

            var reqBody = {
                meal_plan: this.meal.meal_plan,
                description: this.meal.description,
            }

            console.log(reqBody)    
            
          try{
              let response = await this.axios
              .post(`${process.env.VUE_APP_API_URL}mealplan/create?hotel_id=${this.hotel_id}`, reqBody)
              .then((res) => {
                  console.log(res);   
                  this.$toast.success(res.data.message, {hideProgressBar: true, timeout: 3000,});      
              })                    
          }catch(error){    
              console.log(error)   
              this.$toast.error(error.message, {hideProgressBar: true, timeout: 3000,});                                   
          }             
            
        } 
    },            
    async getPromotionList() {
        try {
          let response = await this.axios
            .get(`${process.env.VUE_APP_API_URL}hotel/promotion/get/list?hotel_id=${this.hotel_id}`)
            .then((response) => {
                console.log(response.data)
              for (let a = 0; a < response.data.length; a++){
                this.data.push({
                  id:response.data[a].id,                    
                  promo_code :response.data[a].promo_code,
                  promo_name:response.data[a].promo_name,
                  promo_type:response.data[a].promo_type,
                  room_type:response.data[a].room_type,
                  bed_type:response.data[a].bed_type,
                  meal_type:response.data[a].meal_type,
                  from_date:response.data[a].from_date,
                  to_date:response.data[a].to_date,
                  is_precentag:response.data[a].is_precentag,
                  value:response.data[a].value,
                  remarks:response.data[a].remarks,
                  apply_for_extras:response.data[a].apply_for_extras,                    
                })               
              }
            })
        } catch (error) {
          console.log(error)          
        }
    },   
    submitForm () {
    }       
  },
  created(){
      this.getPromotionList();
  }
}
</script>

<style>

</style>