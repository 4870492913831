
import Vue from 'vue'
import Router from 'vue-router'


// const login = () => import('../views/login.vue')
import login from '../views/login.vue';
import dashboard from '../views/dashboard.vue';

//Signup
import Signup from '../views/Signup.vue';

//Hotel
import AddNewHotel from '../views/Hotel/AddNewHotel.vue';
import editHotel from '../views/Hotel/EditHotel.vue';
import addUser from '../views/Users/AddUser.vue';
import AddNewRoomInfo from '../views/Hotel/AddNewRoomInfo.vue';
import EditRoomInfo from '../views/Hotel/EditRoomInfo.vue';
import HotelList from '../views/Hotel/HotelList.vue';

//Amentices
import Amenities from '../views/Module/Amenities.vue';

//Payment
import Payment from '../views/Payment/Payment.vue';

//Booking
import Booking from '../views/Booking/BookingLIst.vue';

//Category
import Category from '../views/Module/Category.vue';

//Facility
import Facility from '../views/Module/Facility.vue';

//Setting
import Setting from '../views/Setting/Setting.vue';


import test from '../views/Hotel/test.vue';

Vue.use(Router)

function guardmyrouter(to, from, next) {

  document.querySelector("body").classList.remove("close-sidebar");
  document.querySelector("body").classList.remove("hovershowmenu");

  var isauthoticated = false;

  if(localStorage.getItem("token")!=null) {
      isauthoticated = true;
  }
  else{
      isauthoticated = false;
  }
  if(isauthoticated) {
      next();
  }
  else{
      next("/")
  }
  
}


export default new Router({

  mode: 'hash', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'open active',
  linkExactActiveClass: 'exact-active',
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    {
      path: '/',
      name: 'login',
      component: login
    },
    {
      path: '/signup',
      name: 'signup',
      component: Signup,
     // beforeEnter : guardmyrouter
    },    
    {
      path: '/dashboard',
      name: 'dashboard',
      component: dashboard,
   //  beforeEnter : guardmyrouter
    },
    {
      path: '/add_new_hotel',
      name: 'add_hotel',
      component: AddNewHotel,
  //   beforeEnter : guardmyrouter
    },
    {
      path: '/edit_hotel',
      component: editHotel,
    // beforeEnter : guardmyrouter
    },
    {
      path: '/add_user',
      component: addUser,
    // beforeEnter : guardmyrouter
    },    
    {
      path: '/add_room_info',
      component: AddNewRoomInfo,    
    // beforeEnter : guardmyrouter 
    }, 
    {
      path: '/edit_room_info',
      component: EditRoomInfo,   
   //  beforeEnter : guardmyrouter  
    },        
    {
      path: '/hotel_list',
      name: 'hotel_list',
      component: HotelList,
   //  beforeEnter : guardmyrouter
    },
    {
      path: '/amenities',
      name: 'amenities',
      component: Amenities,
   //  beforeEnter : guardmyrouter
    },
    {
      path: '/category',
      name: 'category',
      component: Category,
   //  beforeEnter : guardmyrouter
    },
    {
      path: '/booking',
      name: 'Booking',
      component: Booking,
    // beforeEnter : guardmyrouter
    },
    {
      path: '/setting',
      name: 'setting',
      component: Setting,
    // beforeEnter : guardmyrouter
    },    
    {
      path: '/payment',
      name: 'Payment',
      component: Payment,
    // beforeEnter : guardmyrouter
    },     
    {
      path: '/facility',
      name: 'facility',
      component: Facility,
    // beforeEnter : l
    },     
    {
      path: '/test',
      name: 'test',
      component: test
    }     

  ]
  
})