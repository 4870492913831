<template>
    <default-container>
        <nav class="page-breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="#">Hotel</a></li>
                <li class="breadcrumb-item active" aria-current="page">Add New Hotel</li>
            </ol>
        </nav>   
        <h4 class="page-title">Add New Hotel</h4>
        
        <!-- <ul class="nav nav-pills add-new-hotel mb-3 d-none d-sm-block" id="pills-tab" role="tablist">
            <li class="nav-item" role="presentation">
                <button class="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">General Info</button>
            </li>
            <li class="nav-item" role="presentation">
                <button class="nav-link disabled" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-disabled="true" aria-selected="false">Rooms info</button>
            </li>
            <li class="nav-item" role="presentation">
                <button class="nav-link disabled" id="phone-tab" data-bs-toggle="pill" data-bs-target="#phone-profile" type="button" role="tab" aria-controls="phone-profile" aria-selected="false">Phone Numbers</button>
            </li>             
            <li class="nav-item" role="presentation">
                <button class="nav-link disabled" id="type-profile-tab" data-bs-toggle="pill" data-bs-target="#type-profile" type="button" role="tab" aria-controls="type-profile" aria-selected="false">Rooms Type</button>
            </li>
            <li class="nav-item" role="presentation">
                <button class="nav-link disabled" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Rooms info</button>
            </li>
            <li class="nav-item" role="presentation">
                <button class="nav-link disabled" id="pic-contact-tab" data-bs-toggle="pill" data-bs-target="#pic-contact" type="button" role="tab" aria-controls="pic-contact"  aria-selected="false">Pictures</button>
            </li>
            <li class="nav-item" role="presentation">
                <button class="nav-link disabled" id="taxation-tab" data-bs-toggle="pill" data-bs-target="#taxation" type="button" role="tab" aria-controls="taxation"  aria-selected="false">Taxation</button>
            </li>
        </ul> -->
        <div class="tab-content" id="pills-tabContent">
            <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                <add-new-hotel />
            </div>
            <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab"></div>
            <div class="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab"></div>
        </div>            
    </default-container>
</template>

<script>
import AddNewHotel from '../../components/AddNewHotel/AddNewHotel.vue'
import DefaultContainer from '../../containers/DefaultContainer.vue'
export default {   
  components: { DefaultContainer, AddNewHotel },
  name: "add_hotel",
  data(){
      return{

      }
  },
  methods:{   
  },
  mounted(){
  }
}
</script>

<style>

</style>