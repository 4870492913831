<template>
  <div class="row mt-4">
    <div class="col-md-12">
      <div class="row">
        <div class="col-md-4">
          <div class="top-box">
            <h3>Hotels</h3>
            <div class="stat-icon h-bg"><i class="ri-hotel-line"></i></div>
            <h4>110</h4>
            <p>Compare to last year (2022)</p>
          </div>
        </div>
        <div class="col-md-4">
          <div class="top-box">
            <h3>Bookings</h3>
             <div class="stat-icon b-bg"><i class="ri-book-3-line"></i></div>
            <h4>2456</h4>
            <p>Compare to last year (2022)</p>
          </div>
        </div>
        <div class="col-md-4">
          <div class="top-box">
            <h3>Payment</h3>
             <div class="stat-icon p-bg"><i class="ri-bank-card-line"></i></div>
            <h4>$2,454</h4>
            <p>Compare to last year (2022)</p>
          </div>
        </div>
      </div>
    </div>
  </div>  
</template>

<script>
export default {

}
</script>

<style lang="scss">
    @import "../../scss/Dashboard/Dashboard";
</style>