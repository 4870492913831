<template>
    <default-container>
        <nav class="page-breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="#">Main </a></li>
                <li class="breadcrumb-item active" aria-current="page">Manage Category</li>
            </ol>
        </nav>   
        <h4 class="page-title">Manage Category</h4>
        <div class="row">
            <div class="col-md-12">
                <category />
            </div>
        </div>         
    </default-container>
</template>

<script>
import Category from '../../components/Category/Category.vue'
import DefaultContainer from '../../containers/DefaultContainer.vue'

export default {  
  components: { DefaultContainer, Category },
    name: "",
  data(){
      return{
      }
  },
  methods:{   
  },
  mounted(){

  }
}
</script>

<style>

</style>