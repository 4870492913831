<template>
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-lg-12">
                            <button type="button" class="btn btn-primary pull-right btn-lg" data-bs-toggle="modal" data-bs-target="#addnewphonemodal">Add New Phone NUmber</button>
                            <v-client-table :columns="columns" :data="data" :options="options" id="dataTable" class="main-data-table">
                                <div slot-scope="props" slot="id">                                         
                                    <button @click="editPhone(props.row.id, props.row.name, props.row.number)"   class="btn btn-primary" type="button"><i class="fa-solid fa-pencil"></i></button>
                                    <button @click="deletephone(props.row.id)" class="btn btn-danger" type="button"><i class="fa-solid fa-trash-can"></i></button>
                                </div>                                
                            </v-client-table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    <!-- add phone modal -->
    <div class="modal fade" id="addnewphonemodal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <form @submit.prevent="roomsubmit" ref="roomform">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Add New Phone Number</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="form-group mb-3">
                            <label for="phone_name" class="form-label">Phone Type name</label>
                            <input type="text" v-model="phone_name" id="phone_name" name="phone_name" class="form-control" placeholder="Phone Name" 
                            :class="{ 'is-invalid': phonesubmitted && $v.phone_name.$error }">
                            <div v-if="phonesubmitted && !$v.phone_name.required" class="invalid-feedback">Phone Name required</div>
                        </div>
                        <div class="form-group mb-3">
                            <label for="phone_number" class="form-label">Phone Number</label>
                            <input type="number" v-model="phone_number" id="phone_number" name="phone_number" class="form-control" placeholder="Phone Number" 
                            :class="{ 'is-invalid': phonesubmitted && $v.phone_number.$error }">
                            <div v-if="phonesubmitted && !$v.phone_number.required" class="invalid-feedback">Phone required</div>
                        </div>              
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        <button type="submit" class="btn btn-primary">Save changes</button>
                    </div>
                </form>
            </div>
        </div>
    </div>

    <!-- add phone modal -->
    <div class="modal fade" id="updatephonemodal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Update Phone Number</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="form-group mb-3">
                        <label for="update_phone_name" class="form-label">Phone Type</label>
                        <input type="text" v-model="update_phone_name" id="update_phone_name" name="update_phone_name" class="form-control" placeholder="Phone TYpe" 
                        :class="{ 'is-invalid': updatephonesubmitted && $v.update_phone_name.$error }">
                        <div v-if="updatephonesubmitted && !$v.update_phone_name.required" class="invalid-feedback">Phone Name required</div>
                    </div>
                    <div class="form-group mb-3">
                        <label for="update_phone_number" class="form-label">Phone Number</label>
                        <input type="number" v-model="update_phone_number" id="update_phone_number" name="update_phone_number" class="form-control" placeholder="Phone Number" 
                        :class="{ 'is-invalid': updatephonesubmitted && $v.update_phone_number.$error }">
                        <div v-if="updatephonesubmitted && !$v.update_phone_number.required" class="invalid-feedback">Phone required</div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button class="btn btn-primary" @click="updatemobilesubmit">Save changes</button>
                </div>
            </div>
        </div>
    </div>      
    </div>   
</template>

<script>
import axios from 'axios';
import Header from "../../../mixins/Header";
import { required } from "vuelidate/lib/validators";
import {ClientTable, Event} from 'vue-tables-2'
export default {   
  components: { ClientTable, Event  },
  name: "room_info",
  data(){
      return{
        data: [],
        hotel_id: this.$route.query.hotel_id,
        columns: ['name', 'number', 'id'],
        options: {
            headings: {
                name: 'Phone Number TYpe',
                number: 'Phone NUmber',
                id: 'Action',
            },
            sortable: [],
            filterable: [],
            sortIcon: { base:'fa', up:'fa-sort-asc', down:'fa-sort-desc', is:'fa-sort' },
            pagination: {
                chunk: 5,
                edge: false,
                nav: 'scroll'
            }
        },         
        phonesubmitted: false,
        submitted: false,
        phone_name: "",
        phone_number: "",    
        updatephonesubmitted: false,
        update_phone_name: "",
        update_phone_number: "",
        update_phone_id: "",
      }
  },
  validations: {  
      phone_name: { required },  
      phone_number: { required },
      update_phone_name: { required },
      update_phone_number: { required }     
  }, 
  methods:{   
    async roomsubmit(e) { 

      this.phonesubmitted = true;

        this.$v.phone_name.$touch();
        this.$v.phone_number.$touch();

        if (this.$v.phone_name.$error || 
        this.$v.phone_number.$error
        ) {   
        } 
        else{

            var reqBody = {
                name: this.phone_name,
                number: this.phone_number
            }

            console.log(reqBody)
            try{
                let response = await this.axios
                .post(`${process.env.VUE_APP_API_URL}hotel/phone/number/create?hotel_id=${this.hotel_id}`, reqBody, Header.APP_ADMIN_HEADER)
                .then((res) => {
                    let data = res.data;
                    console.log(data)
                    this.phone_name = '';
                    this.phone_number = '';  
                    this.$v.phone_name.$reset();   
                    this.$v.phone_number.$reset();  
                    this.$toast.success(data.message, {hideProgressBar: true, timeout: 3000,});   
                    this.getContactList();
                    bootstrap.Modal.getOrCreateInstance(document.getElementById('addnewphonemodal')).hide();     
                })                    
            }catch(error){    
                console.log(error)   
                this.$toast.error(error.message, {hideProgressBar: true, timeout: 3000,});                                   
            }             
        }
    }, 
    async getContactList() {

        this.data = []

        try {
          let response = await this.axios
            .get(`${process.env.VUE_APP_API_URL}hotel/phone/number/get/list?hotel_id=${this.hotel_id}`, Header.APP_ADMIN_HEADER)
            .then((response) => {
                console.log(response.data)
              for (let a = 0; a < response.data.length; a++){
                this.data.push({
                  name :response.data[a].name,
                  number:response.data[a].number,
                  id:response.data[a].id,
                })               
              }
            })
        } catch (error) {
          console.log(error)          
        }
    },
   async deletephone(id) { 
    
    this.$swal({
        title: 'Are you sure?',
        text: "Do you want to delete this Phone Number?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#0d6efd',
        cancelButtonColor: '#d33',
        confirmButtonText: 'delete it!'
    }).then((result) => {
        if (result.isConfirmed) {
            axios.delete(`${process.env.VUE_APP_API_URL}hotel/phone/number/delete?phone_no_id=${id}`, Header.APP_ADMIN_HEADER)
            .then(response => {
                console.log(response);
                this.$toast.success(response.data.message, {hideProgressBar: true, timeout: 3000,});    
                this.getContactList(); 
            })
            .catch(function (error) {
                console.log(error)
                this.$toast.error(error.message, {hideProgressBar: true, timeout: 3000,});  
            })  
        }
    })    
   },
   async editPhone(id, name, number) {

        this.update_phone_id = id
        this.update_phone_name = name
        this.update_phone_number = number

        bootstrap.Modal.getOrCreateInstance(document.getElementById('updatephonemodal')).show(); 
 
   },
   async updatemobilesubmit(e) { 

      this.updatephonesubmitted = true;

        this.$v.update_phone_name.$touch();
        this.$v.update_phone_number.$touch();

        if (this.$v.update_phone_number.$error || 
        this.$v.update_phone_name.$error) 
        {
        } 
        else{
            var reqBody = {
                name: this.update_phone_name,
                number: this.update_phone_number
            }

            console.log(reqBody)            
            
            try{
                let response = await this.axios
                .put(`${process.env.VUE_APP_API_URL}hotel/phone/number/update?phone_no_id=${this.update_phone_id}`, reqBody, Header.APP_ADMIN_HEADER)
                .then((res) => {
                    let data = res.data;
                    console.log(data)
                    this.update_phone_name = '';
                    this.update_phone_number = '';
                    this.$v.update_phone_name.$reset();
                    this.$v.update_phone_number.$reset();
                    this.getContactList();
                    this.$toast.success(data.message, {hideProgressBar: true, timeout: 3000,});    
                    bootstrap.Modal.getOrCreateInstance(document.getElementById('updatephonemodal')).hide();    
                })                    
            }catch(error){    
                console.log(error)   
                this.$toast.error(error.message, {hideProgressBar: true, timeout: 3000,});
                this.update_phone_name = '';
                this.update_phone_number = '';
                this.$v.update_phone_name.$reset();
                this.$v.update_phone_number.$reset();                                                
            }              
        }
    },   
  },
  mounted(){
      this.getContactList();
  }
}
</script>
<style>
</style>