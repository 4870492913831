<template>
  <div class="row mt-4">
    <div class="col-md-12">
      <div class="row">
        <div class="col-md-12">
          <div class="top-box">
              <h3 class="sub">Upcoming Booking</h3>
              <v-calendar class="w100" v-model="date"></v-calendar>
          <div class="recent-list">
            <h3 class="mt-3">Recent Booking</h3>
              <div class="list-row">
                <div class="day">06</div>
                <div class="desc">
                  <h5>User Test</h5>
                  <span>The Crown Hotel</span>
                </div>
                <div class="time">2022-12-06</div>
              </div>
              <div class="list-row">
                <div class="day">10</div>
                <div class="desc">
                  <h5>User Test</h5>
                  <span>The Crown Hotel</span>
                </div>
                <div class="time">2022-12-06</div>
              </div>
              <div class="list-row">
                <div class="day">12</div>
                <div class="desc">
                  <h5>User Test</h5>
                  <span>The Crown Hotel</span>
                </div>
                <div class="time">2022-12-06</div>
              </div>   
              <div class="list-row">
                <div class="day">06</div>
                <div class="desc">
                  <h5>User Test</h5>
                  <span>The Crown Hotel</span>
                </div>
                <div class="time">2022-12-06</div>
              </div>   
              <router-link to="/booking" class="text-link">View All bboking</router-link>                      
          <div>
          </div>  
          </div>              
          </div>
      </div>
    </div>
  </div> 
  </div>   
</template>

<script>

export default {
    components: {
    },
    data() {
        return {

          date: new Date(),


        }
        
        
    },    
}
</script>

<style lang="scss">
    @import "../../scss/Dashboard/Dashboard";
</style>