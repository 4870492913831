<template>
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-lg-12">
                            <button type="button" class="btn btn-primary pull-right btn-lg" data-bs-toggle="modal" data-bs-target="#AddNewSeasonModal">Add New Season</button>
                            <v-client-table ref="infoPagesTable" :columns="columns" :data="data" :options="options" id="dataTable" class="main-data-table">
                                <div slot-scope="props" slot="id">
                                    <!-- <button @click="editSeason(props.row.id, props.row.name, props.row.from_date, props.row.to_date)" class="btn btn-primary" type="button"><i class="fa-solid fa-pencil"></i></button> -->
                                    <button @click="deleteSeason(props.row.id)" class="btn btn-danger" type="button"><i class="fa-solid fa-trash-can"></i></button>
                                    <button @click="editInfoPage(props.row.id)" class="btn btn-info" type="button"><i class="ri-eye-fill"></i></button>
                                    
                                </div>
                                <div slot-scope="props" slot="from_date">
                                    {{ props.row.from_date }}
                                </div>
                                <div slot-scope="props" slot="to_date">
                                    {{ props.row.to_date }}
                                </div>    
                                <template v-slot:child_row="props">
                                    <div class="list-br"><b>From Date:</b> {{props.row.from_date}}</div>
                                    <div class="list-br"><b>To Date:</b> {{props.row.to_date}}</div>
                                </template>
                            </v-client-table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Add New season modal -->
        <div class="modal fade" id="AddNewSeasonModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Add New Season</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="form-group mb-3">
                                        <label for="season_name" class="form-label">Season Name</label>
                                        <input type="text" v-model="season_name" id="season_name" name="season_name" class="form-control" placeholder="Season Name" 
                                        :class="{ 'is-invalid': submitted && $v.season_name.$error }">
                                        <div v-if="submitted && !$v.season_name.required" class="invalid-feedback">Season Name required</div>
                                    </div>                                    
                                </div>
                                <div class="col-lg-12">
                                    <div class="form-group mb-3">
                                        <label for="description" class="form-label width-100">From to Date</label>
                                        <date-picker v-model="from_date" range class="width-100" format="YYYY-MM-DD" placeholder="Select date Range"
                                        :class="{ 'is-invalid': submitted && $v.from_date.$error }"
                                        >                                           
                                        </date-picker>
                                        <div v-if="submitted && !$v.from_date.required" class="invalid-feedback">Date required</div>
                                    </div>                                      
                                </div>
                            </div>                                                    
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button @click="seasonsubmit" class="btn btn-primary">Save changes</button>
                        </div>
                </div>
            </div>
        </div>
        <!-- Update season modal -->
        <div class="modal fade" id="updateSeasonModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Update Season</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="form-group mb-3">
                                        <label for="update_season_name" class="form-label">Season Name</label>
                                        <input type="text" v-model="update_season_name" id="update_season_name" name="update_season_name" class="form-control" placeholder="Season Name" 
                                        :class="{ 'is-invalid': updatesubmitted && $v.update_season_name.$error }">
                                        <div v-if="updatesubmitted && !$v.update_season_name.required" class="invalid-feedback">Season Name required</div>
                                    </div>                                    
                                </div>
                                <div class="col-lg-12">
                                    <div class="form-group mb-3">
                                        <label for="update_from_date" class="form-label width-100">From to Date</label>
                                        <date-picker v-model="update_from_date" range class="width-100" format="YYYY-MM-DD" placeholder="Select date Range"
                                        :class="{ 'is-invalid': updatesubmitted && $v.update_from_date.$error }"
                                        >                                           
                                        </date-picker>
                                        <div v-if="updatesubmitted && !$v.update_from_date.required" class="invalid-feedback">Date required</div>
                                    </div>                                      
                                </div>
                            </div>                                                    
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button @click="updateseasonsubmit" class="btn btn-primary">Save changes</button>
                        </div>
                </div>
            </div>
        </div>        
    </div>
</template>
<script>
import axios from 'axios';
import moment from 'moment'
import Header from "../../mixins/Header";
import DatePicker from 'vue2-datepicker';
import { required } from "vuelidate/lib/validators";
import {ClientTable, Event} from 'vue-tables-2'
export default {  
  components: { ClientTable, Event, DatePicker  },
  name: "room_info",
  data(){
      return{
        data: [],
        hotel_id: this.$route.query.hotel_id,
        room_id: this.$route.query.room_id,
        columns: ['name', 'from_date','to_date','id'],
        options: {
            perPage: 8,
            columnsDisplay: {
                name: 'desktopL',
                from_date: 'not_mobile',
                to_date: 'not_mobile',
                id: 'desktopL',
            },            
            headings: {
                name: 'Season Name',
                from_date: 'From Date',
                to_date: 'To Date',
                id: 'action',
            },
            sortable: [],
            filterable: [],
            sortIcon: { base:'fa', up:'fa-sort-asc', down:'fa-sort-desc', is:'fa-sort' },
            pagination: {
                chunk: 5,
                edge: false,
                nav: 'scroll'
            }
        },         
        rate:{
            plan: "",
            description: "",      
        },      
        submitted: false,
        updatesubmitted: false,
        season_name: "",
        from_date: [],
        update_season_name: "",
        update_from_date: [],        
      }
  },
  validations: {
        season_name: { required },
        from_date: { required },
        update_season_name: { required },
        update_from_date: { required }
  }, 
  methods:{    

    async editInfoPage(id) {
        this.$refs.infoPagesTable.toggleChildRow(id);
    },      
    async seasonsubmit() { 

      this.submitted = true;

        this.$v.season_name.$touch();
        this.$v.from_date.$touch();

        if (
            this.$v.season_name.$error || 
            this.$v.from_date.$error
        ) 
        {
        }
        else{
            var reqBody = {
                name: this.season_name,
                from_date: String(moment(this.from_date[0]).format("YYYY-MM-DD")),
                to_date: String(moment(this.from_date[1]).format("YYYY-MM-DD")),
            }

            console.log(reqBody)
            
          try{
              let response = await this.axios
              .post(`${process.env.VUE_APP_API_URL}hotel/season/create?hotel_id=${this.hotel_id}`, reqBody, Header.APP_ADMIN_HEADER)
              .then((res) => {
                  console.log(res);   
                  this.getSeasonList();
                  this.$toast.success(res.data.message, {hideProgressBar: true, timeout: 3000,});     
                  bootstrap.Modal.getOrCreateInstance(document.getElementById('AddNewSeasonModal')).hide();  
              })                    
          }catch(error){    
              console.log(error)   
              this.$toast.error(error.message, {hideProgressBar: true, timeout: 3000,});                                   
          }             
        }
    },
    async editSeason(id, name, form_date, to_date) {

        this.updateBeddata = []
        this.updateBeddata.push({
            id: id
        });         
        this.beds.update_name = name
        this.beds.update_image_url = url

        bootstrap.Modal.getOrCreateInstance(document.getElementById('updateBedModal')).show();         

    },
    async updateseasonsubmit() { 

      this.updatesubmitted = true;

        this.$v.update_season_name.$touch();
        this.$v.update_from_date.$touch();

        if (
                !this.$v.update_season_name.$error || 
                !this.$v.update_from_date.$error
            ) 
        {                  
        } 
        else{
            var reqBody = {
                name: this.season_name,
                from_date: String(moment(this.update_from_date[0]).format("YYYY-MM-DD")),
                to_date: String(moment(this.update_from_date[1]).format("YYYY-MM-DD")),
            }

            console.log(reqBody)
            
          try{
              let response = await this.axios
              .post(`${process.env.VUE_APP_API_URL}hotel/season/create?hotel_id=${this.hotel_id}`, reqBody, Header.APP_ADMIN_HEADER)
              .then((res) => {
                  console.log(res);   
                  this.getSeasonList();
                  this.$toast.success(res.data.message, {hideProgressBar: true, timeout: 3000,});      
                  bootstrap.Modal.getOrCreateInstance(document.getElementById('updateSeasonModal')).hide(); 
              })                    
          }catch(error){    
              console.log(error)   
              this.$toast.error(error.message, {hideProgressBar: true, timeout: 3000,});                                   
          }             
        }
    },     
    async roomsubmit(e) {
      this.roomsubmitted = true;
      this.$v.room.room_type_id.$touch();
      this.$v.room.room_name.$touch();
      this.$v.room.room_allotments.$touch();
      this.$v.room.short_des.$touch();
      this.$v.room.long_des.$touch();
      this.$v.room.max_adults.$touch();
      this.$v.room.max_child.$touch();
      this.$v.room.child_age.$touch();      

      if(
        !this.$v.room.room_type_id.$invalid || 
        !this.$v.room.room_name.$invalid ||
        !this.$v.room.room_allotments.$invalid ||
        !this.$v.room.short_des.$invalid ||
        !this.$v.room.long_des.$invalid ||
        !this.$v.room.max_adults.$invalid ||
        !this.$v.room.max_child.$invalid ||
        !this.$v.room.child_age.$invalid    
      ){
          alert("success")
      }
 
        var reqBody = {
          room_type_id: this.room.room_type_id,
          room_name: this.room.room_name,
          room_allotments: this.room.room_allotments,
          short_des: this.room.short_des,
          long_des: this.room.long_des,
          max_adults: this.room.max_adults,
          max_child: this.room.max_child,
          child_age: this.room.child_age,
        }   
        
        console.log(reqBody)
    }, 
    async getSeasonList() {

        this.data = []

        try {
          let response = await this.axios
            .get(`${process.env.VUE_APP_API_URL}hotel/season/get/list?hotel_id=${this.hotel_id}`, Header.APP_ADMIN_HEADER)
            .then((response) => {
                console.log(response.data)
              for (let a = 0; a < response.data.length; a++){
                this.data.push({
                  name :response.data[a].name,
                  from_date:response.data[a].from_date,
                  to_date:response.data[a].to_date,
                  id:response.data[a].id,
                })               
              }
              console.log()
            })
        } catch (error) {
          console.log(error)          
        }
    },   
    async deleteSeason(id) { 
        
        this.$swal({
            title: 'Are you sure?',
            text: "Do you want to delete this Hotel Season?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#0d6efd',
            cancelButtonColor: '#d33',
            confirmButtonText: 'delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                axios.delete(`${process.env.VUE_APP_API_URL}hotel/season/delete?season_id=${id}`, Header.APP_ADMIN_HEADER)
                .then(response => {
                    console.log(response);
                    this.getSeasonList(); 
                    this.$toast.success(response.data.message, {hideProgressBar: true, timeout: 3000,});    
                })
                .catch(function (error) {
                    console.log(error)
                    this.$toast.error(error.message, {hideProgressBar: true, timeout: 3000,});  
                })  
            }
        })   
    },       
  },
  created(){
      this.getSeasonList();
  }
}
</script>

<style>

</style>