<template>
<default-container>
  <div>Welcome to Dashboard</div>
  <div class="row">
    <div class="col-md-9">
      <top-status />
      <main-chart />
    </div>
    <div class="col-md-3">
      <right-side />
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <recent-users />
    </div>
     <div class="col-md-6">
       <recent-payment />
     </div>
  </div>
</default-container>
</template>

<script>
import MainChart from '../components/Dashboard/MainChart.vue'
import TopStatus from '../components/Dashboard/TopStatus.vue'
import DefaultContainer from '../containers/DefaultContainer.vue'
import RightSide from '../components/Dashboard/RightSide.vue'
import RecentUsers from '../components/Dashboard/RecentUsers.vue'
import RecentPayment from '../components/Dashboard/RecentPayment.vue'
export default {
  components: { DefaultContainer, TopStatus, MainChart, RightSide, RecentUsers, RecentPayment },
}
</script>
<style lang="scss">
    @import "../scss/Dashboard/Dashboard";
</style>