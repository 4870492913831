<template>
    <default-container>
        <nav class="page-breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="#">Booking</a></li>
                <li class="breadcrumb-item active" aria-current="page">View Booking List</li>
            </ol>
        </nav>   
        <h4 class="page-title">Booking List</h4>
            <div class="row">
                <div class="col-lg-12">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-lg-12">

                                    <v-client-table :columns="columns" ref="infoPagesTable" :data="booking_list" :options="options" :theme="theme" id="dataTable" class="main-data-table booking-table">
                                    
                                        <my-custom-filter-component slot="filter" v-on:search="search"></my-custom-filter-component>                                       

                                        <div slot-scope="props" slot="checkIn">
                                            {{ props.row.checkIn.split('T')[0] }}
                                        </div>                                       
                                        <div slot-scope="props" slot="checkOut">
                                            {{ props.row.checkOut.split('T')[0] }}
                                        </div>
                                        <div slot-scope="props" slot="createdAt">
                                            {{ props.row.createdAt.split('T')[0] }}
                                        </div>
                                        <div slot-scope="props" slot="status">
                                            <span v-if="props.row.status == true" class="badge bg-success">Active</span>
                                            <span v-else class="badge bg-danger">Inactive</span>
                                        </div>                                        
                                        <div slot-scope="props" slot="roomId">
                                            <span class="badge border-warning text-dark label-border">{{ props.row.roomId }}</span>
                                        </div>
                                        <div slot-scope="props" slot="userId">
                                            <span class="badge border-warning text-dark label-border">{{ props.row.userId }}</span>
                                        </div>
                                        <div slot-scope="props" slot="id">
                                            <button @click="viewBooking(props.row.id)" class="btn btn-primary icon-with-text-btn" type="button">
                                                <i class="ri-eye-fill"></i> View
                                            </button>
                                        </div>                                                                              
                                    </v-client-table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Booking View -->
                <div class="modal fade" id="BookingViewModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-lg booking-view">
                        <div class="modal-content">
                            <form @submit.prevent="taxsubmit" ref="taxform">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="exampleModalLabel">Booking Details</h5>
                                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div class="modal-body">
                                  <div class="hotel-header">
                                    <div class="row">
                                      <div class="col-md-2">
                                        <div class="hotel-logo">
                                          <img src="img/logo2.png" />
                                        </div>
                                      </div>
                                      <div class="col-md-7">
                                        <div class="hotel-desc">
                                          <h4>
                                            <div class="stars">
                                              <i class="ri-star-fill"></i>
                                              <i class="ri-star-fill"></i>
                                              <i class="ri-star-fill"></i>
                                              <i class="ri-star-fill"></i>
                                              <i class="ri-star-fill"></i>
                                            </div>
                                            The Crown Hotel</h4>
                                          <span><i class="ri-map-pin-fill"></i> Crown Hotel, Jalan Raden Eddy Martadinata, Cipedes, Tasikmalaya Regency, West Java, Indonesia</span>
                                        </div>
                                      </div>
                                      <div class="col-md-3 text-right">
                                            <button class="btn btn-primary icon-with-text-btn" type="button">
                                                <i class="ri-eye-fill"></i> View Hotel
                                            </button>                                        
                                      </div>                                      
                                    </div>
                                  </div>
                                  <div class="book-info">
                                    <div class="row">
                                      <div class="col-md-12">
                                        <h4 class="fl">Booking Summary</h4>
                                        <span class="badge bg-success book-badge">Active</span>                                       
                                        <ul class="item-b">
                                          <li class="full">
                                            <b>Person Name:</b> <span>Kasun Test</span>
                                          </li>
                                          <li>
                                            <b>Created Date:</b> <span>Nov 21th 2022, 12:56:11 pm</span>
                                          </li>
                                          <li>
                                            <b>Check in Date:</b> <span>2022-11-26</span>
                                          </li>
                                          <li>
                                            <b>Check Out Date:</b> <span>2022-11-27</span>
                                          </li>
                                        </ul>

                                      </div>
                                    </div>
                                  </div>
                                  <div class="book-info last">
                                    <div class="row">
                                      <div class="col-md-12">
                                        <h4>Room Details</h4>
                                        <ul class="nav nav-pills add-new-hotel room-tabs mb-3" id="pills-tab" role="tablist">
                                            <li class="nav-item" role="presentation">
                                                <button class="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">General</button>
                                            </li>
                                            <li class="nav-item" role="presentation">
                                                <button class="nav-link" id="beds-tab" data-bs-toggle="pill" data-bs-target="#pills-beds" type="button" role="tab" aria-controls="pills-beds"  aria-selected="false">Tier Plans</button>
                                            </li>
                                            <li class="nav-item" role="presentation">
                                                <button class="nav-link" id="pic-tab" data-bs-toggle="pill" data-bs-target="#pills-pic" type="button" role="tab" aria-controls="pills-pic"  aria-selected="false">Room Extras</button>
                                            </li>            
                                            <li class="nav-item" role="presentation">
                                                <button class="nav-link" id="meal-tab" data-bs-toggle="pill" data-bs-target="#pills-meal" type="button" role="tab" aria-controls="pills-meal"  aria-selected="false">Room Facilities</button>
                                            </li>                         
                                        </ul>
                                          <div class="tab-content room-tabs-content" id="pills-tabContent">
                                              <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                                                <ul class="item-b">
                                                  <li class="full">
                                                    <b>Room ID:</b> <span>6d59afe5-ea87-4d94-aba7-4148d80453b8</span>
                                                  </li>
                                                  <li>
                                                    <b>Room Name:</b> <span>Room Name here</span>
                                                  </li>
                                                  <li>
                                                    <b>Room Type:</b> <span>Sea Side</span>
                                                  </li>                                                  
                                                </ul>
                                              </div>
                                              <div class="tab-pane fade" id="pills-beds" role="tabpanel" aria-labelledby="beds-tab">
                                                b
                                              </div>
                                              <div class="tab-pane fade" id="pills-pic" role="tabpanel" aria-labelledby="pic-tab">
                                              c
                                              </div>            
                                              <div class="tab-pane fade" id="pills-meal" role="tabpanel" aria-labelledby="meal-tab">
                                                d
                                              </div>           
                                          </div> 
                                      </div>
                                    </div>
                                  </div>                                  
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>                
            </div>
    </default-container>
</template>
<script>
import moment from 'moment'
import Vue from 'vue'
import axios from 'axios';
import Header from "../../mixins/Header";
import {ClientTable, Event} from 'vue-tables-2'
import DefaultContainer from '../../containers/DefaultContainer.vue'
import mixinHotel from "@/mixins/Hotel/hotel";

Vue.use(ClientTable)
export default {
    mixins: [
        mixinHotel
    ],    
  components: { 
      DefaultContainer,
      ClientTable,
      Event,      
      },
  name: "hotel_list",
  data(){
      return{
        data: [],
        view_booking: [],
        hotel_list:[],
        theme: 'bootstrap5',
        hotelsubmitted: false,
        submitted: false,
        columns: ['checkIn', 'checkOut','createdAt','roomId','status','userId', 'id'],
        options: {
            customFilters: [{
          name: 'mysearch',
          callback: function(row, query) {
            //this should be updated to match your data objects
            return row.name[0] == query; 
          }
        }],   
            perPage: 8,
            columnsDisplay: {
                checkIn: 'desktopL',
                checkOut: 'desktopL',   
                status: 'not_mobile',                              
                createdAt: 'not_mobile',
                roomId: 'not_mobile',
                userId: 'not_mobile',
                id: 'desktopL',
            },                
            headings: {
                checkIn: 'Check In',
                checkOut: 'Check Out',
                createdAt: 'created At',
                roomId: 'room Id',
                status: 'status',
                userId: 'user Id',
                id: 'Action'                
            },
            sortable: ['hotel_name', 'email','country','total_rooms',],
            filterable: ['checkIn', 'checkOut','createdAt','roomId','userId'],
            sortIcon: { base:'fa', up:'fa-sort-asc', down:'fa-sort-desc', is:'fa-sort' },
            pagination: {
                chunk: 5,
                edge: false,
                nav: 'scroll'
            }
        },  
        booking_list: [],
        searchTerm: ""
      }
  },

  watch: {
    data(data) {
      // set the group metas when data changes
      // groupColumn is the same as the one used for 'groupBy' option
      this.options.groupMeta = data.reduce((groupMeta, r) => {
        if (!groupMeta[r.groupColumn]) {
          groupMeta[r.groupColumn] = {
            label: r.groupColumn,
            total: 0,
          };
        }
        groupMeta[r.groupColumn].total += 1;
        return groupMeta;
      }, {});
    },
  },
  methods:{

    async viewBooking(id) {

       this.view_booking = []
        try {
          let response = await this.axios         
            .get(`${process.env.VUE_APP_BOOKING_API}bookings/admin/details/${id}`, Header.APP_ADMIN_HEADER)
            .then((response) => {
                console.log(response)
                // this.view_booking = response.data
                // console.log(this.view_booking)
            })
        } catch (error) {
          console.log(error)          
        }

      bootstrap.Modal.getOrCreateInstance(document.getElementById('BookingViewModal')).show(); 
    },
    search(filter) {
      this.searchQuery = filter.keyword;
    },  

   async getbookinglist  () {

       this.booking_list = []
        try {
          let response = await this.axios         
            .get(`${process.env.VUE_APP_BOOKING_API}bookings/admin/all`, Header.APP_ADMIN_HEADER)
            .then((response) => {
                console.log(response)
                this.booking_list = response.data.reverse()
                console.log(this.booking_list)
                for (let a = 0; a < this.hotel_list.length; a++){
                  this.booking_list.push({
                    checkIn :this.booking_list[a].checkIn,
                    checkOut:this.booking_list[a].checkOut.split('T')[0],
                    createdAt:this.booking_list[a].createdAt.split('T')[0],
                    roomId:this.booking_list[a].roomId,
                    status:this.booking_list[a].status,
                    userId:this.booking_list[a].userId,
                    id:this.booking_list[a].id,
                  })               
                }
              console.log(this.data)
            })
        } catch (error) {
          console.log(error)          
        }       
   },
   async goedithotel(id) {
        this.$router.push("/edit_hotel?hotel_id="+id)
   },
   async deleteHotel(id) { 
    
    this.$swal({
        title: 'Are you sure?',
        text: "Do you want to delete this Hotel?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#0d6efd',
        cancelButtonColor: '#d33',
        confirmButtonText: 'delete it!'
    }).then((result) => {
        if (result.isConfirmed) {
            axios.delete(`${process.env.VUE_APP_API_URL}hotel/delete?hotel_id=${id}`, Header.APP_ADMIN_HEADER)
            .then(response => {
                console.log(response);
                this.$toast.success(response.data.message, {hideProgressBar: true, timeout: 3000,});    
                this.getHotelList(); 
            })
            .catch(function (error) {
                console.log(error)
                this.$toast.error(error.message, {hideProgressBar: true, timeout: 3000,});  
            })  
        }
    })    
    

   },  
    applyFilter(letter) {
      this.selectedLetter = letter;
      Event.$emit('vue-tables.filter::alphabet', letter);
    }     
  },
  created(){
      this.getbookinglist();
  },
  mounted(){     
  }
}
</script>

<style>

</style>