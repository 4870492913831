<template>
  <div class="row mt-4">
    <div class="col-md-12">
      <div class="row">
        <div class="col-md-12">
          <div class="top-box">
            <h3 class="sub">Crover Overview</h3>
              <apexchart type="line" height="400" :options="chartOptions" :series="series"></apexchart>
          </div>
        </div>
      </div>
    </div>
  </div>  
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
export default {
    components: {
        apexchart: VueApexCharts,
    },
    data() {
        return {
        series: [{
            name: "Number of Hotels",
            type: "bar",
            data: [34, 65, 46, 68, 49, 61, 42, 44, 78, 52, 63, 67]
        }, {
            name: "Booking",
            type: "area",
            data: [89.25, 98.58, 68.74, 108.87, 77.54, 84.03, 51.24, 28.57, 92.57, 42.36, 88.51, 36.57]
        }, {
            name: "Payment",
            type: "bar",
            data: [8, 12, 7, 17, 21, 11, 5, 9, 7, 29, 12, 35]
        }],
          chartOptions: {

            chart: {
                height: 374,
                type: "line",
                toolbar: {
                    show: !1
                }
            },

            stroke: {
                curve: "smooth",
                dashArray: [0, 3, 0],
                width: [0, 1, 0]
            },
            fill: {
                opacity: [1, .1, 1]
            },
            markers: {
                size: [0, 4, 0],
                strokeWidth: 2,
                hover: {
                    size: 4
                }
            },            
            xaxis: {
              categories: ["Jan", "Feb", "Mar", "Apr", "May", "June", "JUly", "Aug", "Sep","Oct", "Nov","Dec"],
              axisTicks: {
                  show: !1
              },
              axisBorder: {
                  show: !1
              }
            },
            grid: {
                show: !0,
                xaxis: {
                    lines: {
                        show: !0
                    }
                },
                yaxis: {
                    lines: {
                        show: !1
                    }
                },
                padding: {
                    top: 0,
                    right: -2,
                    bottom: 15,
                    left: 10
                }
            },            
            yaxis: [
              {
              },
            ],
            tooltip: {
              fixed: {
                enabled: true,
                position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
                offsetY: 30,
                offsetX: 60
              },
            },
            legend: {
                show: !0,
                horizontalAlign: "center",
                offsetX: 0,
                offsetY: -5,
                markers: {
                    width: 9,
                    height: 9,
                    radius: 6
                },
                itemMargin: {
                    horizontal: 10,
                    vertical: 0
                }
            },
            plotOptions: {
                bar: {
                    columnWidth: "30%",
                    barHeight: "70%"
                }
            },            
          },

        }
        
        
    },    
}
</script>

<style lang="scss">
    @import "../../scss/Dashboard/Dashboard";
</style>