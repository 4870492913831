<template>
  <div class="header-part">
  <header class="py-3 mb-3 border-bottom header-inner">
    <div class="container-fluid d-grid gap-3 align-items-center" style="grid-template-columns: 1fr 2fr;">
      <div class="dropdown">
      </div>

      <div class="d-flex align-items-center admin-info">
        <form class="w-100 me-3 text-right" role="search">
          <b class="usename">{{ username }}</b>
        </form>
        <div class="flex-shrink-0 dropdown profile">
          <a href="#" class="d-block link-dark text-decoration-none dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
            <img src="img/user.png" alt="mdo" width="32" height="32" class="rounded-circle">
          </a>
          <ul class="dropdown-menu text-small shadow">
            <li><a class="dropdown-item" href="#">New Hotel</a></li>
            <li><a class="dropdown-item" href="#">Settings</a></li>
            <li><a class="dropdown-item" href="#">Profile</a></li>
            <li><hr class="dropdown-divider"></li>
            <li><a class="dropdown-item" href="#" @click="logout">Sign out</a></li>
          </ul>
        </div>
      </div>
    </div>
  </header>    
  </div>
</template>

<script>
import { Auth } from 'aws-amplify';
export default {
  data() {
    return{
      username: localStorage.getItem("useremail")
    }
  },
methods: {
  async logout() {
        try {
            await Auth.signOut()
            .then(data=>{
                localStorage.clear();
                this.$toast.success("successfully logout", {hideProgressBar: true, timeout: 3000,});   
                this.$router.push("/"); 

            })
        } catch (error) {
            console.log(error);
            this.$toast.error(error, {hideProgressBar: true, timeout: 3000,});    
        } 
  }
}
}
</script>

<style lang="scss">
    @import "../../scss/Header/Header";
</style>