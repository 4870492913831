<template>  
   <div class="app-area">
     <div class="login-area">
       <h3 v-if="loginsuccess" class="text-center mt-4">Welcome to Crover Dashboard</h3>
       <div v-if="loginsuccess" @click="logout" class="text-center">logout</div>
       <div class="left-side" v-if="!loginsuccess">
        <h2 v-if="!loginsuccess">Lets find New Hotel</h2>
        <p v-if="!loginsuccess">Lorem ipsum dolor sit amet, consectetur</p>         
       </div>
       <div class="right-side mobile-login-bg" v-if="!loginsuccess">
         <div class="logo">
           <img src="img/logo.png" width="200" />
         </div>
         <h5 class="sub-t">Login Form</h5>
         <div class="login-form">
            <div class="form-group mb-4 mt-4" v-if="!showpassword">
              <i class="fa-solid fa-envelope"></i>
              <input type="text" class="form-control" id="musername" name="musername" v-model="user.musername" placeholder="Username" autocomplete="off" 
              :class="{ 'is-invalid': $v.user.musername.$error }">
              <div v-if="!$v.user.musername.required" class="invalid-feedback">Username required</div>
              <div v-if="!$v.user.musername.email" class="invalid-feedback">Email Address format not valid</div>
            </div>  
            <div class="form-group mb-4 mt-4" v-if="showpassword">
             <i class="ri-smartphone-fill"></i>
              <input type="number" min="6" class="form-control" id="mpassword" name="mpassword" v-model="user.mpassword" placeholder="OTP Code" autocomplete="off" :class="{ 'is-invalid': submitted && $v.user.mpassword.$error }">
              <div v-if="submitted && !$v.user.mpassword.required" class="invalid-feedback">OTP Code required</div>
            </div>
            <div class="mb-3" v-if="!showpassword">
              <button class="btn btn-primary login-btn" @click="handleSubmit(user.musername)" :class="{ 'disabled': spinner }">
                <div class="spinner-border text-light" role="status" v-if="spinner">
                  <span class="visually-hidden">Loading...</span>
                </div>              
                  Login Now
              </button> 
            </div>

            <div class="mb-3" v-if="showpassword">
              <button class="btn btn-primary login-btn" @click="userSigninbycode(user.mpassword)" :class="{ 'disabled': spinner }">
                <div class="spinner-border text-light" role="status" v-if="spinner">
                  <span class="visually-hidden">Loading...</span>
                </div>              
                  Enter OTP
              </button> 
            </div>              
         </div>
       </div>
     </div>
   </div>
</template>

<script>
import { Auth } from "aws-amplify";
import { required, email } from "vuelidate/lib/validators";

export default {
  name: 'App',
  components: {
  },
  data() {
    return{
      spinner: false,
      loginsuccess: false,
      showpassword: false,
      state: {
          cognitoUser: []
      },      
      user: {
        musername: "",
        mpassword: "",
      },
      submitted: false
    }
  },
  validations: {
      user: {
        musername: { required, email },
        mpassword: { required },
      }
  },  
  computed:{
    admin_token(){
      return this.$store.getters.admin_token
    }
  },
  methods:{
    
     async handleSubmit(email) {
      this.$router.push("/dashboard");
      this.$v.user.musername.$touch();

      if(!this.$v.user.musername.$invalid) {

      this.spinner = true
      this.submitted = true;

        try {
            await Auth.signIn(email)
            .then(data=>{
                console.log(data)

                this.state.cognitoUser = data
                this.showpassword = true

            })
        } catch (error) {
            console.log(error);
            this.$toast.error("Invalid User. Please enter correct user", {hideProgressBar: true, timeout: 2000,});  
        }   
        
         this.spinner = false

      }
    },

    async userSigninbycode(code) {
      this.$router.push("/dashboard");
      this.$v.user.mpassword.$touch();

      if(!this.$v.user.mpassword.$invalid) {

        this.spinner = true

        let otp = code
        let user = this.state.cognitoUser;
        
        console.log(otp)
        console.log(user)

        try {
            await Auth.sendCustomChallengeAnswer(user, otp)
            .then(data=>{
                console.log(data)
                if(data.storage.length == 0){
                    this.$toast.error("Incorrect OTP code", {hideProgressBar: true, timeout: 2000,});  
                }
                else{
                  if(data.signInUserSession.accessToken.payload["cognito:groups"] == "admin_admin") {
                    console.log(data.signInUserSession.accessToken.payload["cognito:groups"][0])
                    localStorage.setItem('token',data.signInUserSession.accessToken.jwtToken)
                    localStorage.setItem('nexttoken',data.signInUserSession.refreshToken.token)
                    localStorage.setItem('useremail',this.user.musername)
                    this.$toast.success("successfully login", {hideProgressBar: true, timeout: 3000,});   
                   // this.$router.push("/dashboard");
                  }
                  else{
                    this.showpassword = false
                    this.user.musername = '';
                    this.$v.user.musername.$reset();
                    this.user.mpassword = '';
                    this.$v.user.mpassword.$reset();
                    this.$toast.error("Please enter a Administrative Acccount to login", {hideProgressBar: true, timeout: 2000,});  
                  }
                } 
            })
        } catch (error) {
          console.log(error); 
          this.$toast.error("Incorrect OTP code", {hideProgressBar: true, timeout: 3000,});
        }  
        this.spinner = false
      }
    },
    async logout() {
        try {
            await Auth.signOut();
            this.loginsuccess = false
            localStorage.removeItem("access-token")
        } catch (error) {
            console.log('error signing out: ', error);
        }
    }    
  },
  mounted() {
  }
}
</script>

<style lang="scss">
    @import "../scss/Login/Login";
</style>
