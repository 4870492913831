<template>
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-lg-12">
                            <button type="button" class="btn btn-primary pull-right btn-lg" data-bs-toggle="modal" data-bs-target="#addnewroomtypemodal">Add New Room Type</button>
                            <v-client-table ref="infoPagesTable" :columns="columns" :data="data" :options="options" id="dataTable" class="main-data-table">
                                <div slot-scope="props" slot="id">                                         
                                    <button @click="edittype(props.row.id, props.row.name, props.row.description)"   class="btn btn-primary" type="button"><i class="fa-solid fa-pencil"></i></button>
                                    <button @click="deletetype(props.row.id)" class="btn btn-danger" type="button"><i class="fa-solid fa-trash-can"></i></button>
                                    <button @click="editInfoPage(props.row.id)" class="btn btn-info" type="button"><i class="ri-eye-fill"></i></button>
                                </div>
                                <template v-slot:child_row="props">
                                    <div class="list-br"><b>Description:</b> {{props.row.description}}</div>
                                </template>                                  
                            </v-client-table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    <!-- Add New Room modal -->
    <div class="modal fade" id="addnewroomtypemodal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <form @submit.prevent="roomsubmit" ref="roomform">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Add New Room Type</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="form-group mb-3">
                            <label for="room_name" class="form-label">Room Name</label>
                            <input type="text" v-model="room_name" id="room_name" name="room_name" class="form-control" placeholder="Room Name" 
                            :class="{ 'is-invalid': roomsubmitted && $v.room_name.$error }">
                            <div v-if="roomsubmitted && !$v.room_name.required" class="invalid-feedback">Room Name required</div>
                        </div>
                        <div class="form-group mb-3">
                            <label for="room_desscription" class="form-label">Room Description</label>
                            <textarea class="form-control" v-model="room_desscription" id="amenity_name" name="amenity_name" rows="5" placeholder="Room Description" 
                            :class="{ 'is-invalid': roomsubmitted && $v.room_desscription.$error }"></textarea>
                            <div v-if="roomsubmitted && !$v.room_desscription.required" class="invalid-feedback">Description required</div>
                        </div>              
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        <button type="submit" class="btn btn-primary">Save changes</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <!-- Edit Room modal -->    
    <div class="modal fade" id="updateroomtypemodal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <form @submit.prevent="updateroomsubmit" ref="roomform">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Update Room Type</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="form-group mb-3">
                            <label for="update_type_name" class="form-label">Room type Name</label>
                            <input type="text" v-model="update_type_name" id="update_type_name" name="update_type_name" class="form-control" placeholder="Room type name" 
                            :class="{ 'is-invalid': updateroomsubmitted && $v.update_type_name.$error }">
                            <div v-if="updateroomsubmitted && !$v.update_type_name.required" class="invalid-feedback">Room Name required</div>
                        </div>
                        <div class="form-group mb-3">
                            <label for="update_type_description" class="form-label">Room type Description</label>
                            <textarea rows="5" v-model="update_type_description" id="update_type_description"  name="update_type_description" class="form-control" placeholder="Room type Description"
                            :class="{ 'is-invalid': updateroomsubmitted && $v.update_type_description.$error }"></textarea>
                            <div v-if="updateroomsubmitted && !$v.update_type_description.required" class="invalid-feedback">Description required</div>
                        </div>              
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        <button class="btn btn-primary">Save changes</button>
                    </div>
                </form>
            </div>
        </div>
    </div>      
    </div>   
</template>

<script>
import axios from 'axios';
import Header from "../../../mixins/Header";
import { required } from "vuelidate/lib/validators";
import {ClientTable, Event} from 'vue-tables-2'
export default {   
  components: { ClientTable, Event  },
  name: "room_info",
  data(){
      return{
        data: [],
        hotel_id: this.$route.query.hotel_id,
        columns: ['name', 'description', 'id'],
        options: {
            perPage: 8,
            columnsDisplay: {
                name: 'desktopL',
                description: 'not_mobile',
                id: 'desktopL',
            },            
            headings: {
                name: 'Room Type',
                description: 'Room Type Description',
                id: 'Action',
            },
            sortable: [],
            filterable: [],
            sortIcon: { base:'fa', up:'fa-sort-asc', down:'fa-sort-desc', is:'fa-sort' },
            pagination: {
                chunk: 5,
                edge: false,
                nav: 'scroll'
            }
        },         
        room:{
            newroom: true,
            room_type_id: "",
            room_name: "",
            room_allotments: "",
            short_des: "",
            long_des: "",
            max_adults: null,
            max_child: null,
            child_age: null,         
        },
        roomsubmitted: false,
        submitted: false,
        room_name: "",
        room_desscription: "",   
        update_type_id: "",
        update_type_name: "",
        update_type_description: "",
        updateroomsubmitted: false             
      }
  },
  validations: {
      room: { 
        room_type_id: { required },
        room_name: { required },  
        room_allotments: { required },  
        short_des: { required },
        long_des: { required },  
        max_adults: { required },  
        max_child: { required },  
        child_age: { required },  
      },    
      room_name: { required },  
      room_desscription: { required },  
    update_type_name: { required },
    update_type_description: { required },        
  }, 
  methods:{   
    async editInfoPage(id) {
        this.$refs.infoPagesTable.toggleChildRow(id);
    },
    async roomsubmit(e) { 

      this.roomsubmitted = true;

        this.$v.room_name.$touch();
        this.$v.room_desscription.$touch();

        if (this.$v.room_name.$error || 
        this.$v.room_desscription.$error
        ) {   
        }
        else{
            var reqBody = {
                name: this.room_name,
                description: this.room_desscription
            }

            console.log(reqBody)
            try{
                let response = await this.axios
                .post(`${process.env.VUE_APP_API_URL}room/type/create?hotel_id=${this.hotel_id}`, reqBody, Header.APP_ADMIN_HEADER)
                .then((res) => {
                    let data = res.data;
                    console.log(data)
                    this.room_name = '';
                    this.room_desscription = '';  
                    this.$v.room_name.$reset();   
                    this.$v.room_desscription.$reset();  
                    this.$toast.success(data.message, {hideProgressBar: true, timeout: 3000,});   
                    this.getRoomtypeList();
                    bootstrap.Modal.getOrCreateInstance(document.getElementById('addnewroomtypemodal')).hide();     
                })                    
            }catch(error){    
                console.log(error)   
                this.$toast.error(error.message, {hideProgressBar: true, timeout: 3000,});                                   
            }             
        }
    }, 
    async getRoomtypeList() {

        this.data = []

        try {
          let response = await this.axios
            .get(`${process.env.VUE_APP_API_URL}room/type/get/list?hotel_id=${this.hotel_id}`, Header.APP_ADMIN_HEADER)
            .then((response) => {
                console.log(response.data)
              for (let a = 0; a < response.data.length; a++){
                this.data.push({
                  name :response.data[a].name,
                  description:response.data[a].description,
                  id:response.data[a].id,
                })               
              }
            })
        } catch (error) {
          console.log(error)          
        }
    },
   async deletetype(id) { 
    
    this.$swal({
        title: 'Are you sure?',
        text: "Do you want to delete this Room type?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#0d6efd',
        cancelButtonColor: '#d33',
        confirmButtonText: 'delete it!'
    }).then((result) => {
        if (result.isConfirmed) {
            axios.delete(`${process.env.VUE_APP_API_URL}room/type/delete?room_type_id=${id}`, Header.APP_ADMIN_HEADER)
            .then(response => {
                console.log(response);
                this.$toast.success(response.data.message, {hideProgressBar: true, timeout: 3000,});    
                this.getRoomtypeList(); 
            })
            .catch(function (error) {
                console.log(error)
                this.$toast.error(error.message, {hideProgressBar: true, timeout: 3000,});  
            })  
        }
    })    
    

   },
   async edittype(id) {   
       this.$router.push("/edit_room_info?room_id="+id+"&hotel_id="+this.hotel_id);       
   },
   async edittype(id, name, description) {

        this.update_type_id = id
        this.update_type_name = name
        this.update_type_description = description

        bootstrap.Modal.getOrCreateInstance(document.getElementById('updateroomtypemodal')).show(); 
 
   },  
    async updateroomsubmit(e) { 

      this.updateroomsubmitted = true;

        this.$v.update_type_name.$touch();
        this.$v.update_type_description.$touch();

        if (
            this.$v.update_type_name.$error ||
            this.$v.update_type_description.$error
        ){               
        }
        else{
            var reqBody = {
                name: this.update_type_name,
                description: this.update_type_description
            }

            console.log(reqBody)            
            
            try{
                let response = await this.axios
                .put(`${process.env.VUE_APP_API_URL}room/type/update?room_type_id=${this.update_type_id}`, reqBody, Header.APP_ADMIN_HEADER)
                .then((res) => {
                    let data = res.data;
                    console.log(data)
                    this.update_type_name = '';
                    this.update_type_description = '';
                    this.$v.update_type_name.$reset();
                    this.$v.update_type_description.$reset();
                    this.getRoomtypeList();
                    this.$toast.success(data.message, {hideProgressBar: true, timeout: 3000,});    
                    bootstrap.Modal.getOrCreateInstance(document.getElementById('updateroomtypemodal')).hide();    
                })                    
            }catch(error){    
                console.log(error)   
                this.$toast.error(error.message, {hideProgressBar: true, timeout: 3000,});
                this.update_type_name = '';
                this.update_type_description = '';
                this.$v.update_type_name.$reset();
                this.$v.update_type_description.$reset();                                                
            }             
        }
    },    
},
  mounted(){
      this.getRoomtypeList();
  }
}
</script>

<style>

</style>