<template>  
   <div class="app-area">
     <div class="login-area">
       <h3 v-if="loginsuccess" class="text-center mt-4">Welcome to Crover Dashboard</h3>
       <div v-if="loginsuccess" @click="logout" class="text-center">logout</div>
       <div class="left-side signup-bg" v-if="!loginsuccess">
        <h2 v-if="!loginsuccess">Lets find New Hotel</h2>
        <p v-if="!loginsuccess">Lorem ipsum dolor sit amet, consectetur</p>         
       </div>

       <div class="right-side signup" v-if="!loginsuccess">

         <div class="logo mt-0">
           <img src="img/logo.png" width="200" />
         </div>

         <h3 class="sub-head">Sginup Form</h3>

         <div class="login-form">

           <div class="row">

             <div class="col-md-6">
              <div class="form-group mb-4 mt-2">
                <i class="ri-user-fill"></i>
                <input type="text" class="form-control" id="fname" name="fname" v-model="fname" placeholder="First Name" autocomplete="off" 
                :class="{ 'is-invalid': $v.fname.$error }">
                <div v-if="!$v.fname.required" class="invalid-feedback">First Name required</div>
              </div>                
             </div>
              <div class="col-md-6">
                <div class="form-group mb-4 mt-2">
                  <i class="ri-user-2-fill"></i>
                  <input type="text" class="form-control" id="mname" name="mname" v-model="mname" placeholder="Middle Name" autocomplete="off" 
                  :class="{ 'is-invalid': $v.mname.$error }">
                  <div v-if="!$v.mname.required" class="invalid-feedback">Middle Name required</div>
                </div>                 
              </div>

           </div>   

            <div class="row">
              <div class="col-md-12">
                <div class="form-group mb-4 mt-2">
                  <i class="fa-solid fa-envelope"></i>
                  <input type="text" class="form-control" id="username" name="username" v-model="username" placeholder="Username" autocomplete="off" 
                  :class="{ 'is-invalid': $v.username.$error }">
                  <div v-if="!$v.username.required" class="invalid-feedback">Email Address required</div>
                  <div v-if="!$v.username.email" class="invalid-feedback">Email Address format not valid</div>
                </div>                  
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <div class="form-group mb-4 mt-2">
                  <vue-phone-number-input @update="onUpdate" v-model="mobile" v-bind="mobile"
                  :class="{ 'is-invalid': $v.mobile.$error || showmobileerror}"
                  >
                  </vue-phone-number-input>
                  <div v-if="!$v.mobile.required" class="invalid-feedback">Mobile Number required</div>
                  <div v-if="showmobileerror" class="invalid-feedback extra-error">Mobile Number is not valid with Country</div>
                </div>                 
              </div>
            </div>

            <div class="row">
              <div class="col-md-12"></div>
            </div>                        
                        
                   
            <div class="mb-3" v-if="!showpassword">
              <button class="btn btn-primary login-btn" @click="handleSubmit()" :class="{ 'disabled': spinner }">
                <div class="spinner-border text-light" role="status" v-if="spinner">
                  <span class="visually-hidden">Loading...</span>
                </div>              
                  Signup Now
              </button> 
            </div>
             
         </div>

       </div>
     </div>
   </div>
</template>

<script>
import axios from 'axios';
import { Auth } from "aws-amplify";
import Header from "../mixins/Header";
import DatePicker from 'vue2-datepicker';
import { required, email } from "vuelidate/lib/validators";

export default {
  components: {
    DatePicker
  },
  data() {
    return{
      spinner: false,
      loginsuccess: false,
      showpassword: false,
      results: [],
      state: {
          cognitoUser: []
      },      
      submitted: false,
      fname: "",
      mname: "",
      username: "",
      mobile: "",   
      countryCode: "",
      showmobileerror: false,   
      sub: ""
    }
  },
  validations: {
        username: { required, email },
        fname: { required },
        mname: { required },   
        mobile: { required }
  },  
  computed:{
    admin_token(){
      return this.$store.getters.admin_token
    }
  },
  methods:{
      async onUpdate(payload) {
        
        this.results = payload;
        this.countryCode = "+"+this.results.countryCallingCode

        console.log(this.results)

        if(!this.$v.mobile.$invalid) {

          if(this.results.isValid == true){
            this.showmobileerror = false
          }    
          
          if(this.results.isValid == false){
            this.showmobileerror = true
          }          

        }

        else {
          this.showmobileerror = false
        }

      },
     async handleSubmit(email) {

      this.$v.fname.$touch();
      this.$v.mname.$touch();
      this.$v.username.$touch();
      this.$v.mobile.$touch();


      if(!this.$v.fname.$invalid || !this.$v.mname.$invalid || !this.$v.username.$invalid || !this.$v.mobile.$invalid) {

        if(this.showmobileerror == false) {

          const params = {
              password: "Test@123",
              username: this.username,
              attributes: {
                  email: this.username,
                  given_name: this.fname,
                  family_name: this.mname,
              }             
          };

        try {
          await Auth.signUp(params)
          .then(data=>{
              console.log(data)
              this.sub = data.userSub  
              this.adminsignup();
          })
        } catch (error) {
            console.log(error);
        }           

        }

        this.spinner = true
        this.submitted = true  
        this.spinner = false

      }

    },


   async adminsignup() {

    var reqBody = {
        sub: this.sub,
        Fname: this.fname,
        Lname: this.mname,
        userName: this.username,
        mobile: this.mobile,
        countryCode: this.countryCode
    }    
    
    console.log(reqBody)                

    try{
        let response = await this.axios
        .post(`${process.env.VUE_APP_AUTH_SERVICE_API}users/register`, reqBody)
        .then((res) => {
            let data = res.data;
            console.log(data)
            this.fname = "";
            this.mname = "";  
            this.username = "";
            this.mobile = "";
            this.countryCode = ""
            this.$v.fname.$reset();   
            this.$v.mname.$reset();  
            this.$v.username.$reset();  
            this.$v.mobile.$reset(); 
            document.getElementById('fname').removeAttribute('readonly');
            document.getElementById('mname').removeAttribute('readonly');
            document.getElementById('username').removeAttribute('readonly');            
            this.$toast.success("Successfully admin user signup", {hideProgressBar: true, timeout: 3000,});      
        })                    
    }catch(error){    
        console.log(error)   
        this.$toast.error(error.message, {hideProgressBar: true, timeout: 3000,});    
            this.fname = "";
            this.mname = "";  
            this.username = "";
            this.mobile = "";
            this.countryCode = ""
            this.$v.fname.$reset();   
            this.$v.mname.$reset();  
            this.$v.username.$reset();  
            this.$v.mobile.$reset();    
            document.getElementById('fname').removeAttribute('readonly');
            document.getElementById('mname').removeAttribute('readonly');
            document.getElementById('username').removeAttribute('readonly');                                                        
    } 
   },
    async userSigninbycode(code) {

      this.$v.user.mpassword.$touch();

      if(!this.$v.user.mpassword.$invalid) {

        this.spinner = true

        let otp = code
        let user = this.state.cognitoUser;
        
        console.log(otp)
        console.log(user)

        try {
            await Auth.sendCustomChallengeAnswer(user, otp)
            .then(data=>{
                console.log(data)
                if(data.storage.length == 0){
                    this.$toast.error("Incorrect OTP code", {hideProgressBar: true, timeout: 2000,});  
                    setTimeout(() => document.getElementById('mpassword').readOnly = "" , 2000);
                    setTimeout(() => document.getElementById('mpassword').removeAttribute('readonly') , 2000);                    
                }
                else{

                  if(data.signInUserSession.accessToken.payload["cognito:groups"] == "admin_admin") {
                    console.log(data.signInUserSession.accessToken.payload["cognito:groups"][0])

                    localStorage.setItem('token',data.signInUserSession.accessToken.jwtToken)
                    this.$toast.success("successfully login", {hideProgressBar: true, timeout: 3000,});  
                    setTimeout(() => document.getElementById('mpassword').readOnly = "" , 2000);
                    setTimeout(() => document.getElementById('mpassword').removeAttribute('readonly') , 2000);   
                    this.$router.push("/dashboard"); 

                  }
                  else{
                    this.showpassword = false
                    this.user.musername = '';  
                    this.$v.user.musername.$reset();                    
                    this.user.mpassword = '';  
                    this.$v.user.mpassword.$reset();
                    this.$toast.error("Please enter a Administrative Acccount to login", {hideProgressBar: true, timeout: 2000,});  
                    setTimeout(() => document.getElementById('musername').readOnly = "" , 2000);
                    setTimeout(() => document.getElementById('musername').removeAttribute('readonly') , 2000);
                  }
                } 
            })
        } catch (error) {
          console.log(error);
          setTimeout(() => document.getElementById('mpassword').readOnly = "" , 2000);
          setTimeout(() => document.getElementById('mpassword').removeAttribute('readonly') , 2000);  
          this.$toast.error("Incorrect OTP code", {hideProgressBar: true, timeout: 3000,});
        }  
        
        this.spinner = false
        
      }

    },

    async logout() {
        try {
            await Auth.signOut();
            this.loginsuccess = false
            localStorage.removeItem("access-token")
        } catch (error) {
            console.log('error signing out: ', error);
        }
    }    
  },
  mounted() {
    document.getElementById('fname').removeAttribute('readonly');
    document.getElementById('mname').removeAttribute('readonly');
    document.getElementById('username').removeAttribute('readonly');
  }
}
</script>

<style lang="scss">
    @import "../scss/Login/Login";
</style>
