<template>
    <default-container>
        <nav class="page-breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="#">Home</a></li>
                <li class="breadcrumb-item active" aria-current="page">Setting</li>
            </ol>
        </nav>   
        <h4 class="page-title">My Profile Setting</h4>
            <div class="row">
                <div class="col-lg-12">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-lg-12">
                                    <profile />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </default-container>
</template>
<script>
import Vue from 'vue'
import axios from 'axios';
import DefaultContainer from '../../containers/DefaultContainer.vue'
import Profile from '../../components/Setting/Profile.vue';
export default {   
  components: { 
      DefaultContainer,     
      
    Profile},
  name: "setting",
  data(){
      return{
        data: [],
      }
  },
  methods:{    
  },
  created(){
  },
  mounted(){     
  }
}
</script>
<style lang="scss">
    @import "../../scss/Setting/Setting";
</style>