/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {

    "aws_project_region": process.env.VUE_APP_AWS_PROJECT_REGION,
    "aws_cognito_region": process.env.VUE_APP_AWS_COGNITO_REGION,
    "aws_user_pools_id": process.env.VUE_APP_AWS_USER_POOL_ID,
    "aws_user_pools_web_client_id": process.env.VUE_APP_AWS_USER_POOL_WEB_CLIENT_ID,
    "oauth": {} 

};


export default awsmobile;