<template>
    <div>
        <form @submit.prevent="handleSubmit">
            <div class="row">
                <div class="col-lg-6">
                    <div class="card">
                        <div class="card-header">
                            Hotel Info
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="update-logo-hotel">
                                        <img :src="hotel.hotel_logo" />
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="form-group mb-3">
                                        <label for="hotelname" class="form-label">Hotel Name</label>
                                        <input type="text" v-model="hotel.hotel_name" class="form-control" id="hotelname" name="hotelname" placeholder="Hotel Name"
                                        :class="{ 'is-invalid': hotelsubmitted && $v.hotel.hotel_name.$error }">
                                        <div v-if="hotelsubmitted && !$v.hotel.hotel_name.required" class="invalid-feedback">Hotel Name required</div>
                                    </div>                                                  
                                </div>
                            </div>        
                            <div class="row">
                                <div class="col-lg-4">
                                    <div class="form-group pos-rel mb-3">
                                        <label for="checkin_hour" class="form-label">Check in hour</label>
                                        <div class="mask-temp" :class="{ 'd-none': hidemask1 }">{{ hotelDetails.checkin_hour }}</div>
                                        <date-picker @change="removecurrentmask1"
                                        :class="{ 'is-invalid': hotelsubmitted && $v.hotel.checkin_hour.$error }"
                                        ref="" v-model="hotel.checkin_hour" type="time" format="HH:mm" default-value="hotel.checkin_hour"></date-picker>
                                        <div v-if="hotelsubmitted && !$v.hotel.checkin_hour.required" class="invalid-feedback">Check in hour required</div>
                                    </div>                                                  
                                </div>
                                <div class="col-lg-4">
                                    <div class="form-group pos-rel mb-3">
                                        <label for="checkout_hour" class="form-label">Check out hour</label>
                                        <div class="mask-temp" :class="{ 'd-none': hidemask2 }">{{ hotelDetails.checkout_hour }}</div>
                                        <date-picker @change="removecurrentmask2"
                                        :class="{ 'is-invalid': hotelsubmitted && $v.hotel.checkout_hour.$error }"
                                        ref="" v-model="hotel.checkout_hour" type="time" format="HH:mm"></date-picker>                                                
                                        <div v-if="hotelsubmitted && !$v.hotel.checkout_hour.required" class="invalid-feedback">Check out hour required</div>
                                    </div>                                                  
                                </div>   
                                <div class="col-lg-4">
                                    <div class="form-group mb-3">
                                        <label for="total_rooms" class="form-label">Number of Rooms</label>
                                        <input type="text" v-model="hotel.total_rooms" class="form-control" id="total_rooms" name="total_rooms" placeholder="Number of Rooms" 
                                        :class="{ 'is-invalid': hotelsubmitted && $v.hotel.total_rooms.$error }">
                                        <div v-if="hotelsubmitted && !$v.hotel.total_rooms.required" class="invalid-feedback">Number of Rooms required</div>
                                    </div>                                                  
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-4">
                                    <div class="form-group mb-3">
                                        <label for="stars" class="form-label">Stars</label>

                                    <star-rating :rating="hotelDetails.stars" :increment="1" :star-size="20" @rating-selected="setRating"
                                    :class="{ 'is-invalid': hotelsubmitted && $v.hotel.stars.$error }"
                                    ></star-rating>

                                        <!-- <input type="text" v-model="hotel.stars" class="form-control" id="stars" name="stars" placeholder="Stars" 
                                        :class="{ 'is-invalid': hotelsubmitted && $v.hotel.stars.$error }"> -->
                                        <div v-if="hotelsubmitted && !$v.hotel.stars.required" class="invalid-feedback">Stars required</div>
                                    </div>                                                  
                                </div>
                                <div class="col-lg-4">
                                    <div class="form-group mb-3">
                                        <label for="latitude" class="form-label">Latitiude</label>
                                        <input type="text" readonly v-model="hotel.latitude" class="form-control" id="latitude" name="latitude" placeholder="Latitiude">
                                    </div>                                                  
                                </div>
                                <div class="col-lg-4">
                                    <div class="form-group mb-3">
                                        <label for="longitude" class="form-label">Longitude</label>
                                        <input type="text" readonly v-model="hotel.longitude" class="form-control" id="longitude" name="longitude" placeholder="Longitude">
                                    </div>                                                
                                </div>
                            </div>                             
                        </div>
                    </div>                                
                </div>
                <div class="col-lg-6">
                    <div class="card">
                    <div class="card-header">
                        Contact
                    </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="form-group mb-3">

                                    <div id="mapnew" style="height:300px; display:none" />
                                    <label for="address" class="form-label">Address</label>
                                    <div class="input-group mb-0 map-border" 
                                    :class="{ 'is-invalid': hotelsubmitted && $v.hotel.address.$error }">
                                        <span class="input-group-text" id="basic-addon1"><i class="ri-map-pin-fill"></i></span>
                                        <input id="autocomplete" type="text" class="form-control" @focus="initAutoComplete" placeholder="Find a Google Address" aria-label="Find a Google Address" aria-describedby="basic-addon1" style="width: 70%;" >
                                    </div>
                                    <div v-if="hotelsubmitted && !$v.hotel.address.required" class="invalid-feedback show-block">Hotel location is required</div>
                                    <iframe v-if="showmap"
                                        width="100%"
                                        height="145"
                                        frameborder="0" style="border:0"
                                        referrerpolicy="no-referrer-when-downgrade"
                                        :src="this.urlmap"
                                        class="mt-3 map-view"
                                        allowfullscreen>
                                    </iframe> 

                                    </div>                                                  
                                </div>                                          
                            </div>                             
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="form-group mb-3">
                                        <label for="phone" class="form-label">Email Address</label>
                                        <input type="tel" v-model="hotel.email" class="form-control" id="phone" name="phone" placeholder="Hotel Name" 
                                        :class="{ 'is-invalid': hotelsubmitted && $v.hotel.email.$error }">
                                        <div v-if="hotelsubmitted && !$v.hotel.email.required" class="invalid-feedback">Email required</div>
                                    </div>                                                  
                                </div>                                  
                            </div>        
                            <div class="row">
                                <div class="col-lg-4">
                                    <div class="form-group mb-3">
                                        <label for="country_id" class="form-label">Country Name</label>
                                        <input type="text" readonly v-model="hotel.country_id" class="form-control" id="country_id" name="country_id" placeholder="Country Name">
                                    </div>                                                  
                                </div>   
                                <div class="col-lg-6">
                                    <div class="form-group mb-3">
                                        <label for="city_id" class="form-label">City</label>
                                        <input type="text" readonly v-model="hotel.city_id" class="form-control" id="city_id" name="city_id" placeholder="Hotel Name">
                                    </div>                                                  
                                </div>   
                                <div class="col-lg-2">
                                    <div class="form-group mb-3">
                                        <label for="zip_code" class="form-label">Zip</label>
                                        <input type="text" readonly v-model="hotel.zip_code" class="form-control" id="zip_code" name="zip_code" placeholder="Zip">
                                    </div>                                                  
                                </div>                                                                                                                     
                            </div>                                        
                        </div>
                    </div>                                
                </div>                
            </div>
            <div class="row">
                <div class="col-lg-6">
                    <div class="card">
                        <div class="card-header">
                        Extra Info
                        </div>
                        <div class="card-body">
                            <div class="row">
                            <div class="col-lg-6">
                                <div class="form-group mb-3">
                                    <label for="hotel_currency" class="form-label">Hotel Currency</label>
                                    <select v-if="showcurrecny" class="form-select" id="hotel_currency" name="hotel_currency" v-model="hotel.hotel_currency" aria-label="Default select example">
                                        <option selected value="">Select Currency</option>
                                        <option v-for="currencylist in currency" :key="currencylist.code">{{ currencylist.code }}</option>
                                    </select>                                    
                                </div>                                                  
                            </div> 
                            </div>
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="form-group mb-3">
                                        <label for="provider" class="form-label">Airport Name</label>
                                        <input type="text" readonly v-model="hotel.provider" class="form-control" id="provider" name="provider" placeholder="Airport Name">
                                    </div>                                                   
                                </div>
                                <div class="col-lg-3">
                                    <div class="form-group mb-3">
                                        <label for="nearest_airport_code" class="form-label">Est: Time (Mins)</label>
                                        <input type="text" readonly v-model="hotel.nearest_airport_code" class="form-control" id="nearest_airport_code" name="nearest_airport_code" placeholder="Estimate Time">
                                    </div>                                                  
                                </div>   
                                <div class="col-lg-3">
                                    <div class="form-group mb-3">
                                        <label for="distance_unit" class="form-label">Distance Unit(KM)</label>
                                        <input type="text" readonly v-model="hotel.distance_unit" class="form-control" id="distance_unit" name="distance_unit" placeholder="Distance Unit">
                                    </div>                                                  
                                </div>                                                                                      
                            </div>                                         
                        </div>
                    </div>                                
                </div>
                <div class="col-lg-6">
                    <div class="card">
                        <div class="card-header">
                            Description
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="form-group mb-3">
                                        <label for="short_description" class="form-label">Short Description En_US</label>
                                        <input type="text" v-model="hotel.short_description" class="form-control" id="short_description" name="short_description" placeholder="Short Description En_US" 
                                        :class="{ 'is-invalid': hotelsubmitted && $v.hotel.short_description.$error }">
                                        <div v-if="hotelsubmitted && !$v.hotel.short_description.required" class="invalid-feedback">Short Description required</div>
                                    </div>                                                  
                                </div>
                                <div class="col-lg-12">
                                    <div class="form-group mb-3"
                                    :class="{ 'accept': hotel.long_description !='' }">
                                        <label for="long_description" class="form-label">Long Description EN_US</label>
                                        <input readonly @focus="opendescriptionmodal" type="text" v-model="hotel.long_description" class="form-control" id="long_description" name="long_description" placeholder="Long Description En_US" 
                                        :class="{ 'is-invalid': hotelsubmitted && $v.hotel.long_description.$error }">
                                        <div v-if="hotelsubmitted && !$v.hotel.long_description.required" class="invalid-feedback">Long Description required</div>
                                    </div>                                                  
                                </div>                                            
                            </div>                                                  
                        </div>
                    </div>                                
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6">
                    <div class="card">
                        <div class="card-header">
                        Hotel Amenities
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-lg-12">  
                                    <Multiselect
                                        v-model="hotel.selected_amentices"
                                        value="hotel.hotel_amenities"
                                        mode="tags"
                                        placeholder="Select Amenities"
                                        track-by="name"
                                        label="name"
                                        :close-on-select="false"
                                        :search="true"     
                                        :options="this.amenities_data"
                                        >
                                        <template v-slot:tag="{ option, handleTagRemove, disabled }">
                                            <div class="multiselect-tag is-user">
                                            <img :src="option.image">
                                            {{ option.name }}
                                            <span
                                                v-if="!disabled"
                                                class="multiselect-tag-remove"
                                                @mousedown.prevent="handleTagRemove(option, $event)"
                                            >
                                                <span class="multiselect-tag-remove-icon"></span>
                                            </span>
                                            </div>
                                        </template>
                                        <template v-slot:option="{ option }">
                                            <img class="user-image" :src="option.image"> {{ option.name }}
                                        </template>

                                    </Multiselect>                                                                                
                                </div>                           
                            </div>                                        
                        </div>
                    </div>                                
                </div>
                <div class="col-lg-6">                               
                </div>
            </div>             
            <div class="row">
                <div class="col-lg-12 text-right mb-4 footer-btn-action-area">
                    <button type="submit" class="btn btn-primary float-end footer-btn-action no-margin">Save</button>
                    <button type="button" class="btn btn-secondary float-end footer-btn-action">Cancel</button>
                </div>    
            </div>  
        </form>    
    <!-- Long description modal -->
    <div class="modal fade" id="lgdescModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Long Description EN_US</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="form-group mb-3">
                            <!-- <input type="text" v-model="updateamenity.amenity_name" id="amenity_name" name="amenity_name" class="form-control" placeholder="Amenity Name" 
                            :class="{ 'is-invalid': updateamenitysubmitted && $v.amenity.amenity_name.$error }"> -->
                            <vue-editor v-model="hotel.long_description"
                            :class="{ 'is-invalid': hotelsubmitted && $v.hotel.long_description.$error }" />
                            <div v-if="hotelsubmitted && !$v.hotel.long_description.required" class="invalid-feedback">Long Description required</div>
                        </div>             
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        <button type="submit" class="btn btn-primary" data-bs-dismiss="modal" :disabled="hotel.long_description == ''">Save changes</button>
                    </div>
            </div>
        </div>
    </div>              
    </div>  
</template>
<script>

import Vue from 'vue'
import moment from 'moment'
import axios from 'axios';

import Header from "../../mixins/Header";
import Multiselect from '@vueform/multiselect/dist/multiselect.vue2.js'
import StarRating from 'vue-star-rating'
import { required } from "vuelidate/lib/validators";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
export default {   
  components: { DatePicker, Event, StarRating, Multiselect },
  name: "edit_hotel",
  data(){
      return {
        currency:[],
        selected_amentices: [],
        example10: {
            mode: 'tags',
            value: [],
            placeholder: 'Select Amenities',
            closeOnSelect: false,
            searchable: true,
            trackBy: 'name',
            label: 'name',
            options: [
                { value: 'judy', name: 'Judy', image: 'https://randomuser.me/api/portraits/med/women/1.jpg' },
                { value: 'jane', name: 'Jane', image: 'https://randomuser.me/api/portraits/med/women/2.jpg' },
                { value: 'john', name: 'John', image: 'https://randomuser.me/api/portraits/med/men/1.jpg' },
                { value: 'joe', name: 'Joe', image: 'https://randomuser.me/api/portraits/med/men/2.jpg' },
            ]
        },          
          showmap: false,
          amenities_data: [],
          showcurrecny: true,
          data: [],
          urlqueue: "",
          hidemask1: false,
          hidemask2: false,
          hotel_id: this.$route.query.hotel_id,
          hotelDetails: [],  
          coindata:[],
          time2: null,
          amenityList: [],
          theme: 'bootstrap5',
        columns: ['amenity_name', 'image_url','id'],
        options: {
            headings: {
                id: 'Action',
                amenity_name: 'Name',
                image_url: 'Image',
            },
            sortable: [],
            filterable: [],
            sortIcon: { base:'fa', up:'fa-sort-asc', down:'fa-sort-desc', is:'fa-sort' },
            pagination: {
                chunk: 5,
                edge: false,
                nav: 'scroll'
            }
        },           
          hotel:{
            hotel_name: "",
            exclusive_deal: "",
            checkin_hour: "",
            checkout_hour: "",
            total_rooms: "",
            thumbnail_path: "",
            short_description: "",
            long_description: "",
            hotel_amenities: [],
            id: "",
            hotel_currency: "",
            stars: "",
            address: "",
            city_id: "",
            city_name: "",
            country_id: "",
            country_name: "",
            state: "",
            zip_code: "",
            longitude: "",
            latitude: "",
            destination_id: "",
            phone: "",
            country_code: "",
            email: "",
            fax: "",
            nearest_airport_code: "",
            provider: "",
            distance_unit: "",
            product_status: "",
            email: "",
            hotel_logo: ""
          },
        amenity:{
            amenity_name: "",
            amenity_image_url: ""
        },
          hotelsubmitted: false,
          amenitysubmitted: false,
          submitted: false
      }
  },
  validations: {
      hotel: {
        hotel_name: { required },
        checkin_hour: { required },
        checkout_hour: { required },
        total_rooms: { required },
        short_description: { required },
        long_description: { required },
        hotel_currency: { required },
        address: { required },
        // thumbnail_path: { required },
        // hotel_amenities: { required },
        // id: { required },
        // hotel_currency: { required },
        stars: { required },
        // address: { required },
        // city_id: { required },
        // city_name: { required },
        // country_id: { required },
        // country_name: { required },
        // state: { required },
        // zip_code: { required },
        // longitude: { required },
        // latitude: { required },
        // destination_id: { required },
        // phone: { required },
        // country_code: { required },
        // fax: { required },
        // nearest_airport_code: { required },
        // provider: { required },
        // distance_unit: { required },
        // product_status: { required },
        email: { required },   
      },
      amenity: {
        amenity_name: { required },
        amenity_image_url: { required },   
      }, 
      currency: [], 
      airport_lat: 0,
      airport_lng: 0,
      map: null,
      booking: "",
      airport_latitude: null,
      airport_longitude: null,
      airport_name: ""           
  }, 
  methods:{
    async getnearby() {

        var pyrmont = new google.maps.LatLng(this.hotel.latitude, this.hotel.longitude);

        const map = new google.maps.Map(document.getElementById('mapnew'), {
            center: pyrmont,
            zoom: 15
        });

        var request = {
            location: pyrmont,
            radius: '50000',
            query: 'airport'
        };

        const service = new google.maps.places.PlacesService(map);
        service.textSearch(request, this.callbacknew);

    },
    async callbacknew(results, status) {
        if (status == google.maps.places.PlacesServiceStatus.OK) {

            console.log(status)

            for (var i = 0; i < results.length; i++) {
                if(i == 0){
                    this.airport_latitude = results[i].geometry.location.lat()
                    this.airport_longitude = results[i].geometry.location.lng()
                    this.hotel.provider = results[i].name

                    console.log(results[i])
                }
            }
            this.calclAirport();
        }
    },    
    async getselectedAmentices() {

       this.selected_amentices = []

        console.log(this.hotelDetails.amenities)

              for (let a = 0; a < this.hotelDetails.amenities.length; a++){

                this.selected_amentices.push({
                  value :this.hotelDetails.amenities[a].id,
                  name:this.hotelDetails.amenities[a].amenity_name,
                  image: this.hotelDetails.amenities[a].image_url,
                })

              }   
              
              console.log(this.selected_amentices)

    },  
    async opendescriptionmodal() {
        bootstrap.Modal.getOrCreateInstance(document.getElementById('lgdescModal')).show();    
    },      
    async getCurrency() {

        this.currency = []

        try {
          let response = await this.axios
            .get(`${process.env.VUE_APP_API_URL}common/get/currency/list`, Header.APP_ADMIN_HEADER)
            .then((response) => {
                console.log(response.data)
              for (let a = 0; a < response.data.length; a++){
                this.currency.push({
                  name :response.data[a].name,
                  code:response.data[a].code,
                  symbol: response.data[a].symbol
                })               
              }
              console.log(this.currency)
            })
        } catch (error) {
          console.log(error)          
        }
    },      
    async initAutoComplete() {

    autocomplete = new google.maps.places.Autocomplete(
        document.getElementById('autocomplete'),
        {
        types: ['establishment'],
        // componentRestrictions: { 'country': ['AU'] },
        fields: ['address_components', 'adr_address', 'alt_id', 'formatted_address', 'geometry', 'icon', 'id', 'name', 'permanently_closed', 'photo', 'place_id', 'scope', 'type', 'url', 'utc_offset', 'vicinity']
        }
    )
        autocomplete.addListener('place_changed', this.onPlaceChanged)
    },
    async onPlaceChanged(){

        this.hotel.address = ""

        var place = autocomplete.getPlace();
        var place2 = autocomplete.getFields();

        if(!place.geometry) {
        document.getElementById('autocomplete').placeholder = 'Enter a Place'
        }
        else{
            this.hotel.address = document.getElementById('autocomplete').value
            this.hotel.name = ""
            this.hotel.zip_code = ""
            this.hotel.country_id = ""
            this.hotel.city_id = ""
            this.hotel.provider = ""
            this.hotel.nearest_airport_code = ""
            this.hotel.distance_unit = ""

            console.log(place)
            this.urlqueue = place.place_id
            this.urlmap = `https://www.google.com/maps/embed/v1/place?key=${process.env.VUE_APP_GOOGLE_MAP_KEY}&q=place_id:`+ this.urlqueue
            this.showmap = true
            console.log(place2)
            console.log(place.geometry.location.lat())
            console.log(place.geometry.location.lng())
            this.hotel.latitude = place.geometry.location.lat();
            this.hotel.hotel_name = place.name;
            this.hotel.longitude = place.geometry.location.lng();
            this.getnearby();

            for (let a = 0; a < place.address_components.length; a++){

                if(place.address_components[a].types[0] == "postal_code"){
                    this.hotel.zip_code = place.address_components[a].long_name
                }

                if(place.address_components[a].types[0] == "country"){
                    this.hotel.country_id = place.address_components[a].long_name
                }

                if(place.address_components[a].types[0] == "administrative_area_level_1"){
                    this.hotel.city_id = place.address_components[a].long_name
                }             
            }      
        }

    },    
    async calclAirport() {

        var bounds = new google.maps.LatLngBounds;
        var markersArray = [];

        var a1 = {lat: this.hotel.latitude, lng: this.hotel.longitude};
        var a2 = {lat: this.airport_latitude, lng: this.airport_longitude};

        console.log(a1)
        console.log(a2)

        var p1 = new google.maps.LatLng(a1.lat, a1.lng);
        var p2 = new google.maps.LatLng(a2.lat, a2.lng);

        var service = new google.maps.DistanceMatrixService();
        console.log(service)
        service.getDistanceMatrix(
            {
                origins: [p1],
                destinations: [p2],
                travelMode: google.maps.TravelMode.DRIVING,
                unitSystem: google.maps.UnitSystem.METRIC,
                avoidHighways: false,
                avoidTolls: false
            }, this.callback);
    },  
    callback(response, status) {
     if (status != google.maps.DistanceMatrixStatus.OK) {
         //  alert('Error was: ' + status);
     } else {
         console.log(response)
         console.log(status)
           var origins = response.originAddresses;
           var totalDistance = response.rows[0].elements[0].distance.value / 1000;
           this.hotel.nearest_airport_code = totalDistance

           var totalTime = response.rows[0].elements[0].duration.value / 1000;
           this.hotel.distance_unit = totalTime
           console.log(totalTime)

           this.data.distance=parseFloat(totalDistance*0.001).toFixed(2);
           this.e1=2;
     }
    },      
    async setplace() {

        const place = {
            lat: this.hotel.latitude,
            lng: this.hotel.longitude
        };
        const map = new google.maps.Map(document.getElementById("mapnew"), {
            zoom: 15,
            center: place
        });
        const marker = new google.maps.Marker({
            position: place,
            map,
        });
     },     
     setRating: function(rating) {
      this.hotel.stars = rating
    },       
     addnewroom() {
        this.$router.push("/room_info?id=1");
    },      
    async handleSubmit(e) {

      this.hotelsubmitted = true;
      
      this.$v.hotel.hotel_name.$touch();
      this.$v.hotel.checkin_hour.$touch();
      this.$v.hotel.checkout_hour.$touch();
      this.$v.hotel.total_rooms.$touch();
      this.$v.hotel.short_description.$touch();
      this.$v.hotel.long_description.$touch();
      this.$v.hotel.stars.$touch();
      this.$v.hotel.email.$touch();      
      this.$v.hotel.address.$touch();

      if(
        this.$v.hotel.hotel_name.$error ||
        this.$v.hotel.checkin_hour.$error ||
        this.$v.hotel.checkout_hour.$error ||
        this.$v.hotel.total_rooms.$error ||
        this.$v.hotel.short_description.$error ||
        this.$v.hotel.long_description.$error ||
        this.$v.hotel.stars.$error ||
        this.$v.hotel.email.$error ||
        this.$v.hotel.address.$error   
      ){          
           
      }
      else{
        var reqBody = {
            address: document.getElementById("autocomplete").value,
            checkin_hour: this.hotelDetails.checkin_hour,
            checkout_hour: this.hotelDetails.checkout_hour,
            city_id: this.hotel.city_id,
            city_name: this.hotel.city_id,
            country_code: this.hotel.country_id,
            country_id: this.hotel.country_id,
            country_name: this.hotel.country_id,
            destination_id: this.hotel.destination_id,
            hotel_amenities: this.hotel.hotel_amenities,
            hotel_currency: this.hotel.hotel_currency,
            hotel_name: this.hotel.hotel_name,
            latitude: parseFloat(this.hotel.latitude),
            long_description: this.hotel.long_description,
            longitude: parseFloat(this.hotel.longitude),
            nearest_airport: this.hotel.provider,
            airport_distance: parseFloat(this.hotel.distance_unit),
            airport_eta: String(this.hotel.nearest_airport_code),
            nearest_train_station: "",
            train_station_distance: 0,
            train_station_eta: "",
            email: this.hotel.email,
            short_description: this.hotel.short_description,
            stars: String(this.hotel.stars),
            total_rooms: parseInt(this.hotel.total_rooms),
            user_id: "",
            zip_code: this.hotel.zip_code,
            hotel_logo: this.hotel.thumbnail_path
          } 
          console.log(reqBody)

          try{
              let response = await this.axios
              .put(`${process.env.VUE_APP_API_URL}hotel/update/general/info/?hotel_id=${this.hotel_id}`, reqBody, Header.APP_ADMIN_HEADER)
              .then((res) => {
                  console.log(res);   
                  this.$toast.success(res.data.message, {hideProgressBar: true, timeout: 3000,});      
              })                    
          }catch(error){    
              console.log(error)   
              this.$toast.error(error.message, {hideProgressBar: true, timeout: 3000,});                                   
          }         
      }
 
  
        
       // console.log(reqBody)
    },
    async removecurrentmask1() {
        this.hidemask1 = true
    },
    async removecurrentmask2() {
        this.hidemask2 = true
    },    
    async getHotelDetails() {
        try {
          let response = await this.axios         
            .get(`${process.env.VUE_APP_API_URL}hotel/view/general/info/?hotel_id=${this.hotel_id}`, Header.APP_ADMIN_HEADER)
            .then((response) => {
                console.log(response)
                this.hotelDetails = response.data
                this.hotel.hotel_name = this.hotelDetails.hotel_name,
                this.hotel.exclusive_deal = this.hotelDetails.exclusive_deal,
                this.hotel.checkin_hour =  this.hotelDetails.checkin_hour,
                this.hotel.checkout_hour =  this.hotelDetails.checkout_hour,
                this.hotel.total_rooms = this.hotelDetails.total_rooms,
                this.hotel.thumbnail_path = this.hotelDetails.thumbnail_path,
                this.hotel.short_description = this.hotelDetails.short_description,
                this.hotel.long_description = this.hotelDetails.long_description,
                this.hotel.hotel_amenities = this.hotelDetails.hotel_amenities,
                this.selected_amentices = this.hotelDetails.hotel_amenities,
                this.hotel.hotel_currency =  this.hotelDetails.hotel_currency,
                this.hotel.stars =  this.hotelDetails.stars,
                this.hotel.address = this.hotelDetails.address,
                this.hotel.city_id = this.hotelDetails.city_id,
                this.hotel.city_name = this.hotelDetails.city_id,
                this.hotel.country_id = this.hotelDetails.country_id,
                this.hotel.country_name =  this.hotelDetails.country_id,
                this.hotel.state = this.hotelDetails.state,
                this.hotel.zip_code = this.hotelDetails.zip_code,
                this.hotel.longitude = this.hotelDetails.longitude,
                this.hotel.latitude = this.hotelDetails.latitude,
                this.hotel.destination_id = this.hotelDetails.destination_id,
                this.hotel.phone = this.hotelDetails.phone,
                this.hotel.country_code = this.hotelDetails.country_id,
                this.hotel.fax = this.hotelDetails.fax,
                this.hotel.hotel_logo = this.hotelDetails.hotel_logo
                this.hotel.nearest_airport_code = this.hotelDetails.airport_eta,
                this.hotel.provider = this.hotelDetails.nearest_airport,
                this.hotel.distance_unit = this.hotelDetails.airport_distance,
                this.hotel.product_status = this.hotelDetails.hotel_status
                this.hotel.email = this.hotelDetails.email  
                
                console.log(this.hotelDetails)
                document.getElementById('autocomplete').value = this.hotel.address
            })
        } catch (error) {
          console.log(error)          
        }
    },
    async getAmenities() {

        this.amenities_data = []

        try {
          let response = await this.axios
            .get(`${process.env.VUE_APP_API_URL}common/amenities/get/list`, Header.APP_ADMIN_HEADER)
            .then((response) => {
                console.log(response.data)
              for (let a = 0; a < response.data.length; a++){
                this.amenities_data.push({
                  value :response.data[a].id,
                  name:response.data[a].amenity_name,
                  image: response.data[a].image_url,
                })               
              }
            })
        } catch (error) {
          console.log(error)
        }
    },   
  },
  mounted(){
      this.getHotelDetails();
      setTimeout(() => this.getselectedAmentices(), 2000);
      setTimeout(() => $("#autocomplete").prop("readonly", false), 2000);
      this.getCurrency();
      this.getAmenities();
      this.setplace();
      this.initAutoComplete();
  }  

}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>