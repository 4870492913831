export default {

    get APP_ADMIN_HEADER() {

      const AuthStr = "Bearer ".concat(localStorage.getItem("token"));
      return {
        headers: {
          Authorization: AuthStr,
          "Content-Type": "application/json",
        }
      };

    },
    get BOOKING_TOKEN() {

      const AuthStr = "Bearer sk_test_51LjflnFqIDxqoLNiQUyJfim7c3EIr7p0eDEYTxVAIhigyX0gBuatSC6fGyLKQQoSXMpHf4suVFKA24vTVebXuTsx00mHJGfwE0"; 
      return {
        headers: {
          Authorization: AuthStr
        }
      };

    },    

}    