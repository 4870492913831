<template>
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-lg-12">
                            <button type="button" class="btn btn-primary pull-right btn-lg" @click="addnewroom">Add New Room</button>
                            <v-client-table ref="infoPagesTable" :columns="columns" :data="data" :options="options" id="dataTable" class="main-data-table">
                                <div slot-scope="props" slot="id">                                         
                                    <button @click="editRoom(props.row.id, props.row.room_name, props.row.room_type_id, props.row.room_allotments, props.row.short_des, props.row.long_des, props.row.max_adults, props.row.max_child, props.row.child_age)"   class="btn btn-primary" type="button"><i class="fa-solid fa-pencil"></i></button>
                                    <button @click="deleteRoom(props.row.id, props.row.room_name, props.row.room_type_id, props.row.room_allotments, props.row.short_des, props.row.long_des, props.row.max_adults, props.row.max_child, props.row.child_age)" class="btn btn-danger" type="button"><i class="fa-solid fa-trash-can"></i></button>
                                    <button @click="editInfoPage(props.row.id)" class="btn btn-info" type="button"><i class="ri-eye-fill"></i></button>

                                </div>  
                                <template v-slot:child_row="props">
                                    <div class="list-br"><b>Room Allotments:</b> {{props.row.room_allotments}}</div>
                                    <div class="list-br"><b>Max Adults:</b> {{props.row.max_adults}}</div>
                                    <div class="list-br"><b>Max Childs:</b> {{props.row.max_child}}</div>
                                    <div class="list-br"><b> Child Age:</b> {{props.row.child_age}}</div>
                                </template>                                                               
                            </v-client-table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    <!-- edit Room modal -->
    <div class="modal fade" id="editRoommodal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <form @submit.prevent="updateroomsubmit" ref="roomform">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Add New Amenity</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="form-group mb-3">
                                    <label for="room_name" class="form-label">Room Name</label>
                                <input type="text" v-model="room.room_name" class="form-control" id="room_name" name="room_name" placeholder="Room  Name" 
                                    :class="{ 'is-invalid': roomsubmitted && $v.room.room_name.$error }">
                                    <div v-if="roomsubmitted && !$v.room.room_name.required" class="invalid-feedback">Room Name required</div>
                                </div>   
                            </div>                            
                        </div>
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="form-group mb-3">
                                    <label for="room_type" class="form-label">Room Type</label>
                                    <select class="form-control form-select" v-model="room.room_type_id" id="exampleFormControlSelect1" placeholder="Select Room Type"
                                    :class="{ 'is-invalid': roomsubmitted && $v.room.room_type_id.$error }">
                                    <option selected value="1">Delux</option>
                                    <option value="2">Luxary</option>
                                    </select>
                                    <div v-if="roomsubmitted && !$v.room.room_type_id.required" class="invalid-feedback">Room Type required</div>
                                </div>   
                            </div>
                            <div class="col-lg-6">
                                <div class="form-group mb-3">
                                    <label for="room_name" class="form-label">Allotments</label>
                                    <select class="form-control form-select" v-model="room.room_allotments" id="exampleFormControlSelect1" placeholder="Select Allotments"
                                    :class="{ 'is-invalid': roomsubmitted && $v.room.room_allotments.$error }">
                                    <option selected value="1">Delux</option>
                                    <option value="2">Luxary</option>
                                    </select>
                                    <div v-if="roomsubmitted && !$v.room.room_allotments.required" class="invalid-feedback">Allotments required</div>
                                </div>   
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="form-group mb-3">
                                    <label for="short_des" class="form-label">Room Short Description</label>
                                <input type="text" v-model="room.short_des" class="form-control" id="total_rooms" name="short_des" placeholder="Room Short Description" 
                                    :class="{ 'is-invalid': roomsubmitted && $v.room.short_des.$error }">
                                    <div v-if="roomsubmitted && !$v.room.short_des.required" class="invalid-feedback">Room Name required</div>
                                </div>   
                            </div>                                                                        
                        </div>
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="form-group mb-3">
                                    <label for="long_des" class="form-label">Room Long Description</label>
                                    <textarea rows="4" v-model="room.long_des" class="form-control" id="total_rooms" name="long_des" placeholder="Room Long Description"
                                    :class="{ 'is-invalid': roomsubmitted && $v.room.long_des.$error }"
                                    ></textarea>
                                    <div v-if="roomsubmitted && !$v.room.long_des.required" class="invalid-feedback">Room Name required</div>
                                </div>   
                            </div>                                      
                        </div>
                        <div class="row">
                            <div class="col-lg-4">
                                <div class="form-group mb-3">
                                    <label for="max_adults" class="form-label">Max Adults</label>
                                    <input type="number" v-model="room.max_adults" class="form-control" id="max_adults" name="max_adults" placeholder="Max Adults"
                                    :class="{ 'is-invalid': roomsubmitted && $v.room.max_adults.$error }"                                                 />
                                    <div v-if="roomsubmitted && !$v.room.max_adults.required" class="invalid-feedback">Max Adults required</div>
                                </div>   
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group mb-3">
                                    <label for="max_child" class="form-label">Max Child</label>
                                    <input type="number" v-model="room.max_child" class="form-control" id="max_child" name="max_child" placeholder="Max Child" 
                                    :class="{ 'is-invalid': roomsubmitted && $v.room.max_child.$error }"                                                    />
                                    <div v-if="roomsubmitted && !$v.room.max_child.required" class="invalid-feedback">Max Child required</div>
                                </div>   
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group mb-3">
                                    <label for="child_age" class="form-label">Child Age</label>
                                    <input type="number" v-model="room.child_age" class="form-control" id="child_age" name="max_chchild_ageild" placeholder="Child Age" 
                                    :class="{ 'is-invalid': roomsubmitted && $v.room.child_age.$error }"                                                   />
                                    <div v-if="roomsubmitted && !$v.room.child_age.required" class="invalid-feedback">Child Age</div>
                                </div>
                            </div>
                        </div>                         
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        <button type="submit" class="btn btn-primary">Save changes</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
    </div>   
</template>

<script>
import axios from 'axios';
import Header from "../../../mixins/Header";
import { required } from "vuelidate/lib/validators";
import {ClientTable, Event} from 'vue-tables-2'
export default {   
  components: { ClientTable, Event  },
  name: "room_info",
  data(){
      return{
        data: [],
        hotel_id: this.$route.query.hotel_id,
        columns: ['room_type_id', 'room_name','room_allotments','max_adults','max_child','child_age', 'id'],
        options: {
            headings: {
                room_type_id: 'Room Type',
                room_name: 'Room Name',
                room_allotments: 'Room Allotments',
                max_adults: 'Max Adults',
                max_child: 'Max_childus',
                child_age: 'child_age',
                id: 'Action'
            },
            perPage: 8,
            columnsDisplay: {
                room_name: 'desktopL',
                child_age: 'not_mobile',
                room_type_id: 'not_mobile',
                room_allotments: 'not_mobile',
                max_adults: 'not_mobile',
                max_child: 'not_mobile',
                id: 'desktopL',
            },            
            sortable: ['room_type_id', 'room_name','room_allotments','max_adults','max_child',],
            filterable: ['room_type_id', 'room_name','room_allotments','max_adults','max_child'],
            sortIcon: { base:'fa', up:'fa-sort-asc', down:'fa-sort-desc', is:'fa-sort' },
            pagination: {
                chunk: 5,
                edge: false,
                nav: 'scroll'
            }
        },         
        room:{
            newroom: true,
            room_type_id: "",
            room_name: "",
            room_allotments: "",
            short_des: "",
            long_des: "",
            max_adults: null,
            max_child: null,
            child_age: null,         
        },
        roomsubmitted: false,
        submitted: false
      }
  },
  validations: {
      room: { 
        room_type_id: { required },
        room_name: { required },  
        room_allotments: { required },  
        short_des: { required },
        long_des: { required },  
        max_adults: { required },  
        max_child: { required },  
        child_age: { required },  
      },     
  }, 
  methods:{   
    async editInfoPage(id) {
        this.$refs.infoPagesTable.toggleChildRow(id);
    },      
     addnewroom() {
        this.$router.push("/add_room_info?hotel_id="+this.hotel_id);
    },         
    async roomsubmit(e) {
      this.roomsubmitted = true;
      this.$v.room.room_type_id.$touch();
      this.$v.room.room_name.$touch();
      this.$v.room.room_allotments.$touch();
      this.$v.room.short_des.$touch();
      this.$v.room.long_des.$touch();
      this.$v.room.max_adults.$touch();
      this.$v.room.max_child.$touch();
      this.$v.room.child_age.$touch();      

      if(
        !this.$v.room.room_type_id.$invalid || 
        !this.$v.room.room_name.$invalid ||
        !this.$v.room.room_allotments.$invalid ||
        !this.$v.room.short_des.$invalid ||
        !this.$v.room.long_des.$invalid ||
        !this.$v.room.max_adults.$invalid ||
        !this.$v.room.max_child.$invalid ||
        !this.$v.room.child_age.$invalid    
      ){
          alert("success")
      }
 
        var reqBody = {
          room_type_id: this.room.room_type_id,
          room_name: this.room.room_name,
          room_allotments: this.room.room_allotments,
          short_des: this.room.short_des,
          long_des: this.room.long_des,
          max_adults: this.room.max_adults,
          max_child: this.room.max_child,
          child_age: this.room.child_age,
        }   
        
        console.log(reqBody)

    },

    async getRoomList() {
        try {
          let response = await this.axios
            .get(`${process.env.VUE_APP_API_URL}room/get/list?hotel_id=${this.hotel_id}`, Header.APP_ADMIN_HEADER)
            .then((response) => {
                console.log(response.data)
              for (let a = 0; a < response.data.length; a++){
                this.data.push({
                  room_type_id :response.data[a].room_type_id,
                  room_name:response.data[a].room_name,
                  room_allotments:response.data[a].room_allotments,
                  max_adults:response.data[a].max_adults,
                  max_child:response.data[a].max_child,
                  child_age:response.data[a].child_age,
                  id:response.data[a].id,
                })               
              }
            })
        } catch (error) {
          console.log(error)          
        }
    },
   async deleteRoom(id) { 
    
    this.$swal({
        title: 'Are you sure?',
        text: "Do you want to delete this Room?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#0d6efd',
        cancelButtonColor: '#d33',
        confirmButtonText: 'delete it!'
    }).then((result) => {
        if (result.isConfirmed) {
            axios.delete(`${process.env.VUE_APP_API_URL}room/update?room?id=${id}`, Header.APP_ADMIN_HEADER)
            .then(response => {
                console.log(response);
                this.$toast.success(response.data.message, {hideProgressBar: true, timeout: 3000,});    
                this.getHotelList(); 
            })
            .catch(function (error) {
                console.log(error)
                this.$toast.error(error.message, {hideProgressBar: true, timeout: 3000,});  
            })  
        }
    })    
    

   },
   async editRoom(id) {   
       this.$router.push("/edit_room_info?room_id="+id+"&hotel_id="+this.hotel_id);
       // bootstrap.Modal.getOrCreateInstance(document.getElementById('editRoommodal')).show();         
   }
  },
  mounted(){
      this.getRoomList();
  }
}
</script>

<style>

</style>