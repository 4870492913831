import Vue from 'vue';
import App from './App.vue';
import store from './store/index'
import router from './router/index'

import Vuelidate from 'vuelidate'

import axios from 'axios'
import VueAxios from 'vue-axios'

import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)
Vue.use('apexchart', VueApexCharts)

import VCalendar from 'v-calendar';
Vue.use(VCalendar);



import Vue2Editor from "vue2-editor";
Vue.use(Vue2Editor);

Vue.config.productionTip = false;

import Amplify, { Auth } from 'aws-amplify';
import awsconfig from './aws-exports';

import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

axios.interceptors.response.use(function (response) {
  return response
}, function (error) {
  console.log(error.response.data)
  if (error.response.status === 401) {
    window.localStorage.clear();
    router.push('/')
  }
})

import VueSweetalert2 from 'vue-sweetalert2';
// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css';
Vue.use(VueSweetalert2);

// import Multiselect from 'vue-multiselect'
// Vue.use('multiselect', Multiselect)

import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
Vue.component("vue-phone-number-input", VuePhoneNumberInput);


const options = {
    // You can set your default options here
};

Vue.use(Toast, options);
Vue.use(Vuelidate)
Vue.use(VueAxios, axios)
Amplify.configure(awsconfig);
Auth.configure(awsconfig);


/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  store,
  template: '<App/>',
  components: {
    App
  }
})