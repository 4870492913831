<template>
<div>
        <div class="row">
            <div class="col-lg-6">              
                <div class="card">
                    <div class="card-header">
                        Hotel Info
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-lg-12">    
                                <div class="form-group mb-3">
                                    <vue-dropzone 
                                        ref="myVueDropzone"
                                        :options="dropzoneOptions"
                                        @vdropzone-error="errorUploading"
                                        @vdropzone-success="fileUploaded"
                                        :useCustomSlot=true
                                        >
                                        <div class="dropzone-custom-content">
                                            <h3 class="dropzone-custom-title">Upload a Logo</h3>
                                        </div>                                         
                                    </vue-dropzone>
                                </div>                                                  
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="form-group mb-3">
                                    <label for="hotelname" class="form-label">Hotel Name</label>
                                    <input  type="text" v-model="hotel.hotel_name" class="form-control" id="hotelname" name="hotelname" placeholder="Hotel Name"
                                    :class="{ 'is-invalid': hotelsubmitted && $v.hotel.hotel_name.$error }">
                                    <div v-if="hotelsubmitted && !$v.hotel.hotel_name.required" class="invalid-feedback">Hotel Name required</div>
                                </div>                                                  
                            </div>
                        </div>                         
                        <div class="row">
                            <div class="col-lg-4">
                                <div class="form-group mb-3">
                                    <label for="checkin_hour" class="form-label">Check in hour</label>
                                    <date-picker
                                    :class="{ 'is-invalid': hotelsubmitted && $v.hotel.checkin_hour.$error }"
                                    ref="" v-model="hotel.checkin_hour" type="time" format="HH:mm"></date-picker>
                                    <div v-if="hotelsubmitted && !$v.hotel.checkin_hour.required" class="invalid-feedback">Check in hour required</div>
                                </div>                                                  
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group mb-3">
                                    <label for="checkout_hour" class="form-label">Check out hour</label>
                                    <date-picker
                                    :class="{ 'is-invalid': hotelsubmitted && $v.hotel.checkout_hour.$error }"
                                    ref="" v-model="hotel.checkout_hour" type="time" format="HH:mm"></date-picker>                                                
                                    <div v-if="hotelsubmitted && !$v.hotel.checkout_hour.required" class="invalid-feedback">Check out hour required</div>
                                </div>                                                  
                            </div>   
                            <div class="col-lg-4">
                                <div class="form-group mb-3">
                                    <label for="total_rooms" class="form-label">Total number of Rooms</label>
                                    <input type="number" min="0" v-model="hotel.total_rooms" class="form-control" id="total_rooms" name="total_rooms" placeholder="Number of Rooms" 
                                    :class="{ 'is-invalid': hotelsubmitted && $v.hotel.total_rooms.$error }">
                                    <div v-if="hotelsubmitted && !$v.hotel.total_rooms.required" class="invalid-feedback">Number of Rooms required</div>
                                </div>                                                  
                            </div>                                                                                      
                        </div>
                        <div class="row">
                            <div class="col-lg-4">
                                <div class="form-group mb-3">
                                    <label for="stars" class="form-label">Stars</label>
                                    <!-- <input type="text" v-model="hotel.stars" class="form-control" id="stars" name="stars" placeholder="Stars" 
                                    :class="{ 'is-invalid': hotelsubmitted && $v.hotel.stars.$error }"> -->
                                    <star-rating :increment="1" :star-size="20" @rating-selected="setRating"
                                    :class="{ 'is-invalid': hotelsubmitted && $v.hotel.stars.$error }"
                                    ></star-rating>
                                    <div v-if="hotelsubmitted && !$v.hotel.stars.required" class="invalid-feedback">Stars required</div>
                                </div>                                                  
                            </div>  
                            <div class="col-lg-4">
                                <div class="form-group mb-3">
                                    <label for="latitude" class="form-label">Latitiude</label>
                                    <input readonly type="text" v-model="hotel.latitude" class="form-control" id="latitude" name="latitude" placeholder="Latitiude">
                                </div>                                                  
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group mb-3">
                                    <label for="longitude" class="form-label">Longitude</label>
                                    <input readonly type="text" v-model="hotel.longitude" class="form-control" id="longitude" name="longitude" placeholder="Longitude">
                                </div>                                                
                            </div>
                        </div>                                          
                    </div>
                </div>                                
            </div>
            <div class="col-lg-6">
                <div class="card">
                <div class="card-header">
                    Contact
                </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="form-group mb-3">

                                    <div id="mapnew" style="height:300px; display:none" />
                                    <label for="address" class="form-label">Address</label>
                                    <div class="input-group mb-0 map-border"
                                    :class="{ 'is-invalid': hotelsubmitted && $v.hotel.address.$error }"
                                    >
                                        <span class="input-group-text" id="basic-addon1"><i class="ri-map-pin-fill"></i></span>
                                        <input id="autocomplete" v-model="hotel.address" type="text" class="form-control" @focus="removereadonly" placeholder="Find a Google Address" aria-label="Find a Google Address" aria-describedby="basic-addon1" style="width: 70%;" >
                                    </div>
                                    <div v-if="hotelsubmitted && !$v.hotel.address.required" class="invalid-feedback">Hotel location is required</div>

                                    <iframe v-if="showmap" class="mt-3 map-view"
                                        width="100%"
                                        height="145"
                                        frameborder="0" style="border:0"
                                        referrerpolicy="no-referrer-when-downgrade"
                                        :src="this.urlmap"
                                        allowfullscreen>
                                    </iframe>  

                                </div>                                
                            </div>  
                        </div>                          
                        <div class="row">
                            <!-- <div class="col-lg-6">
                                <div class="form-group mb-3">
                                    <label for="phone" class="form-label">Phone Number</label>
                                    <input type="tel" v-model="hotel.phone" class="form-control" id="phone" name="phone" placeholder="Hotel Name" 
                                    :class="{ 'is-invalid': hotelsubmitted && $v.hotel.phone.$error }">
                                    <div v-if="hotelsubmitted && !$v.hotel.phone.required" class="invalid-feedback">Phone Number required</div>
                                </div>                                                  
                            </div>                              -->
                            <div class="col-lg-12">
                                <div class="form-group mb-3">
                                    <label for="fax" class="form-label">Email Address</label>
                                    <input type="text" v-model="hotel.fax" class="form-control" id="email" name="email" placeholder="Email Address" 
                                    :class="{ 'is-invalid': hotelsubmitted && $v.hotel.fax.$error }">
                                    <div v-if="hotelsubmitted && !$v.hotel.fax.required" class="invalid-feedback">Email Address is required</div>
                                     <div v-if="hotelsubmitted && !$v.hotel.fax.email" class="invalid-feedback">Email Address format not valid</div>
                                </div>
                            </div>                                           
                        </div>        
                        <div class="row">
                            <div class="col-lg-4">
                                <div class="form-group mb-3">
                                    <label for="country_id" class="form-label">Country Name</label>
                                    <input readonly type="text" v-model="hotel.country_id" class="form-control" id="country_id" name="country_id" placeholder="Country Name" 
                                    :class="{ 'is-invalid': hotelsubmitted && $v.hotel.country_id.$error }">
                                    <div v-if="hotelsubmitted && !$v.hotel.country_id.required" class="invalid-feedback">Country required</div>
                                </div>                                                  
                            </div>   
                            <div class="col-lg-5">
                                <div class="form-group mb-3">
                                    <label for="city_id" class="form-label">City</label>
                                    <input type="text" readonly v-model="hotel.city_id" class="form-control" id="city_id" name="city_id" placeholder="City">
                                </div>                                                  
                            </div>   
                            <div class="col-lg-3">
                                <div class="form-group mb-3">
                                    <label for="zip_code" class="form-label">Zip</label>
                                    <input type="text" readonly v-model="hotel.zip_code" class="form-control" id="zip_code" name="zip_code" placeholder="Zip">
                                </div>                                                  
                            </div>                                                                                                                
                        </div>                                        
                    </div>
                </div>                                
            </div>
        </div> 
        <div class="row">
            <div class="col-lg-6">
                <div class="card">
                    <div class="card-header">
                    Extra Info
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="form-group mb-3">
                                    <label for="hotel_currency" class="form-label">Hotel Currency</label>
                                    <select v-if="showcurrecny" class="form-select" id="hotel_currency" name="hotel_currency" v-model="hotel.hotel_currency" aria-label="Default select example">
                                        <option selected value="">Select Currency</option>
                                        <option v-for="currencylist in currency" :key="currencylist.code">{{ currencylist.code }}</option>
                                    </select>                                    
                                </div>                                                  
                            </div>                             
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                 <fieldset>
                                    <legend>Airport Details</legend>
                                    <div class="row">
                                        <div class="col-xl-12 col-xxl-6">
                                            <div class="form-group mb-">
                                                <label for="provider" class="form-label">Airport Name</label>
                                                <input type="text" readonly v-model="hotel.provider" class="form-control" id="provider" name="provider" placeholder="Airport Name">
                                            </div>                                                   
                                        </div>
                                        <div class="col-xl-6 col-xxl-3">
                                            <div class="form-group mb-3">
                                                <label for="nearest_airport_code" class="form-label">Estimate Time (Mins)</label>
                                                <input readonly type="text" v-model="hotel.nearest_airport_code" class="form-control" id="nearest_airport_code" name="nearest_airport_code" placeholder="Estimate Time">
                                            </div>                                                  
                                        </div>   
                                        <div class="col-xl-6 col-xxl-3">
                                            <div class="form-group mb-3">
                                                <label for="distance_unit" class="form-label">Distance Unit (KM)</label>
                                                <input readonly type="text" v-model="hotel.distance_unit" class="form-control" id="distance_unit" name="distance_unit" placeholder="Distance Unit">
                                            </div>                                                  
                                        </div> 
                                    </div>
                                </fieldset>
                            </div>                                                                                     
                        </div>                                         
                    </div>
                </div>                                
            </div>
            <div class="col-lg-6">
                <div class="card">
                    <div class="card-header">
                        Description
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="form-group mb-3">
                                    <label for="short_description" class="form-label">Short Description En_US</label>
                                    <input type="text" v-model="hotel.short_description" class="form-control" id="short_description" name="short_description" placeholder="Short Description En_US" 
                                    :class="{ 'is-invalid': hotelsubmitted && $v.hotel.short_description.$error }">
                                    <div v-if="hotelsubmitted && !$v.hotel.short_description.required" class="invalid-feedback">Short Description required</div>
                                </div>                                                  
                            </div>
                            <div class="col-lg-12">
                                <div class="form-group mb-3"
                                :class="{ 'accept': hotel.long_description !='' }">
                                    <label for="long_description" class="form-label">Long Description EN_US</label>
                                    <input 
                                     readonly @focus="opendescriptionmodal" type="text" v-model="hotel.long_description" class="form-control" id="long_description" name="long_description" placeholder="Long Description En_US" 
                                    :class="{ 'is-invalid': hotelsubmitted && $v.hotel.long_description.$error }">
                                    <div v-if="hotelsubmitted && !$v.hotel.long_description.required" class="invalid-feedback">Long Description required</div>
                                </div>                                                  
                            </div>                                            
                        </div>                                                  
                    </div>
                </div>                                
            </div>
        </div>       
        <div class="row">
            <div class="col-lg-6">
                <div class="card">
                    <div class="card-header">
                    Hotel Amenities
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-lg-12">  

                                <Multiselect
                                    v-model="hotel.hotel_amenities"
                                    mode="tags"
                                    placeholder="Select Amenities"
                                    track-by="name"
                                    label="name"
                                    :close-on-select="false"
                                    :search="true"     
                                    :options="this.amenities_data"
                                    @change="getdata_amenities(hotel.hotel_amenities)"
                                    >
                                    <template v-slot:tag="{ option, handleTagRemove, disabled }">
                                        <div class="multiselect-tag is-user">
                                        <img :src="option.image">
                                        {{ option.name }}
                                        <span
                                            v-if="!disabled"
                                            class="multiselect-tag-remove"
                                            @mousedown.prevent="handleTagRemove(option, $event)"
                                        >
                                            <span class="multiselect-tag-remove-icon"></span>
                                        </span>
                                        </div>
                                    </template>
                                    <template v-slot:option="{ option }">
                                        <img class="user-image" :src="option.image"> {{ option.name }}
                                    </template>
                                    </Multiselect>
                            </div>
                        </div>                                        
                    </div>
                </div>                                
            </div>
            <div class="col-lg-6">
                <div class="card">
                    <div class="card-header">
                    Hotel Categories
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-lg-12">  

                                <Multiselect
                                    v-model="hotel.hotel_categories"
                                    mode="tags"
                                    placeholder="Select Categories"
                                    track-by="name"
                                    label="name"
                                    :close-on-select="false"
                                    :search="true"     
                                    :options="this.categories_data"
                                    @change="getdata_categories(hotel.hotel_categories)"
                                    >
                                    <template v-slot:tag="{ option, handleTagRemove, disabled }">
                                        <div class="multiselect-tag is-user">
                                        <img :src="option.image">
                                        {{ option.name }}
                                        <span
                                            v-if="!disabled"
                                            class="multiselect-tag-remove"
                                            @mousedown.prevent="handleTagRemove(option, $event)"
                                        >
                                            <span class="multiselect-tag-remove-icon"></span>
                                        </span>
                                        </div>
                                    </template>
                                    <template v-slot:option="{ option }">
                                        <img class="user-image" :src="option.image"> {{ option.name }}
                                    </template>
                                    </Multiselect>
                            </div>
                        </div>                                        
                    </div>
                </div>                                
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12 text-right mb-4 footer-btn-action-area">
                <button @click="handleSubmit" class="btn btn-primary float-end footer-btn-action no-margin">Save</button>
                <button type="button" class="btn btn-secondary float-end footer-btn-action">Cancel</button>
            </div>    
        </div>    
    <!-- Long description modal -->
    <div class="modal fade" id="lgdescModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Long Description EN_US</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="form-group mb-3">
                            <!-- <input type="text" v-model="updateamenity.amenity_name" id="amenity_name" name="amenity_name" class="form-control" placeholder="Amenity Name" 
                            :class="{ 'is-invalid': updateamenitysubmitted && $v.amenity.amenity_name.$error }"> -->
                            <vue-editor v-model="hotel.long_description"
                            :class="{ 'is-invalid': hotelsubmitted && $v.hotel.long_description.$error }" />
                            <div v-if="hotelsubmitted && !$v.hotel.long_description.required" class="invalid-feedback">Long Description required</div>
                        </div>             
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        <button type="submit" class="btn btn-primary" data-bs-dismiss="modal" :disabled="hotel.long_description == ''">Save changes</button>
                    </div>
            </div>
        </div>
    </div>
</div> 
</template>

<script>
import axios from 'axios'
import Multiselect from '@vueform/multiselect/dist/multiselect.vue2.js'
import moment from 'moment'
import Header from "../../mixins/Header";
import { VueEditor } from "vue2-editor";
import StarRating from 'vue-star-rating'
import { required, email, numeric } from "vuelidate/lib/validators";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
export default {   
    components: { DatePicker, vueDropzone: vue2Dropzone, StarRating, VueEditor, Multiselect },
  data(){
      return{
        example10: {
            mode: 'tags',
            value: [],
            placeholder: 'Select Amenities',
            closeOnSelect: false,
            searchable: true,
            trackBy: 'name',
            label: 'name',
            options: [
                { value: 'judy', name: 'Judy', image: 'https://randomuser.me/api/portraits/med/women/1.jpg' },
                { value: 'jane', name: 'Jane', image: 'https://randomuser.me/api/portraits/med/women/2.jpg' },
                { value: 'john', name: 'John', image: 'https://randomuser.me/api/portraits/med/men/1.jpg' },
                { value: 'joe', name: 'Joe', image: 'https://randomuser.me/api/portraits/med/men/2.jpg' },
            ]
        },
        showcurrecny: true,  
        dropzoneOptions: {
            id: "facility_image",
            url: 'https://httpbin.org/post',
            thumbnailWidth: 150,
            maxFilesize: 0.5,
            addRemoveLinks: true,
            uploadMultiple: false,
            maxFilesize: 20,
            autoProcessQueue: true,
            acceptedFiles: "image/*",
            maxFiles: 1,
            headers: { "My-Awesome-Header": "header value" },
        },
        urlqueue: "",
        urlmap: "",         
        coindata:[],
        address: "",
        amenities_data: [],
        categories_data: [],
        time2: null,
        amenityList: [],
        hotel_id: "",
        gallery:  [],       
        dropzoneOptions: {
            id: "",
            url: 'https://httpbin.org/post',
            maxFilesize: 0.5,
            addRemoveLinks: true,
            uploadMultiple: false,
            maxFilesize: 50,
            autoProcessQueue: true,
            acceptedFiles: "image/*",
            maxFiles: 1,
            headers: { "My-Awesome-Header": "header value" },
        },          
        hotel:{
            hotel_name: "",
            checkin_hour: "",
            checkout_hour: "",
            total_rooms: null,
            thumbnail_path: "",
            short_description: "",
            long_description: "",
            hotel_amenities: [],
            hotel_categories: [],
            id: "",
            hotel_currency: "",
            stars: "",
            address: "",
            city_id: "",
            city_name: "",
            country_id: "",
            country_name: "",
            zip_code: "",
            longitude: null,
            latitude: null,
            destination_id: "",
            phone: "",
            country_code: "",
            fax: "",
            nearest_airport_code: "",
            provider: "",
            distance_unit: "",
            user_id: ""
        },       
        hotelsubmitted: false,
        amenitysubmitted: false,
        updateamenitysubmitted: false,
        submitted: false,
        currency: [],
        room_name: "",
        room_desscription: "",
        roomsubmitted: false,
        showmap: false
      }
  },
  validations: {
      hotel: {
        hotel_name: { required },
        checkin_hour: { required },
        checkout_hour: { required },
        total_rooms: { required, numeric },
        short_description: { required },
        long_description: { required },
        // // hotel_amenities: { required },
        // id: { required },
        // hotel_currency: { required },
        stars: { required },
        address: { required },
        country_id: { required },
        country_name: { required },
        country_code: { required },
        fax: { required, email }, 
      },
      room_name: { required },
      room_desscription: { required },
      pickeropen1: false,
      pickeropen2: false,
      currency: [], 
      airport_lat: 0,
      airport_lng: 0,
      map: null,
      booking: "",
      airport_latitude: null,
      airport_longitude: null,
      airport_name: ""
  }, 
  methods:{  
    async getnearby() {

        var pyrmont = new google.maps.LatLng(this.hotel.latitude, this.hotel.longitude);

       const map = new google.maps.Map(document.getElementById('mapnew'), {
            center: pyrmont,
            zoom: 15
        });

        var request = {
            location: pyrmont,
            radius: '50000',
            query: 'airport'
        };

       const service = new google.maps.places.PlacesService(map);
        service.textSearch(request, this.callbacknew);

    },
    async callbacknew(results, status) {
        if (status == google.maps.places.PlacesServiceStatus.OK) {

            console.log(status)

            for (var i = 0; i < results.length; i++) {
                if(i == 0){
                    this.airport_latitude = results[i].geometry.location.lat()
                    this.airport_longitude = results[i].geometry.location.lng()
                    this.hotel.provider = results[i].name

                    console.log(results[i])
                }
            }
            this.calclAirport();
        }
    },
    handleChange1(value, type) {
        this.pickeropen1 = false;
  	},
    handleChange2(value, type) {
        this.pickeropen2 = false;
  	},      
    async removereadonly(){
    document.getElementById('autocomplete').removeAttribute('readonly');
    },
    async selectamtity(e){
        console.log(this.hotel.hotel_amenities)
    },
    async setplace() {

    const place = {
        lat: this.hotel.latitude,
        lng: this.hotel.longitude
    };
    const map = new google.maps.Map(document.getElementById("mapnew"), {
        zoom: 15,
        center: place
    });
    const marker = new google.maps.Marker({
        position: place,
        map,
    });
    }, 
    async initAutoComplete() {
    autocomplete = new google.maps.places.Autocomplete(
        document.getElementById('autocomplete'),
        {
        types: ['establishment'],
        // componentRestrictions: { 'country': ['AU'] },
        fields: ['address_components', 'adr_address', 'alt_id', 'formatted_address', 'geometry', 'icon', 'id', 'name', 'permanently_closed', 'photo', 'place_id', 'scope', 'type', 'url', 'utc_offset', 'vicinity']
        }
    )
        autocomplete.addListener('place_changed', this.onPlaceChanged)
    },
    async onPlaceChanged(){

        this.hotel.address = ""

        var place = autocomplete.getPlace();
        var place2 = autocomplete.getFields();

        if(!place.geometry) {
            document.getElementById('autocomplete').placeholder = 'Enter Hotel Location'
        }
        else{
            this.hotel.address = document.getElementById('autocomplete').value
            this.hotel.name = ""
            this.hotel.zip_code = ""
            this.hotel.country_id = ""
            this.hotel.city_id = ""
            this.hotel.provider = ""
            this.hotel.nearest_airport_code = ""
            this.hotel.distance_unit = ""

            console.log(place)
            this.urlqueue = place.place_id
            this.urlmap = `https://www.google.com/maps/embed/v1/place?key=${process.env.VUE_APP_GOOGLE_MAP_KEY}&q=place_id:`+ this.urlqueue
            this.showmap = true
            console.log(place2)
            console.log(place.geometry.location.lat())
            console.log(place.geometry.location.lng())
            this.hotel.latitude = place.geometry.location.lat();
            this.hotel.hotel_name = place.name;
            this.hotel.longitude = place.geometry.location.lng();
            this.getnearby();
           // this.getairport();
            this.setplace();

            for (let a = 0; a < place.address_components.length; a++){
                

                if(place.address_components[a].types[0] == "postal_code"){
                    this.hotel.zip_code = place.address_components[a].long_name
                }

                if(place.address_components[a].types[0] == "country"){
                    this.hotel.country_id = place.address_components[a].long_name
                }

                if(place.address_components[a].types[0] == "administrative_area_level_1"){
                    this.hotel.city_id = place.address_components[a].long_name
                }             
            }      
        }

    },
    async displaySuggestions(predictions, status) {
        if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
        this.searchResults = [];
        return;
        }
        this.searchResults = predictions.map(prediction => prediction.description);
        console.log(predictions)
        console.log(status)
    },  
    async getCurrency() {

        this.currency = []

        try {
          let response = await this.axios
            .get(`${process.env.VUE_APP_API_URL}common/get/currency/list`, Header.APP_ADMIN_HEADER)
            .then((response) => {
                console.log(response.data)
              for (let a = 0; a < response.data.length; a++){
                    this.currency.push({
                        name :response.data[a].name,
                        code:response.data[a].code,
                        symbol: response.data[a].symbol
                    })               
              }
            })
        } catch (error) {
          console.log(error)          
        }
    },
    async getsocket() {

            // var reqBody = {
            //     id: this.booking,
            // }        

          try{
              let response = await this.axios
              .get(`http://3.67.8.190:9090/list/${this.booking}`)
              .then((res) => {
                  console.log(res);
              })                    
          }catch(error){    
              console.log(error)                                   
          }         

    },     
    async calclAirport() {

        var bounds = new google.maps.LatLngBounds;
        var markersArray = [];

        var a1 = {lat: this.hotel.latitude, lng: this.hotel.longitude};
        var a2 = {lat: this.airport_latitude, lng: this.airport_longitude};

        console.log(a1)
        console.log(a2)

        var p1 = new google.maps.LatLng(a1.lat, a1.lng);
        var p2 = new google.maps.LatLng(a2.lat, a2.lng);

        var service = new google.maps.DistanceMatrixService();
        console.log(service)
        service.getDistanceMatrix(
            {
                origins: [p1],
                destinations: [p2],
                travelMode: google.maps.TravelMode.DRIVING,
                unitSystem: google.maps.UnitSystem.METRIC,
                avoidHighways: false,
                avoidTolls: false
            }, this.callback);
    },   
    callback(response, status) {
     if (status != google.maps.DistanceMatrixStatus.OK) {
           alert('Error was: ' + status);
     } else {
         console.log(response)
         console.log(status)
           var origins = response.originAddresses;
           var totalDistance = response.rows[0].elements[0].distance.value / 1000;
           this.hotel.distance_unit = totalDistance.toFixed(3)

           var totalTime = response.rows[0].elements[0].duration.value / 60;
           this.hotel.nearest_airport_code = totalTime.toFixed(2)
           console.log(totalTime)

           this.data.distance=parseFloat(totalDistance*0.001).toFixed(2);
           this.e1=2;
     }
    }, 
    async findairportmodal() {
        bootstrap.Modal.getOrCreateInstance(document.getElementById('airportmodal')).show();    
    },  
    async opendescriptionmodal() {
        bootstrap.Modal.getOrCreateInstance(document.getElementById('lgdescModal')).show();    
    },      
    async openlogomodal() {
        bootstrap.Modal.getOrCreateInstance(document.getElementById('logoModal')).show();     
    },  
    setRating: function(rating) {
      this.hotel.stars = rating
    },       
    async handleSubmit(e) {

      this.hotelsubmitted = true;

      this.$v.hotel.hotel_name.$touch();
      this.$v.hotel.checkin_hour.$touch();
      this.$v.hotel.checkout_hour.$touch();
      this.$v.hotel.total_rooms.$touch();
      this.$v.hotel.short_description.$touch();
      this.$v.hotel.long_description.$touch();
      this.$v.hotel.stars.$touch();
      this.$v.hotel.address.$touch();
    //   this.$v.hotel.country_id.$touch();
    //   this.$v.hotel.country_name.$touch();
      this.$v.hotel.fax.$touch();

        var  reqBody = {
            address:  document.getElementById("autocomplete").value,
            checkin_hour: String(moment(this.hotel.checkin_hour).format("HH:mm")),
            checkout_hour: String(moment(this.hotel.checkout_hour).format("HH:mm")),
            city_id: this.hotel.city_id,
            city_name: this.hotel.city_id,
            country_code: this.hotel.country_id,
            country_id: this.hotel.country_id,
            country_name: this.hotel.country_id,
            amenities: this.hotel.hotel_amenities,
            categories: this.hotel.hotel_categories,
            hotel_currency: this.hotel.hotel_currency,
            hotel_name: this.hotel.hotel_name,
            latitude: parseFloat(this.hotel.latitude),
            long_description: this.hotel.long_description,
            longitude: parseFloat(this.hotel.longitude),
            nearest_airport: this.hotel.provider,
            airport_distance: parseFloat(this.hotel.distance_unit),
            airport_eta: String(this.hotel.nearest_airport_code),
            nearest_train_station: "",
            train_station_distance: 0,
            train_station_eta: "",
            email: this.hotel.fax,
            short_description: this.hotel.short_description,
            stars: String(this.hotel.stars),
            total_rooms: parseInt(this.hotel.total_rooms),
            user_id: "",
            zip_code: this.hotel.zip_code,
            hotel_logo: this.hotel.thumbnail_path
        }        
        
      if(
        this.$v.hotel.hotel_name.$error ||
        this.$v.hotel.checkin_hour.$error ||
        this.$v.hotel.checkout_hour.$error ||
        this.$v.hotel.total_rooms.$error ||
        this.$v.hotel.short_description.$error ||
        this.$v.hotel.long_description.$error ||
        this.$v.hotel.stars.$error ||
        this.$v.hotel.fax.$error ||
        this.$v.hotel.address.$error
      ){

            console.log(reqBody)           
      }     
      else{
            console.log(reqBody)

          try{
              let response = await this.axios
              .post(`${process.env.VUE_APP_API_URL}hotel/create/general/info`, reqBody, Header.APP_ADMIN_HEADER)
              .then((res) => {
                  console.log(res);   
                  this.$toast.success(res.data.message, {hideProgressBar: true, timeout: 3000,});      
                  setTimeout(() => this.$router.push("/hotel_list"), 1000);
              })                    
          }catch(error){    
              console.log(error)   
              this.$toast.error(error.message, {hideProgressBar: true, timeout: 3000,});
          }           
      }   
    },    
    async getAmenities() {

        this.amenities_data = []

        try {
          let response = await this.axios
            .get(`${process.env.VUE_APP_API_URL}common/amenities/get/list`, Header.APP_ADMIN_HEADER)
            .then((response) => {
                console.log(response.data)
              for (let a = 0; a < response.data.length; a++){
                this.amenities_data.push({
                  value :response.data[a].id,
                  name:response.data[a].amenity_name,
                  image: response.data[a].image_url,
                })               
              }
            })
        } catch (error) {
          console.log(error)          
        }

    },
    async getCategories() {

        this.categories_data = []

        try {
          let response = await this.axios
            .get(`${process.env.VUE_APP_API_URL}common/category/get/list`, Header.APP_ADMIN_HEADER)
            .then((response) => {
                console.log(response.data)
              for (let a = 0; a < response.data.length; a++){
                this.categories_data.push({
                  value :response.data[a].id,
                  name:response.data[a].name,
                  image: response.data[a].image_url,
                })               
              }
            })
        } catch (error) {
          console.log(error)          
        }

    },     
    errorUploading (file, message, xhr) {
        console.log(file)
        this.$toast.error(message, {hideProgressBar: true, timeout: 3000,});  
    },
    fileUploaded (file, response) {
        console.log(file.dataURL)
        this.hotel.thumbnail_path = file.dataURL
    },
    removefile (file, error, xhr){
        console.log(file)
        this.hotel.thumbnail_path = ""
    },   
    amenity_errorUploading (file, message, xhr) {
        console.log(file)
        this.$toast.error(message, {hideProgressBar: true, timeout: 3000,});  
    },
    amenity_fileUploaded (file, response) {
        console.log(file.dataURL)
        this.amenity.amenity_image_url = file.dataURL
    },
    amenity_removefile (file, error, xhr){
        console.log(file)
        this.amenity.amenity_image_url = ""
    },    
    updateerrorUploading (file, message, xhr) {
    console.log(file)
    this.$toast.error(message, {hideProgressBar: true, timeout: 3000,});  
    },
    updatefileUploaded (file, response) {
        this.hotel.thumbnail_path = file.dataURL
        console.log(response)
    },  
    updateremovefile (file, error, xhr){
        console.log(file)
        this.amenity.amenity_image_url = ""
    },
    async testlocation() {

        var orig = this.state.input;
        var dest = this.state.destination;

    service.getDistanceMatrix({
          origins: [orig],
          destinations: [dest],
          travelMode: 'DRIVING',
          unitSystem: google.maps.UnitSystem.METRIC,
          avoidHighways: false,
          avoidTolls: false
        }, (response, status) => {
          if (status !== 'OK') { 
            alert('Error was: ' + status);
          } else {
            var origins = response.originAddresses;
            var destinations = response.destinationAddresses;

            //Loop through the elements row to get the value of duration and distance
            for (var i = 0; i < origins.length; i++) {
              var results = response.rows[i].elements;
              for (var j = 0; j < results.length; j++) {
              var element = results[j];
              var distanceString = element.distance.text;
              var durationString = element.duration.text;


              this.setState ({
                distance: parseInt(distanceString, 10)
                });
              console.log(this.state.distance);
              this.setState ({
                duration: parseInt(durationString, 10)
                });
                console.log(this.state.duration);
               }
             }
          }
          });      
    }
  },
  mounted(){
       this.getCurrency();
      this.getAmenities();
      this.getCategories();
      setTimeout(() => $("#map").prop("readonly", false), 2000);
      setTimeout(() => $("#autocomplete").prop("readonly", false), 2000);
      this.initAutoComplete();
      this.setplace();
  }    
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>