<template>
  <div class="main-layout">
    <div class="col-md-12">
      <div class="container-area">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12">
              <div class="row top-area">
                <div class="col-md-12 no-padding">
                  <div class="content">                     
                    <slot/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name:'pageContent',
  data(){
      return{

      }
  }
}
</script>
<style>
    .content{
        float: left;
        width: 100%;
        padding: 0;
        min-height: 864px;  
        padding: 15px!important;      
    }
    @import "../../scss/pagecontent/pagecontent.scss";
</style>