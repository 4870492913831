<template>
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-lg-12">
                            <button type="button" class="btn btn-primary pull-right btn-lg" data-bs-toggle="modal" data-bs-target="#AddNewMealModal">Add New Meal</button>
                            <v-client-table ref="infoPagesTable" :columns="columns" :data="data" :options="options" id="dataTable" class="main-data-table">
                                <div slot-scope="props" slot="id">
                                    <button @click="editmeal(props.row.id, props.row.meal_plan, props.row.description)" class="btn btn-primary" type="button"><i class="fa-solid fa-pencil"></i></button>
                                    <button @click="deleteMeal(props.row.id)" class="btn btn-danger" type="button"><i class="fa-solid fa-trash-can"></i></button>
                                    <button @click="editInfoPage(props.row.id)" class="btn btn-info" type="button"><i class="ri-eye-fill"></i></button>
                                 
                                </div>   
                                <template v-slot:child_row="props">
                                    <div class="list-br"><b>Description:</b> {{props.row.description}}</div>
                                </template>
                            </v-client-table>                                                
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Add New Meal modal -->
        <div class="modal fade" id="AddNewMealModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <form @submit.prevent="mealsubmit" ref="mealform">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Add New Meal Plan</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="form-group mb-3">
                                <label for="meal_plan" class="form-label">Meal Plan Name</label>
                                <input type="text" v-model="meal.meal_plan" id="meal_plan" name="meal_plan" class="form-control" placeholder="meal Name" 
                                :class="{ 'is-invalid': mealsubmitted && $v.meal.meal_plan.$error }">
                                <div v-if="mealsubmitted && !$v.meal.meal_plan.required" class="invalid-feedback">Meal Name required</div>
                            </div>
                            <div class="form-group mb-3">
                                <label for="description" class="form-label">Description</label>
                                <input type="text" v-model="meal.description" id="description" name="description" class="form-control" placeholder="meal description" 
                                :class="{ 'is-invalid': mealsubmitted && $v.meal.description.$error }">
                                <div v-if="mealsubmitted && !$v.meal.description.required" class="invalid-feedback">Meal description required</div>
                            </div>                            
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="submit" class="btn btn-primary">Save changes</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>

        <!-- Update Meal modal -->
        <div class="modal fade" id="UpdatewMealModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <form @submit.prevent="updatemealsubmit" ref="updatemealform">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Update Meal Plan</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="form-group mb-3">
                                <label for="meal_plan" class="form-label">Meal Plan Name</label>
                                <input type="text" v-model="meal.update_meal_plan" id="meal_plan" name="meal_plan" class="form-control" placeholder="meal Name" 
                                :class="{ 'is-invalid': updatemealsubmitted && $v.meal.update_meal_plan.$error }">
                                <div v-if="updatemealsubmitted && !$v.meal.update_meal_plan.required" class="invalid-feedback">Meal Name required</div>
                            </div>
                            <div class="form-group mb-3">
                                <label for="description" class="form-label">Description</label>
                                <textarea placeholder="meal description"  v-model="meal.update_description" id="description" name="description" class="form-control" rows="5"
                                 :class="{ 'is-invalid': updatemealsubmitted && $v.meal.update_description.$error }"
                                ></textarea>
                                <div v-if="updatemealsubmitted && !$v.meal.update_description.required" class="invalid-feedback">Meal description required</div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button class="btn btn-primary">Update Now</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>        
    </div>
</template>
<script>
import axios from 'axios';
import Header from "../../../mixins/Header";
import { required } from "vuelidate/lib/validators";
import {ClientTable, Event} from 'vue-tables-2'
export default {  
  components: { ClientTable, Event  },
  name: "room_info",
  data(){
      return{
        data: [],
        hotel_id: this.$route.query.hotel_id,
        columns: ['meal_plan', 'description', 'id'],
        options: {
            perPage: 8,
            columnsDisplay: {
                meal_plan: 'desktopL',
                description: 'not_mobile',
                id: 'desktopL',
            },            
            headings: {
                meal_plan: 'Meal Plan',
                description: 'Description',
                id: "Action"
            },
            sortable: [],
            filterable: [],
            sortIcon: { base:'fa', up:'fa-sort-asc', down:'fa-sort-desc', is:'fa-sort' },
            pagination: {
                chunk: 5,
                edge: false,
                nav: 'scroll'
            }
        },         
        meal:{
            meal_plan: "",
            description: "",
            update_meal_plan: "",
            update_description: "",             
        },      
        mealsubmitted: false,
        updatemealsubmitted: false,
        submitted: false,
        updatemealdataid: null
      }
  },
  validations: {
      meal: {
        meal_plan: { required },
        description: { required },   
        update_meal_plan: { required },
        update_description: { required }
      },      
  }, 
  methods:{    
    async editInfoPage(id) {
        this.$refs.infoPagesTable.toggleChildRow(id);
    },
    async mealsubmit(e) { 

      this.mealsubmitted = true;

        this.$v.meal.meal_plan.$touch();
        this.$v.meal.description.$touch();

        if (this.$v.meal.meal_plan.$error || this.$v.meal.description.$error) {  
        } 
        else{
            var reqBody = {
                meal_plan: this.meal.meal_plan,
                description: this.meal.description,
            }
            console.log(reqBody)
            
          try{
              let response = await this.axios
              .post(`${process.env.VUE_APP_API_URL}room/mealplan/create?hotel_id=${this.hotel_id}`, reqBody, Header.APP_ADMIN_HEADER)
              .then((res) => {
                console.log(res);   
                this.meal.meal_plan = '';
                this.meal.description = '';  
                this.$v.meal.meal_plan.$reset();
                this.$v.meal.description.$reset();
                this.getmealLIst();
                this.$toast.success(res.data.message, {hideProgressBar: true, timeout: 3000,});      
                bootstrap.Modal.getOrCreateInstance(document.getElementById('AddNewMealModal')).hide(); 
              })                    
          }catch(error){    
              console.log(error)   
              this.$toast.error(error.message, {hideProgressBar: true, timeout: 3000,});                                   
          }            
        }
    },            
    async getmealLIst() {
        this.data = []
        try {
          let response = await this.axios
            .get(`${process.env.VUE_APP_API_URL}room/mealplan/get/list?hotel_id=${this.hotel_id}`, Header.APP_ADMIN_HEADER)
            .then((response) => {
                console.log(response.data)
              for (let a = 0; a < response.data.length; a++){
                this.data.push({
                  meal_plan :response.data[a].meal_plan,
                  description:response.data[a].description,
                  id:response.data[a].id,                  
                })               
              }
            })
        } catch (error) {
          console.log(error)          
        }
    },
    async editmeal(id, name, description) {

        this.updatemealdataid = id
        this.meal.update_meal_plan = name
        this.meal.update_description = description
        
        bootstrap.Modal.getOrCreateInstance(document.getElementById('UpdatewMealModal')).show();
    },
    async updatemealsubmit(e) { 

      this.updatemealsubmitted = true;

        this.$v.meal.update_meal_plan.$touch();
        this.$v.meal.update_description.$touch();

        if (this.$v.meal.update_meal_plan.$error || this.$v.meal.update_description.$error) {             
            
        } 
        else{
            var reqBody = {
                meal_plan: this.meal.update_meal_plan,
                description: this.meal.update_description
            }

            console.log(reqBody)            
            
            try{
                let response = await this.axios
                .put(`${process.env.VUE_APP_API_URL}room/mealplan/update?meal_plan_id=${this.updatemealdataid}&hotel_id=${this.hotel_id}`, reqBody, Header.APP_ADMIN_HEADER)
                .then((res) => {
                    let data = res.data;
                    console.log(data)
                    this.meal.update_meal_plan = '';
                    this.meal.update_description = '';
                    this.$v.meal.update_meal_plan.$reset();
                    this.$v.meal.update_description.$reset();
                    this.getmealLIst();
                    this.$toast.success(data.message, {hideProgressBar: true, timeout: 3000,});    
                    bootstrap.Modal.getOrCreateInstance(document.getElementById('UpdatewMealModal')).hide();    
                })                    
            }catch(error){    
                console.log(error)   
                this.$toast.error(error.message, {hideProgressBar: true, timeout: 3000,});
                this.meal.update_meal_plan = '';
                this.meal.update_description = '';
                this.$v.meal.update_meal_plan.$reset();
                this.$v.meal.update_description.$reset();                                                
            }             
        }
    },
    async deleteMeal(id) { 
        
        this.$swal({
            title: 'Are you sure?',
            text: "Do you want to delete this Meal Plan?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#0d6efd',
            cancelButtonColor: '#d33',
            confirmButtonText: 'delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                axios.delete(`${process.env.VUE_APP_API_URL}room/mealplan/delete?meal_plan_id=${id}`, Header.APP_ADMIN_HEADER)
                .then(response => {
                    console.log(response);
                    this.getmealLIst(); 
                    this.$toast.success(response.data.message, {hideProgressBar: true, timeout: 3000,});    
                })
                .catch(function (error) {
                    console.log(error)
                    this.$toast.error(error.message, {hideProgressBar: true, timeout: 3000,});  
                })  
            }
        })   
    },      
  },
  created(){
      this.getmealLIst();
  }
}
</script>

<style>

</style>