<template>
    <default-container>
        <nav class="page-breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <router-link :to="`edit_hotel?hotel_id=${this.$route.query.hotel_id}`">
                    <h4 class="page-title"><i class="fa-regular fa-circle-left"></i> Back to Hotel</h4>
                    </router-link>
                </li>
                    
                <li class="breadcrumb-item no-arrow"><router-link :to="`/hotel_list`">Manage Hotel</router-link></li>
                <li class="breadcrumb-item"><router-link :to="`edit_hotel?hotel_id=${this.$route.query.hotel_id}`">Hotel Details</router-link></li>
                <li class="breadcrumb-item active" aria-current="page">Edit Room</li>
            </ol>
        </nav>   
        <ul class="nav nav-pills add-new-hotel mb-3" id="pills-tab" role="tablist">
            <li class="nav-item" role="presentation">
                <button class="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Room General</button>
            </li>
            <li class="nav-item" role="presentation">
                <button class="nav-link" id="beds-tab" data-bs-toggle="pill" data-bs-target="#pills-beds" type="button" role="tab" aria-controls="pills-beds"  aria-selected="false">Beds</button>
            </li>
            <li class="nav-item" role="presentation">
                <button class="nav-link" id="pic-tab" data-bs-toggle="pill" data-bs-target="#pills-pic" type="button" role="tab" aria-controls="pills-pic"  aria-selected="false">Pictures</button>
            </li>            
            <li class="nav-item" role="presentation">
                <button class="nav-link" id="meal-tab" data-bs-toggle="pill" data-bs-target="#pills-meal" type="button" role="tab" aria-controls="pills-meal"  aria-selected="false">Meal Plans</button>
            </li>         
            <li class="nav-item" role="presentation">
                <button class="nav-link" id="extra-tab" data-bs-toggle="pill" data-bs-target="#pills-extra" type="button" role="tab" aria-controls="pills-extra"  aria-selected="false">Room Extra</button>
            </li>                 
        </ul>
        <div class="tab-content" id="pills-tabContent">
            <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                <general-room />
            </div>
            <div class="tab-pane fade" id="pills-beds" role="tabpanel" aria-labelledby="beds-tab">
               <beds />
            </div>
            <div class="tab-pane fade" id="pills-pic" role="tabpanel" aria-labelledby="pic-tab">
               <p-ictures />
            </div>            
            <div class="tab-pane fade" id="pills-meal" role="tabpanel" aria-labelledby="meal-tab">
              <meal-plan />
            </div>
            <div class="tab-pane fade" id="pills-extra" role="tabpanel" aria-labelledby="extra-tab">
              <room-extra />
            </div>            
        </div>           
    </default-container>
</template>
<script>
import DefaultContainer from '../../containers/DefaultContainer.vue'
import GeneralRoom from '../../components/EditHotel/EditRoom/GeneralRoom.vue';
import PIctures from '../../components/EditHotel/EditRoom/PIctures.vue';
import Beds from '../../components/EditHotel/EditRoom/beds.vue';
import MealPlan from '../../components/EditHotel/EditRoom/MealPlan.vue';
import RoomExtra from '../../components/EditHotel/EditRoom/RoomExtra.vue';

export default {    
  components: { DefaultContainer, GeneralRoom, PIctures, Beds, MealPlan, RoomExtra  },
  name: "room_info",
  data(){
      return{
        data: [],
        roomsubmitted: false,
        submitted: false
      }
  }, 
  methods:{    
    // maingethotellist: async function() {
    //     let response = await this.getHotelList();
    //     if(response !== null){
    //         console.log(response)
    //           for (let a = 0; a < response.length; a++){
    //             this.data.push({
    //               date :response[a].change,
    //               email:response[a].last_price,
    //               amount:response[a].second_min,
    //               hash:response[a].country_name,
    //               maturity_date:response[a].volume,
    //               id:response[a].pair_name,
    //             })
                
    //                 for (let b = 0; b < response.length; b++){
    //                     this.data.push({
    //                         date :response[a].change,
    //                         email:response[a].last_price,
    //                         amount:response[a].second_min,
    //                         hash:response[a].country_name,
    //                         maturity_date:response[a].volume,
    //                         id:response[a].pair_name,
    //                     })

    //             this.data.push({
    //               date :response[a].change,
    //               email:response[a].last_price,
    //               amount:response[a].second_min,
    //               hash:response[a].country_name,
    //               maturity_date:response[a].volume,
    //               id:response[a].pair_name,
    //             })                    
    //             }                
    //           }            
    //     }
    //     else{
    //         return null
    //     }

    // },    
  },
  created(){
    //   this.maingethotellist();
  }
}
</script>

<style>

</style>